import { ShareModule } from '../../share/share.module';
import { UserSeatComponent } from './game/user-seat/user-seat.component';
import { HomeComponent } from './home/home.component';
import { GameComponent } from './game/game.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { LoaderComponent } from 'src/app/pages/game/loader/loader.component';
import { ChooseGameComponent } from './choose-game/choose-game.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SelectAvatarComponent } from './select-avatar/select-avatar.component';
import { LoadingInvitationComponent } from './loading-invitation/loading-invitation.component';
import { HeaderGameComponent } from './game/header-game/header-game.component';
import {AllTablesComponent} from "./all-tables/all-tables.component";


const routes: Route[] = [
  {
    path: '',
    component: GameComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'loader',
    component: LoaderComponent
  },
  {
    path: 'choose_game',
    component: ChooseGameComponent
  },
  {
    path: 'loading-invitation/:id',
    component: LoadingInvitationComponent
  },
  {
    path: 'select-avatar',
    component: SelectAvatarComponent
  },
  {
    path: 'loader',
    component: LoaderComponent
  }
];

@NgModule({
  declarations: [
    HomeComponent,
    GameComponent,
    UserSeatComponent,
    ChooseGameComponent,
    SelectAvatarComponent,
    LoaderComponent,
    HeaderGameComponent,
    AllTablesComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ShareModule,
    SlickCarouselModule
  ],
  exports: [
    RouterModule
    ]
})
export class GameModule { }
