
export class SetGameStatus {
  static readonly type = '[Game] SetGameStatus';
  constructor(public game: any) {}
}

export class SetChairs {
  static readonly type = '[Game] SetChairs';
  constructor(public users: any) {}
}
export class ResetChar {
  static readonly type = '[Game] ResetChar';
  constructor() {}
}

export class ReturnCardForUser {
  static readonly type = '[Game] ReturnCardForUser';
  constructor() {}
}

export class ResetGame {
  static readonly type = '[Game] ResetGame';
  constructor() {}
}

export class SetAuthUser {
  static readonly type = '[Game] SetAuthUser';
  constructor(public user: any) {}
}

export class SelectTable {
  static readonly type = '[Game] SelectTable';
  constructor(public selectedTable: any) {}
}

export class UpdateWinningCards {
  static readonly type = '[Game] UpdateWinningCards';
  constructor(public users: any) {}
}

export class ShowMyCards {
  static readonly type = '[Game] ShowMyCards';
  constructor(public showMyCards: any) {}
}

export class SetNumberOfChair {
  static readonly type = '[Game] SetNumberOfChair';
  constructor(public numberOfChair: number) {}
}

export class SetWaitingList {
  static readonly type = '[Game] SetWaitingList';
  constructor(public waitingList: number) {}
}
