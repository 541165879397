import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notificationSuccess = new Subject();
  public reconnecting = new Subject();
  public notificationError = new Subject();
  public ok = new Subject();
  public close = new Subject();
  public cancel = new Subject();
  public loader = new Subject();
  public reset = new Subject();
  public refresh = new Subject();

  constructor() { }

  showSuccess(message) {
    this.notificationSuccess.next({message});
  }

  showError(message) {
    this.notificationError.next({message});
  }

  okEvent(){
    this.ok.next();
  }

  cancelEvent(){
    this.cancel.next();
  }

  setReconnecting(value): void {
    this.reconnecting.next(value);
  }

  closeEvent(){
    this.close.next();
  }

  showLoader(value){
    this.loader.next(value);
  }
}
