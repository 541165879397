import { MusicService } from 'src/app/core/services/music/music.service';
import { NotificationService } from '../../../core/services/notification/notification.service';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Login, RememberMe } from 'src/app/store/auth/auth.actions';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ResetChar, ResetGame, SelectTable } from 'src/app/store/game/game.actions';
import {SetChairNumber, SetCheck} from 'src/app/store/helper/helper.action';
import { DataService } from 'src/app/core/services/data/data.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {environment} from '../../../../environments/environment';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, ViewDidEnter, ViewDidLeave {
  public loginUserForm: FormGroup;
  public selectOption = false;
  public countries = null;
  public selectedCountry: any = 'Select';
  public existingScreenOrientation = null;
  public addClass = false;
  public isLoading = false;
  public env = environment;
  public musicClick = null;

  constructor(private navController: NavController,
              private router: Router,
              private notificationService: NotificationService,
              private so: ScreenOrientation,
              private authService: AuthService,
              private musicPlayer: MusicService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private dataService: DataService,
              private store: Store) {

  this.existingScreenOrientation = this.so.type;

  // find out changes in orientation
  this.so.onChange().subscribe(
    () => {
      this.existingScreenOrientation = this.so.type;
    }
  );}

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    setTimeout(() => {this.addClass = true;},500);
    this.createLoginForm();
    this.store.dispatch(new SelectTable(null));
    this.store.dispatch(new ResetChar());
    this.store.dispatch(new ResetGame());
    this.store.dispatch(new SetChairNumber(null));
  }

  // Lock to portrait
  lockToPortrait(){
    this.so.lock(this.so.ORIENTATIONS.PORTRAIT);
  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  ionViewDidEnter(): void {
    this.store.dispatch(new SetCheck(1));
    this.createLoginForm();
  }

  createLoginForm(): void {
    const version = environment.version;
    this.loginUserForm = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required]),
      deviceId: new FormControl(null, []),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      rememberMe: new FormControl(false, []),
      version: new FormControl( version, []),
    }, {
      updateOn: 'submit'
    });

    const deviceId = this.store.snapshot().elitPokerAuth.deviceId;

    if (deviceId) {
      this.loginUserForm.controls.deviceId.setValue(deviceId);
    }
  }


  onSubmit(): void {

    // this.musicPlayer.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }

    this.loginUserForm.markAllAsTouched();

    if (this.loginUserForm.invalid) { return; }
    this.isLoading = true;



    this.musicPlayer.playClick();
    const data = {
      phoneNumber: this.loginUserForm.value.phoneNumber,
      deviceId: this.loginUserForm.value.deviceId,
      password: this.loginUserForm.value.password,
      rememberMe: this.loginUserForm.value.rememberMe,
      version: environment.version,
    };


    this.authService.login(data).subscribe(
      res => {
        this.store.dispatch(new Login(res));
        if (res.verificationType === 1) {
          //this.navController.navigateForward(['/otp']);
          this.router.navigate(['/otp']);
        }
        if (res.verificationType === 0) {
          //this.navController.navigateForward(['/game/welcome']);
          this.router.navigate(['/game/loader']);
        }
        console.log(res);
        this.isLoading = false;
      },
      async err => {
        this.isLoading = false;

        const msg = await this.translateService.get(err.error.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  ionViewDidLeave(): void {
    this.loginUserForm.reset();
  }
}
