import { MusicService } from '../../../core/services/music/music.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, ViewWillEnter } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { GameType } from 'src/app/core/enum/game-type-enum';
import { TablesInterface } from 'src/app/core/interfaces';
import { GetTableQuery } from 'src/app/core/models';
import { GameService } from 'src/app/core/services/game/game.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TableService } from 'src/app/core/services/table/table.service';
import { ResetGame, SelectTable } from 'src/app/store/game/game.actions';
import { PasswordComponent } from '../../../share/components/password/password.component';
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";
import {PickTable} from "../../../store/multiple-game/multiple-game.actions";

@Component({
  selector: 'app-all-tables',
  templateUrl: './all-tables.component.html',
  styleUrls: ['./all-tables.component.scss'],
})
export class AllTablesComponent implements OnInit, ViewWillEnter {
  @Input() selectedGameType: any = '';
  @Input() allActiveTables = null;
  @Input() allTables = null;
  @Output() closeModalEmit = new EventEmitter();
  @Output() openModalEmit = new EventEmitter();
  public getTableQueries = new GetTableQuery(null, 1, 1000, false);
  public fadeInRow = false;
  public gameTypeEnum = GameType;
  public tablesList: TablesInterface[] = [];
  public musicClick = null;

  constructor(private navController: NavController,
              private store: Store,
              private tableService: TableService,
              private notificationService: NotificationService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private modalCtrl: ModalController,
              private gameService: GameService,
              private musicService: MusicService,
              private router: Router) { }

  ngOnInit() {
    this.getAvailableTableForGame(this.selectedGameType?.id || '', this.allActiveTables);
    setTimeout(() => {this.fadeInRow = true;}, 500);
    this.musicClick = document.getElementById('click-tag');
  }

  ionViewWillEnter() {
    this.store.dispatch(new ResetGame());
  }

  queryTable(value) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.getAvailableTableForGame(value, this.allActiveTables);
  }

  getAvailableTableForGame(id, activeTable): void {
    this.getTableQueries.GameType = id === null ? '' : id;
    this.getTableQueries.JustActiveTables = activeTable ? activeTable : false;

    this.tableService.getAvailableTables(this.getTableQueries).subscribe(
      res => {
        this.tablesList = res.result;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  redirectToTable({gameKey, password}): void {
    //this.navController.navigateForward(['game'],{queryParams:{gameKey, password}});
    this.router.navigate(['game'],{queryParams:{gameKey, password}});
  }

  async askToJoin(table): Promise<void> {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    if(table.hasPassword) {
      const modal = await this.modalCtrl.create({
        component: PasswordComponent,
        componentProps: {gameKey: table.gameKey}
      });

      modal.onDidDismiss().then(data => {
        console.log(data?.data);
        if(data?.data.password && data?.data.password.length > 0) {
          this.store.dispatch(new SelectTable(table));
          this.store.dispatch(new PickTable(table));
          //this.navController.navigateForward(['game'],{queryParams:{gameKey: table.gameKey, password: data?.data.password}});
          this.router.navigate(['game'],{queryParams:{gameKey: table.gameKey, password: data?.data.password}});
        }
      });
      return modal.present();
    }
    // this.joinGame(gameKey, null);
    this.store.dispatch(new SelectTable(table));
    this.store.dispatch(new PickTable(table));

    //this.navController.navigateForward(['game'],{queryParams:{gameKey: table.gameKey}});
    this.router.navigate(['game'],{queryParams:{gameKey: table.gameKey}});
  }

  joinGame(gameKey, password): void {
    console.log(gameKey);
    this.gameService.joinTable({gameKey, password}).subscribe(
      res => {
        console.log(res);
        this.router.navigate(['game'],{queryParams:{gameKey, password}});
        //this.navController.navigateForward(['game'],{queryParams:{gameKey, password}});
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  closeModal() {
    this.closeModalEmit.emit(4);
    // this.musicService.playClick();
    // this.musicPlayer.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  openModal(value) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.openModalEmit.emit(value);
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
