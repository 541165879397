/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { MusicService } from 'src/app/core/services/music/music.service';
import { SetMusic} from 'src/app/store/helper/helper.action';

@Component({
  selector: 'app-music-check',
  templateUrl: './music-check.component.html',
  styleUrls: ['./music-check.component.scss'],
})
export class MusicCheckComponent implements OnInit {
  public musicTag = null;
  @Output() close = new EventEmitter<boolean>();

  constructor( private musicPlayer: MusicService,
                private store: Store,
              ) { }

  ngOnInit(): void {
    this.musicTag = document.getElementById('beep');
  }

  yes() {
    // this.musicPlayer.playBackgroundMusic();
    this.closeModal();
    this.store.dispatch(new SetMusic(true));
    // this.store.dispatch(new SetCheck(0));
    if (this.musicTag) { this.musicTag.play(); }
  }

  no() {
    this.closeModal();
    this.musicPlayer.mute();
    this.store.dispatch(new SetMusic(false));
    // this.store.dispatch(new SetCheck(0));
  }

  closeModal(): void {
    this.close.emit(false);
    // this.store.dispatch(new SetCheck(0));
  }
}
