import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public getMyCards = new Subject();
  public updateUserProfile = new Subject();
  public refreshNumberOfUser = new Subject();
  public startAnimationAgain = new Subject();
  public pullMoney = new Subject();
  public resetCards = new Subject();
  public reconnectedSuccessful = new Subject();
  public unsubscribeMyCards = new Subject();
  public stopAnimation = new Subject();
  public resetChair = new Subject();
  public actionPreBlock = new Subject();
  public removeCard = new Subject();
  public pickCardStatus = new Subject<boolean>();
  public preventActionBeforePickCard = new Subject<boolean>();
  public nextWinner = new Subject<number>();

  constructor() { }
}
