/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */

import {ReturnCardForUser, SetNumberOfChair, SetWaitingList, UpdateWinningCards} from './game.actions';
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {ResetChar, ResetGame, SelectTable, SetAuthUser, SetChairs, SetGameStatus, ShowMyCards} from './game.actions';
import {EventService} from '../../core/services/event/event.service';
import {
  SetAutoActionStatus,
  SetChairNumber,
  SetNewJackpot,
  SetShowPotAmount,
  UpdateCurrentJackpot
} from "../helper/helper.action";
import {SerTypingStatus} from "../chat/chat.actions";
const NEW_TABLE = {
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
  7: null,
  8: null,
  9: null
};
export interface GameStateModel {
  game: any;
  table: any;
  authUser: any;
  selectedTable: any;
  showMyCards: any;
  numberOfChair: number;
  waitingList: number;
  userWinningCards: any;
}

@Injectable()

@State<GameStateModel>({
  name: 'elitPokerGame',
  defaults: {
    game: null,
    authUser: null,
    selectedTable: null,
    showMyCards: true,
    table: NEW_TABLE,
    numberOfChair: null,
    waitingList: null,
    userWinningCards: [],
  }
})

export class GameState {

  constructor(private store: Store,
              private eventService: EventService) {}

  @Selector()
  static getGame(state: GameStateModel): any {
    return state.game || null;
  }

  @Selector()
  static getNumberOfChair(state: GameStateModel): any {
    return state.numberOfChair || null;
  }



  @Selector()
  static getTable(state: GameStateModel): any {
    return state.selectedTable || null;
  }

  @Selector()
  static getWaitingList(state: GameStateModel): any {
    return state.waitingList || null;
  }

  @Selector()
  static getAuthUserGameInfo(state: GameStateModel): any {
    return state.authUser || null;
  }

  @Selector()
  static getSelectedTable(state: GameStateModel): any {
    return state.selectedTable || null;
  }

  @Action(SetGameStatus)
  setGameStatus(ctx: StateContext<GameStateModel>, {game}: SetGameStatus): void {

    const authUserId = this.store.snapshot().elitPokerAuth.user.userId;
    const user = game.users.find((x)=> x.id === authUserId);
    ctx.patchState({
      game
    });
    console.log('GAME STATUS EVENT', game)

    this.store.dispatch(new UpdateCurrentJackpot(game.jackpotAmount))

    if(game.maxNumberOfUsers === 9) {
      if(game.users.length && user && user.status !== 4) {
        const usersMapped = this.mapUserSeat(game.users, user.chairNumber, 5, game.maxNumberOfUsers)
        ctx.dispatch(new SetChairs(usersMapped));
      } else {
        ctx.dispatch(new SetChairs(game.users));
      }
    }


    if(game.maxNumberOfUsers === 7) {
      if(game.users.length && user && user.status !== 4) {
        const usersMapped = this.mapUserSeat(game.users, user.chairNumber, 4, game.maxNumberOfUsers)
        ctx.dispatch(new SetChairs(usersMapped));
      } else {
        ctx.dispatch(new SetChairs(game.users));
      }
    }

    if(game.maxNumberOfUsers === 6) {
      if(game.users.length && user && user.status !== 4) {
        const usersMapped = this.mapUserSeat(game.users, user.chairNumber, 4, game.maxNumberOfUsers)
        ctx.dispatch(new SetChairs(usersMapped));
      } else {
        ctx.dispatch(new SetChairs(game.users));
      }
    }

    ctx.dispatch(new SetShowPotAmount(game.potAmounts));

    ctx.dispatch(new SetAuthUser(user));
    if(user?.chairNumber) {
      ctx.dispatch(new SetChairNumber(user.chairNumber));
    } else if  (user?.status === 4){
      ctx.dispatch(new SetChairNumber(null));
    } else if  (!user) {
      ctx.dispatch(new SetChairNumber(null));
      this.eventService.resetChair.next();
    };

    ctx.dispatch(new SetAutoActionStatus(user.autoAction));



  }

  @Action(ResetGame)
  resetGame(ctx: StateContext<GameStateModel>, {}: ResetGame): void {

    ctx.patchState({
      game: null,
      authUser: null,
      selectedTable: null,
      showMyCards: true,
      table: NEW_TABLE,
      numberOfChair: null,
      waitingList: null,
    });

  }


  @Action(ShowMyCards)
  showMyCards(ctx: StateContext<GameStateModel>, {showMyCards}: ShowMyCards): void {

    ctx.patchState({
      showMyCards
    });

  }

  @Action(SetWaitingList)
  setWaitingList(ctx: StateContext<GameStateModel>, {waitingList}: SetWaitingList): void {

    ctx.patchState({
      waitingList
    });

  }

  @Action(SetNumberOfChair)
  setNumberOfChair(ctx: StateContext<GameStateModel>, {numberOfChair}: SetNumberOfChair): void {

    ctx.patchState({
      numberOfChair
    });

  }

  @Action(SelectTable)
  selectTable(ctx: StateContext<GameStateModel>, {selectedTable}: SelectTable): void {

    ctx.patchState({
      selectedTable
    });

  }



  @Action(UpdateWinningCards)
  updateWinningCards(ctx: StateContext<GameStateModel>, {users}: UpdateWinningCards): void {
    const state = ctx.getState();

    ctx.setState({
        ...state,
        userWinningCards: users
      }
    );

  }


  @Action(SetAuthUser)
  setAuthUser(ctx: StateContext<GameStateModel>, {user}: SetAuthUser): void {
    ctx.patchState({authUser: user});
  }

  @Action(ReturnCardForUser)
  returnCardsForUser(ctx: StateContext<GameStateModel>, {}: ReturnCardForUser): void {
    const authUser = ctx.getState().authUser;
    authUser.discardCardNumber = 0;
    ctx.patchState({authUser});
  }



  @Action(SerTypingStatus)
  setTypingStatus(ctx: StateContext<GameStateModel>, {id,username,status}: SerTypingStatus): void {

    const userInList = ctx.getState().game.users.find( user => user.id === id);
    if(!userInList) return;
    console.log(userInList)
    const table = ctx.getState().table;
    table[userInList.chairNumber].isTyping = status

    console.log('wwwwwww')
    ctx.patchState({
      table
    });

  }



  @Action(ResetChar)
  resetChar(ctx: StateContext<GameStateModel>, {}: ResetChar): void {
    const state = ctx.getState();

    ctx.setState({
        ...state,
        table: NEW_TABLE
      }
    );
  }

  @Action(SetChairs)
  setPositionOnTable(ctx: StateContext<GameStateModel>, {users}: SetChairs): void {
    const state = ctx.getState();

    const newTable = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null
    };

    for ( let i = 0; i < users.length; i++) {
      newTable[users[i].chairNumber] = users[i];
    }

    ctx.setState({
        ...state,
        table: newTable
      }
    );
  }

  mapUserSeat(users, playerChairNumber, centerSpotChairNumber, maxChairNumber) {

    const moveStep = this.getCorrectChairNumber(playerChairNumber - centerSpotChairNumber, maxChairNumber);

    users.forEach((user) => {
      user.chairNumber = this.getCorrectChairNumber(user.chairNumber - moveStep, maxChairNumber);
    });

    return users;
  }

  getCorrectChairNumber( chairNumber, maxChairNumber) {
  if (chairNumber < 1) {return chairNumber + maxChairNumber;}
  if (chairNumber > maxChairNumber) return chairNumber - maxChairNumber;
  return chairNumber;
}

}
