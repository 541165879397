import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {UserService} from "../../../core/services/user/user.service";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public isLoading = false;
  @Output() joinGame = new EventEmitter();
  @Input("gameKey") gameKey;
  


  constructor(private modalCtrl: ModalController,
              private notificationService: NotificationService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private store: Store,
              private userService: UserService) { }

  ngOnInit() {
    console.log('game key', this.gameKey);
  }

  close(val: string): void {
    this.modalCtrl.dismiss({
      password: val ?? null,
    });
  }

  submit(pass): void {
    this.isLoading = true;
    const data = {
      gameKey: this.gameKey,
      password: pass
    };


    this.userService.checkTablePassword(data).subscribe(
      res => {
        this.isLoading = false;
        console.log(res);
        if (res) {
          console.log('pusti dalje');
          this.close(pass);

        } else {
          console.log('nema dalje');
          this.notificationService.showError('Wrong Password');
          this.close('');
        }
      },
      async err => {
        this.isLoading = false;
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
