import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDoubleTap]'
})
export class DoubleTapDirective {
  public count = 0;

  constructor() { }

  @Output() dbTapClick = new EventEmitter();

  @HostListener('click') onClick() {
    this.checkClick();
  }


  checkClick(): void {
    this.count++;
    setTimeout(() => {
    if(this.count > 1) {
        console.log('click na direktivi double')
        this.dbTapClick.emit();
        this.count = 0;
      }
    this.count = 0;
    }, 300);
  }

}
