import {UserService} from "../../../core/services/user/user.service";
const AVATAR_BASE = '/assets/avatars/'
declare var $: any;

import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {MusicService} from "../../../core/services/music/music.service";
import {ViewWillEnter} from "@ionic/angular";
import {SetAvatar} from "../../../store/helper/helper.action";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-select-avatar',
  templateUrl: './select-avatar.component.html',
  styleUrls: ['./select-avatar.component.scss'],
})
export class SelectAvatarComponent implements OnInit, AfterViewInit, ViewWillEnter {
  @ViewChild('carousel') el: ElementRef;
  constructor(private userService: UserService,
              private router: Router,
              private store: Store,
              private musicService: MusicService) { }

  public avatars = ['avatar1.png','avatar2.png','avatar3.png','avatar4.png','avatar5.png','avatar6.png','avatar7.png',
    'avatar8.png','avatar9.png','avatar10.png','avatar11.png','avatar12.png','avatar13.png','avatar14.png','avatar15.png','avatar16.png',
    'avatar17.png','avatar18.png','avatar19.png','avatar20.png'];
  public selectedAvatar = '';
  public musicClick = null;

  ngAfterViewInit() {
    $('.owl-carousel').owlCarousel({
      loop: true,
      nav: false,
      items: 3,
      dots: false,
      center: true
    });

    let id;

    $('.owl-next').click((e) =>  {
      $('.owl-carousel').trigger('next.owl.carousel');
    });

    $('.owl-prev').click((e) =>  {
      $('.owl-carousel').trigger('prev.owl.carousel');
    });


    $('.owl-carousel').on('prev.owl.carousel', (e) =>  {
      id = $('.owl-carousel').find('.center').children('.item').children('a')[0].getAttribute('id');
      this.selectedAvatar = environment.domain + AVATAR_BASE + id;
      // this.musicService.playClick();
      if (this.musicClick) {
        this.musicClick.play();
      }
      console.log("selected avatar" + this.selectedAvatar);
    });

    $('.owl-carousel').on('next.owl.carousel', (e) => {
      id = $('.owl-carousel').find('.center').children('.item').children('a')[0].getAttribute('id');
      this.selectedAvatar = environment.domain + AVATAR_BASE + id;
      // this.musicService.playClick();
      if (this.musicClick) {
        this.musicClick.play();
      }
      console.log("selected avatar" + this.selectedAvatar);
    });

  }

  ionViewWillEnter() {
    this.selectedAvatar = environment.domain + AVATAR_BASE + this.avatars[0];
  }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    this.selectedAvatar = this.avatars[0];
  }

  skip() {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  selectNewAvatar() {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.store.dispatch(new SetAvatar(true));
    this.userService.editAvatar({avatar:this.selectedAvatar}).subscribe(
      res => {
        this.router.navigate(['game/choose_game']);
      },
      err => {console.log(err)}
    )
  }

}
