export class TableFields {
  'NEIGHBOURS#00' = false;
  'NEIGHBOURS#01' = false;
  'NEIGHBOURS#02' = false;
  'NEIGHBOURS#03' = false;
  'NEIGHBOURS#04' = false;
  'NEIGHBOURS#05' = false;
  'NEIGHBOURS#06' = false;
  'NEIGHBOURS#07' = false;
  'NEIGHBOURS#08' = false;
  'NEIGHBOURS#09' = false;
  'NEIGHBOURS#10' = false;
  'NEIGHBOURS#11' = false;
  'NEIGHBOURS#12' = false;
  'NEIGHBOURS#13' = false;
  'NEIGHBOURS#14' = false;
  'NEIGHBOURS#15' = false;
  'NEIGHBOURS#16' = false;
  'NEIGHBOURS#17' = false;
  'NEIGHBOURS#18' = false;
  'NEIGHBOURS#19' = false;
  'NEIGHBOURS#20' = false;
  'NEIGHBOURS#21' = false;
  'NEIGHBOURS#22' = false;
  'NEIGHBOURS#23' = false;
  'NEIGHBOURS#24' = false;
  'NEIGHBOURS#25' = false;
  'NEIGHBOURS#26' = false;
  'NEIGHBOURS#27' = false;
  'NEIGHBOURS#28' = false;
  'NEIGHBOURS#29' = false;
  'NEIGHBOURS#30' = false;
  'NEIGHBOURS#31' = false;
  'NEIGHBOURS#32' = false;
  'NEIGHBOURS#33' = false;
  'NEIGHBOURS#34' = false;
  'NEIGHBOURS#35' = false;
  'NEIGHBOURS#36' = false;
  'STRAIGHT#00' = false;
  'STRAIGHT#01' = false;
  'STRAIGHT#02' = false;
  'STRAIGHT#03' = false;
  'STRAIGHT#04' = false;
  'STRAIGHT#05' = false;
  'STRAIGHT#06' = false;
  'STRAIGHT#07' = false;
  'STRAIGHT#08' = false;
  'STRAIGHT#09' = false;
  'STRAIGHT#10' = false;
  'STRAIGHT#11' = false;
  'STRAIGHT#12' = false;
  'STRAIGHT#13' = false;
  'STRAIGHT#14' = false;
  'STRAIGHT#15' = false;
  'STRAIGHT#16' = false;
  'STRAIGHT#17' = false;
  'STRAIGHT#18' = false;
  'STRAIGHT#19' = false;
  'STRAIGHT#20' = false;
  'STRAIGHT#21' = false;
  'STRAIGHT#22' = false;
  'STRAIGHT#23' = false;
  'STRAIGHT#24' = false;
  'STRAIGHT#25' = false;
  'STRAIGHT#26' = false;
  'STRAIGHT#27' = false;
  'STRAIGHT#28' = false;
  'STRAIGHT#29' = false;
  'STRAIGHT#30' = false;
  'STRAIGHT#31' = false;
  'STRAIGHT#32' = false;
  'STRAIGHT#33' = false;
  'STRAIGHT#34' = false;
  'STRAIGHT#35' = false;
  'STRAIGHT#36' = false;
  'SPLIT#01-02' = false;
  'SPLIT#01-04' = false;
  'SPLIT#02-03' = false;
  'SPLIT#02-05' = false;
  'SPLIT#03-06' = false;
  'SPLIT#04-05' = false;
  'SPLIT#04-07' = false;
  'SPLIT#05-06' = false;
  'SPLIT#05-08' = false;
  'SPLIT#06-09' = false;
  'SPLIT#07-08' = false;
  'SPLIT#07-10' = false;
  'SPLIT#08-09' = false;
  'SPLIT#08-11' = false;
  'SPLIT#09-12' = false;
  'SPLIT#10-11' = false;
  'SPLIT#10-13' = false;
  'SPLIT#11-12' = false;
  'SPLIT#11-14' = false;
  'SPLIT#12-15' = false;
  'SPLIT#13-14' = false;
  'SPLIT#13-16' = false;
  'SPLIT#14-15' = false;
  'SPLIT#14-17' = false;
  'SPLIT#15-18' = false;
  'SPLIT#16-17' = false;
  'SPLIT#16-19' = false;
  'SPLIT#17-18' = false;
  'SPLIT#17-20' = false;
  'SPLIT#18-21' = false;
  'SPLIT#19-20' = false;
  'SPLIT#19-22' = false;
  'SPLIT#20-21' = false;
  'SPLIT#20-23' = false;
  'SPLIT#21-24' = false;
  'SPLIT#22-23' = false;
  'SPLIT#22-25' = false;
  'SPLIT#23-24' = false;
  'SPLIT#23-26' = false;
  'SPLIT#24-27' = false;
  'SPLIT#25-26' = false;
  'SPLIT#25-28' = false;
  'SPLIT#26-27' = false;
  'SPLIT#26-29' = false;
  'SPLIT#27-30' = false;
  'SPLIT#28-29' = false;
  'SPLIT#28-31' = false;
  'SPLIT#29-30' = false;
  'SPLIT#29-32' = false;
  'SPLIT#30-33' = false;
  'SPLIT#31-32' = false;
  'SPLIT#31-34' = false;
  'SPLIT#32-33' = false;
  'SPLIT#32-35' = false;
  'SPLIT#33-36' = false;
  'SPLIT#34-35' = false;
  'SPLIT#35-36' = false;
  'STREET#01-02-03' = false;
  'STREET#04-05-06' = false;
  'STREET#07-08-09' = false;
  'STREET#10-11-12' = false;
  'STREET#13-14-15' = false;
  'STREET#16-17-18' = false;
  'STREET#19-20-21' = false;
  'STREET#22-23-24' = false;
  'STREET#25-26-27' = false;
  'STREET#28-29-30' = false;
  'STREET#31-32-33' = false;
  'STREET#34-35-36' = false;
  'SIXLINE#01-02-03-04-05-06' = false;
  'SIXLINE#04-05-06-07-08-09' = false;
  'SIXLINE#07-08-09-10-11-12' = false;
  'SIXLINE#10-11-12-13-14-15' = false;
  'SIXLINE#13-14-15-16-17-18' = false;
  'SIXLINE#16-17-18-19-20-21' = false;
  'SIXLINE#19-20-21-22-23-24' = false;
  'SIXLINE#22-23-24-25-26-27' = false;
  'SIXLINE#25-26-27-28-29-30' = false;
  'SIXLINE#28-29-30-31-32-33' = false;
  'SIXLINE#31-32-33-34-35-36' = false;
  'CORNER#01-02-04-05' = false;
  'CORNER#02-03-05-06' = false;
  'CORNER#04-05-07-08' = false;
  'CORNER#05-06-08-09' = false;
  'CORNER#07-08-10-11' = false;
  'CORNER#08-09-11-12' = false;
  'CORNER#10-11-13-14' = false;
  'CORNER#11-12-14-15' = false;
  'CORNER#13-14-16-17' = false;
  'CORNER#14-15-17-18' = false;
  'CORNER#16-17-19-20' = false;
  'CORNER#17-18-20-21' = false;
  'CORNER#19-20-22-23' = false;
  'CORNER#20-21-23-24' = false;
  'CORNER#22-23-25-26' = false;
  'CORNER#23-24-26-27' = false;
  'CORNER#25-26-28-29' = false;
  'CORNER#26-27-29-30' = false;
  'CORNER#28-29-31-32' = false;
  'CORNER#29-30-32-33' = false;
  'CORNER#31-32-34-35' = false;
  'CORNER#32-33-35-36' = false;
  'TRIO#00-01-02' = false;
  'TRIO#00-02-03' = false;
  'BASKET' = false;
  'RED' = false;
  'BLACK' = false;
  'ODD' = false;
  'EVEN' = false;
  'LOW' = false;
  'HIGH' = false;
  'DOZEN1' = false;
  'DOZEN2' = false;
  'DOZEN3' = false;
  'COLUMN1' = false;
  'COLUMN2' = false;
  'COLUMN3' = false;
  'VOISINSDUZERO' = false;
  'ORPHELINS' = false;
  'TIER' = false;

}
