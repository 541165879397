import { MusicService } from '../../../core/services/music/music.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { Store } from '@ngxs/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserInterface, UsersNumberInterface } from 'src/app/core/interfaces';
import { ResetGame } from 'src/app/store/game/game.actions';
import { Logout, SetBalance } from 'src/app/store/auth/auth.actions';
import { UserService } from 'src/app/core/services/user/user.service';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import {EventService} from '../../../core/services/event/event.service';
import {Subscription} from 'rxjs';
import {DataService} from '../../../core/services/data/data.service';
import { HelperState } from 'src/app/store/helper/helper.state';
import {AuthService} from '../../../core/services/auth/auth.service';
import {AuthState} from '../../../store/auth/auth.state';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
})
export class HeaderMainComponent implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  @Output() triggerProfileModalEmit = new EventEmitter();
  @Output() triggerBuyCoinsModalEmit = new EventEmitter();
  public userAvatar: any;
  public countries = [];
  public myCountry = null;
  public userData: UserInterface;
  public usersNumber: UsersNumberInterface;
  public muteSound: boolean;
  public balance$ = this.store.select(AuthState.getUserBalance);
  public balance = null;
  public userDetails = null;
  private subscribe = new Subscription();
  private userDataInterval = null;
  public musicClick = null;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private store: Store,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dataService: DataService,
    private musicService: MusicService,
    private errorLogService: ErrorLogService,
    private translateService: TranslateService,
    private eventService: EventService,
    private notificationService: NotificationService,
  ) { }

  async ngOnInit() {
    this.muteSound = this.musicService.isMuted.getValue();
    this.musicClick = document.getElementById('click-tag');

    await this.getCountry();
    this.getBalance();
    this.getUserData();
    this.getUsersNumber();
    this.store.dispatch(new ResetGame());
    this.subscribe.add(
      this.eventService.updateUserProfile.subscribe(() => {
        this.getBalance();
        this.getUserData();
        this.getUsersNumber();
      })
    );

    this.subscribe.add(
      this.eventService.refreshNumberOfUser.subscribe(() => {
        this.getUsersNumber();
      })
    );

    this.userDetails = this.store.snapshot().elitPokerAuth.details;
  }

  ionViewWillEnter(): void {
    this.userDetails = this.store.snapshot().elitPokerAuth.details;
    this.muteSound = this.musicService.isMuted.getValue();

    this.subscribe.add(
      this.eventService.updateUserProfile.subscribe(() => {
        this.getBalance();
        this.getUserData();
        this.getUsersNumber();
      })
    );

    this.getBalance();
    this.getUserData();
    this.getUsersNumber();
    this.userDetails = this.store.snapshot().elitPokerAuth.details;

  };

  getCountry() {
    return new Promise<void>((resolve, reject) =>  {
      this.dataService.getCountries().subscribe(
        res => {
          this.countries = res.list;
          resolve();
        },
        async err => {
          const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
          this.notificationService.showError(msg);
          const store = this.store.snapshot();

          const errorData = {
            AppName: 'Elites Poker',
            DeviceId: store.elitPokerAuth?.deviceId,
            UserId: store.elitPokerAuth?.details?.userId,
            PageUrl: window.location.pathname,
            ErrorDescription:  msg,
          };

          reject();
          this.logOnServer(errorData);
          console.log(err);
        }
      );
    });

  }

  muteSoundFunction(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.musicService.isMuted.getValue() ? this.musicService.unmute() : this.musicService.mute();
    this.muteSound = this.musicService.isMuted.getValue();

  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.balance = res.balance;
        this.store.dispatch(new SetBalance(res.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }


  getUserData(): void {
    this.userService.getUser().subscribe(
      res => {
        this.userData = res;
        this.userAvatar = this.userData?.avatar?.length ? this.sanitizer.bypassSecurityTrustResourceUrl(this.userData.avatar) : null;
        this.userData.userName = this.userData.userName.charAt(0).toUpperCase() + this.userData.userName.substr(1);
        console.log('User Data: ', this.userData);
        this.countries.forEach(x => {
          if (x.name === this.userData?.country) {
            this.myCountry = x?.iso2;
          }
        });
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getUsersNumber(): void {
    this.userService.getUsersNumber().subscribe(
      res => {
        this.usersNumber = res;
        console.log('Users number: ', this.usersNumber);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  playMusicFunc(): void {
  }

  logout(): void {
    this.authService.logout().subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
    this.store.dispatch(new Logout());
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicService.stopBackgroundMusic();
    this.router.navigate(['/login']);

  }

  ionViewWillLeave(): void {
    this.subscribe.unsubscribe();
    clearInterval(this.userDataInterval);
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    clearInterval(this.userDataInterval);
  }

  triggerProfileModal(){
    this.triggerProfileModalEmit.emit(true);
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  triggerBuyCoinsModal(){
    this.triggerBuyCoinsModalEmit.emit(true);
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
