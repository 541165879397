import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  AddWinningNumber, CurrentWin, LastWin,
  SelectGame,
  SetCurrentRound,
  SetGameRules,
  SetGameStatus, SetIsGameStartStatus,
  SetNextWinningNumber,
  SetSyncStatus
} from './game-roulette.actions';
import { Injectable } from '@angular/core';
import {AvailableGameModel, GameRoundModel} from '../../core/models';
import {CssService} from '../../core/services/css-service/css-service.service';

export interface RouletteGameStateModel {
  currentGame: AvailableGameModel;
  gameRound: GameRoundModel;
  winningNumbers: number[];
  winningNumber: number;
  lastWin: number;
  currentWin: number;
  gameStatus: string;
  sync: boolean;
  isGameStart: boolean;
  biggestWinners: {userName: string, amount: number}[];
}

@Injectable()

@State<RouletteGameStateModel>({
  name: 'rouletteGame',
  defaults: {
    sync: false,
    currentGame: null,
    gameRound: null,
    lastWin: 0,
    currentWin: 0,
    gameStatus: 'IDLE',
    winningNumbers: [],
    winningNumber: null,
    biggestWinners: [],
    isGameStart: null,
  }
})

export class GameRoulleteState {

  constructor(private cssService: CssService) {
  }

  @Selector()
  static getBiggestWinners(state: RouletteGameStateModel): any {
    return state.biggestWinners || null;
  }

  @Selector()
  static getIsGameStartStatus(state: RouletteGameStateModel): any {
    return state.isGameStart || false;
  }

  @Selector()
  static getCurrentGame(state: RouletteGameStateModel): any {
    return state.currentGame || null;
  }
  @Selector()
  static getLastWin(state: RouletteGameStateModel): any {
    return state.lastWin || 0;
  }

  @Selector()
  static getGameId(state: RouletteGameStateModel): any {
    return state.gameRound.gameId || null;
  }

  @Selector()
  static getCurrentWin(state: RouletteGameStateModel): any {
    return state.currentWin || 0;
  }

  @Selector()
  static getMinBet(state: RouletteGameStateModel): any {
    return state.gameRound.rules.minBet || null;
  }

  @Selector()
  static getMaxBet(state: RouletteGameStateModel): any {
    return state.gameRound.rules.maxBet || null;
  }

  @Selector()
  static getMinBetNo(state: RouletteGameStateModel): any {
    return state.gameRound.rules.maxStraightBet || null;
  }

  @Selector()
  static getDealerName(state: RouletteGameStateModel): any {
    return state.gameRound.dealerName || null;
  }

  @Selector()
  static getWinningNumbers(state: RouletteGameStateModel): number[] {
    return state.winningNumbers || [];
  }

  @Action(SelectGame)
  setCurrentGame(ctx: StateContext<RouletteGameStateModel>, {data}: SelectGame): void {
    ctx.patchState({
      currentGame: data
    });
  }

  @Action(SetNextWinningNumber)
  setNextWinningNumber(ctx: StateContext<RouletteGameStateModel>, {winningNumber}: SetNextWinningNumber): void {
    ctx.patchState({
      winningNumber
    });
  }

  @Action(SetGameStatus)
  setGameStatus(ctx: StateContext<RouletteGameStateModel>, {gameStatus}: SetGameStatus): void {
    ctx.patchState({
      gameStatus
    });
  }

  @Action(SetCurrentRound)
  setCurrentRound(ctx: StateContext<RouletteGameStateModel>, {gameRound}: SetCurrentRound): void {

    const newCssVariables = {
      '--game-spin-timeout-in-seconds': gameRound.rules.gameSpinTimeoutInSeconds + 's'
    };

    this.cssService.change(newCssVariables);
    ctx.patchState({
      gameRound,
      biggestWinners: gameRound.biggestWinners
    });

  }

  @Action(SetSyncStatus)
  setSyncGame(ctx: StateContext<RouletteGameStateModel>, {status}: SetSyncStatus): void {
    ctx.patchState({
      sync: status,
    });
  }


  @Action(AddWinningNumber)
  addWinningNumber(ctx: StateContext<RouletteGameStateModel>, {winningNumber}: AddWinningNumber): void {
    const oldWiningNumber = ctx.getState().winningNumber;

    const winningNumbers = ctx.getState().winningNumbers;
    if (oldWiningNumber) {
      winningNumbers.unshift(oldWiningNumber);
    }

    if (winningNumbers.length > 10) {
      winningNumbers.pop();
    }

    ctx.patchState({
      winningNumbers
    });
  }

  @Action(LastWin)
  setLastWin(ctx: StateContext<RouletteGameStateModel>, {lastWin}: LastWin): void {

    ctx.patchState({
      lastWin,
      currentWin: lastWin
    });
  }

  @Action(CurrentWin)
  setCurrentWin(ctx: StateContext<RouletteGameStateModel>, {currentWin}: CurrentWin): void {

    ctx.patchState({
      currentWin
    });
  }

  @Action(SetIsGameStartStatus)
  setIsGameStartStatus(ctx: StateContext<RouletteGameStateModel>, {isGameStart}: SetIsGameStartStatus): void {

    ctx.patchState({
      isGameStart
    });
  }

}
