/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable guard-for-in */
export function objectKeyToArray(input): any[] {
  const output = [];

  for (const key in  input) {
    output.push(input[key]);
  }

  return output;
}

export function createElementFromHTML(htmlString): ChildNode {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
};

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
