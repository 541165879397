import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss'],
})
export class CarouselSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  public slides = [
    {img: "/assets/images/virtual-poker.png"},
    {img: "/assets/images/live-poker.png"},
    {img: "/assets/images/roulette.png"},
    {img: "/assets/images/virtual-poker.png"},
    {img: "/assets/images/live-poker.png"},
    {img: "/assets/images/roulette.png"},
  ];

  public slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "infinite": true,
  };

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }


}
