import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JoinTableModel } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) { }

  message(data: any): Observable<any> {
    return this.http.post<any>( environment.pokerURL + `/gametables/message`, data);
  }

  getTableInfo(gameKey: string): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.get<any>( environment.pokerURL + `/user/gameinfo`,{headers});
  }

  getJoinTableInfo(gameKey: string): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    const data = {
      gameKey
    };
    return this.http.get<any>( environment.pokerURL + `/user/jointable`,{headers, params:data});
  }

  getWaitingListInfo(gameKey: string): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.get<any>( environment.pokerURL + `/user/waitinglist?gameKey=${gameKey}`,{headers});
  }

  discardCards(data, gameKey: string): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/discardcards`, data,{headers});
  }


  joinTable(data: JoinTableModel): Observable<any> {
    return this.http.post<any>( environment.pokerURL + `/user/jointable`, data);
  }

  onceOrTwice(data: any, gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);

    return this.http.post<any>( environment.pokerURL + `/gametables/onceortwice`, data, {headers});
  }

  boyCoins(data: any, gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);

    return this.http.post<any>( environment.pokerURL + `/gametables/addcash`, data, {headers});
  }

  joinWaitingList(gameKey, data: JoinTableModel): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/user/jointablewaitlist`, data,{headers});
  }

  leaveTable(gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/leave`,{},{headers});
  }

  getMyCard(gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.get<any>( environment.pokerURL + `/gametables/mycards`,{headers});
  }

  getLog(gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.get<any>( environment.pokerURL + `/gametables/log`,{headers});
  }

  bet(data, gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/bet`,data, {headers});
  }

  fold(gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/fold`,{},{headers});
  }


  payBigBlind(gameKey): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/paybigblind`,{},{headers});
  }

  getStatistics(): Observable<any> {
    return this.http.get<any>( environment.bettingURL + `/user/statistics`);
  }
}

