export class SetTotalBet {
  static readonly type = '[Bet] SetTotalBet';
  constructor(public totalBet: any) {}
}

export class SetBalance {
  static readonly type = '[Bet] SetBalance';
  constructor(public balance: any) {}
}
export class ResetBets {
  static readonly type = '[Bet] ResetBets';
  constructor(public balance: any) {}
}

export class SetCoinValue {
  static readonly type = '[Bet] SetCoinValue';
  constructor(public coinValue: any) {}
}
