import {GameRuleModel} from './GameRuleModel';

export class GameRoundModel {
  biggestWinners: any[];
  dealerName: string;
  gameId: string;
  nextRoundTime: string;
  roundStatus: string;
  roundToken: string;
  winningNumber: number;
  lastWinningNumber: number;
  winningCombinations: string[];
  rules: GameRuleModel;
}
