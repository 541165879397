import { UserService } from 'src/app/core/services/user/user.service';
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GameState} from '../../../../store/game/game.state';
import {UserStatusEnum} from '../../../../core/enum/user-status-enum';
import {CardModel} from '../../../../core/models';
import {Subscription} from 'rxjs';
import {HelperState} from '../../../../store/helper/helper.state';
import {Store} from '@ngxs/store';
import {GameService} from '../../../../core/services/game/game.service';
import {NotificationService} from '../../../../core/services/notification/notification.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MusicService} from '../../../../core/services/music/music.service';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../../../core/services/event/event.service';
import {UpdateWinningCards} from '../../../../store/game/game.actions';
import { UserInterface } from 'src/app/core/interfaces';
import { cardAnimation } from 'src/app/animations/card.animations';
import { coinAnimation, pullCoinAnimation } from 'src/app/animations/coin.animations';
import {FriendsState} from "../../../../store/friends/friends.state";
import { Platform } from '@ionic/angular';
import {DataService} from "../../../../core/services/data/data.service";
import {ErrorLogService} from "../../../../core/services/error-log/error-log.service";

@Component({
  selector: 'app-user-seat',
  templateUrl: './user-seat.component.html',
  styleUrls: ['./user-seat.component.scss'],
  animations: [
    cardAnimation,
    coinAnimation,
    pullCoinAnimation
  ]
})
export class UserSeatComponent implements OnInit, OnDestroy {
  @Input() status = '';
  @Input() shareCards: any = null;
  @Input() position: any = null;
  @Input() positionCss: any = null;
  @Output() userIsActiv = new EventEmitter();
  public isActive = false;
  public testCards = [{value:7,suit:2},{value:14,suit:3},{value:9,suit:3}];
  public modalPickUp = true;
  public isMyTurn = false;
  public isAuthUserActive = false;
  public newGameStart = true;
  public user;
  public userOnChar = null;
  public userAuthState;
  public joinGame;
  public userWithCurrentStatus;
  public intervalForUserAction;
  public userStatusEnum = UserStatusEnum;
  public gameKey;
  public countDownInterval = null;
  public countDown = 0;
  public defaultCards: CardModel[] = [];
  public myCardsRequest;
  public game;
  public winingCardsSplash = true;
  public animationTimeout;
  public animationTimeoutPreBlock;
  public offset = null;
  public numberOfChair: number = null;
  public pickChair = true;
  public userAvatar: any;
  public getWinner$ = this.store.select(HelperState.getWinner);
  public coinAnimationData: any = null;
  public pullCoinAnimationData: any = null;
  public userWin = false;
  public pullMoneyPosition = 4;
  public pullMoney: any = true;
  public animationTest = false;
  public getCards = true ;
  public straitLine = false;
  public userWait = false;
  public userData: UserInterface = null;
  private subscription = new Subscription();
  public myCountry = null;
  public countries = [];
  public isFriend = null;
  public pending = false;
  public isApk: boolean;
  public clockInterval = null;

  public authUserInGame$ = this.store.select(GameState.getAuthUserGameInfo);

  @HostListener('window:focus', ['$event'])
  detectFocus(event) {
    if (window.location.pathname !== '/login') {
      if (this.isActive) {
        this.startUserActionInterval();
      }
    }

    // this.store.dispatch(new SetConnecting(true));
  }


  @HostListener('window:blur', ['$event'])
  detectBlur(event) {
    if (window.location.pathname !== '/login') {
        this.stopUserActionInterval()
    }
  }

  constructor(private store: Store,
              public platform: Platform,
              private gameService: GameService,
              private dataService: DataService,
              private notificationService: NotificationService,
              private sanitizer: DomSanitizer,
              private musicService: MusicService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private userService: UserService,
              private eventService: EventService) { }

  appendClass() {
    if(this.defaultCards.length === 0) {
      this.getCards = true;

    }
  }
  ngOnInit() {
    this.gameKey = this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey;

    const gameSnapshot = this.store.snapshot().elitPokerGame?.game;

    this.isApk = window.hasOwnProperty('cordova');


    this.setUserPositionAnimation(this.positionCss);
    this.numberOfChair = this.store.snapshot().elitPokerGame?.numberOfChair;

    this.getUserData();


    this.eventService.removeCard.subscribe(res => this.removeCardFromArray(res));

    // this.subscription.add(
    //   this.eventService.stopAnimation.subscribe(res => this.stopUserActionInterval())
    // );

    this.subscription.add(
      this.eventService.startAnimationAgain.subscribe(res => {
        setTimeout(() => {
          if (this.isActive) {
            this.startUserActionInterval();
          } else  {
            this.stopUserActionInterval();

          }
        },10)

      })
    );

    // this.subscription.add(
    //   this.eventService.getMyCards.subscribe(res => {
    //       this.getMyCards()
    //
    //   })
    // );

    this.subscription.add(
      this.store.select(FriendsState.getFriendList).subscribe(res => this.checkUserStatus())
    );

    this.subscription.add(
      this.eventService.resetCards.subscribe(res => this.getCards = false)
    );

    this.subscription.add(
      this.store.select( GameState.getGame).subscribe(
        game => {


          const authUser = this.store.snapshot().elitPokerAuth;
          const authUserInfo = this.store.snapshot().elitPokerGame?.authUser;
          this.userOnChar = this.store.snapshot().elitPokerGame?.table[this.position];

          if(authUserInfo?.chairNumber === this.position && this.defaultCards.length === 0 ) {
            this.getMyCards();
          }

          this.game = game;
          this.isActive = game?.activeUserId === this.user?.id;
          this.isMyTurn = authUser.user?.userId === game?.activeUserId;

          if(this.store.snapshot().elitPokerGame?.table[this.position]?.discardCardNumber > 0 && this.isMyTurn && this.isActive){
            this.modalPickUp = true;
            this.eventService.pickCardStatus.next(false);
          }



          if (game?.status === 10) {
            this.straitLine = true;
          }

          if (game?.status === 20 && !game.activeUserId) {

            this.getCards =  false;
            this.straitLine = false;
            setTimeout(() => {
              this.getCards = true;
              this.musicService.playDealingCards();

            }, 500);

          }


          if (game?.winningInfo !== undefined && game?.status === 10) {


            this.winingCardsSplash = false;

            setTimeout(() => this.winingCardsSplash = true, 10);

          }


          if (game?.status === 10 || game?.status === 0 ) {
            this.defaultCards = [];
            this.modalPickUp = true;

          }

          if (game?.status === 20 && !game?.activeUserId) {
            setTimeout(() => {
              this.animationTest = true;
            }, 500);
            this.userWin = false;
          }

          if(this.isActive) {
            this.activUser(this.user?.chairNumber);
          }
          this.stopUserActionInterval();

          if (this.isActive) {
            this.startUserActionInterval();
          } else  {
            this.stopUserActionInterval();
          }
        }
      )
    );

    if(this.position) {


      this.subscription.add(
        this.store.select( store => store.elitPokerGame?.table).pipe().subscribe(
          res => {
            this.user = res[this.position];

            this.checkUserStatus();

            const myId = this.store.snapshot().elitPokerAuth?.user?.userId;
            this.userAvatar =  this.user?.avatar ? this.sanitizer.bypassSecurityTrustResourceUrl(this.user?.avatar) : 'https://authapi.playgems.club/avatars/5.jpg';
            const waitingList = this.store.snapshot().elitPokerGame?.waitingList;
            if(this.user?.status === 0 && waitingList?.length === 0) {
              this.joinGame = true;
            }

            this.userWait = this.user?.status === 1 && this.user?.isNewPlayer === false;

            if(myId === this.user?.id && this.user?.cardsChanged && this.user?.status !== 4)  {
              this.getMyCards();
            }

          }
        )
      );


      this.subscription.add(
        this.store.select( store => store.elitPokerHelper?.winner).pipe().subscribe(
          winner => {

            this.userWin = winner !== undefined && winner?.userId !== undefined && winner?.userId === this.userOnChar?.id;

            if (this.userWin) {
              this.pullMoney = true;
              // this.musicPlayer.playCoins();
              this.musicService.playPot();


            } else {
              this.pullMoney = false;
            }

            this.setPullMoneyUserAnimation(this.positionCss);
            this.stopUserActionInterval();
          }
        )
      );

      this.subscription.add(
        this.store.select( HelperState.getWinner).subscribe(
          winner => {
            if(!winner) {return;}
            if(this.store.snapshot().elitPokerGame?.game?.users){
              let users =  this.store.snapshot().elitPokerGame?.game?.users;
              const winningCards = this.store.snapshot().elitPokerHelper?.winner?.winningCombination;
              users = this.resetCards(users);
              users = this.markUserCards(winningCards, users);
              this.store.dispatch(new UpdateWinningCards(users));
              this.stopUserActionInterval();
            }
          }
        )
      );
    }

    this.getCountry();

    this.isActive = gameSnapshot?.activeUserId !== undefined && gameSnapshot.activeUserId === this.user?.id;
    // console.log('variabla is active ', this.isActive)
    // if (this.isActive) {
    //   this.startUserActionInterval();
    // } else  {
    //   this.stopUserActionInterval();
    // }
  }

  updateFlag() {
    this.countries.forEach(x => {
      if (x.name === this.userData?.country) {
        this.myCountry = x?.iso2;
      }
    });
  }

  getCountry() {
    return new Promise<void>((resolve, reject) =>  {
      this.dataService.getCountries().subscribe(
        res => {
          this.countries = res.list;
          this.updateFlag();
          resolve();
        },
        async err => {
          const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
          this.notificationService.showError(msg);
          const store = this.store.snapshot();

          const errorData = {
            AppName: 'Elites Poker',
            DeviceId: store.elitPokerAuth?.deviceId,
            UserId: store.elitPokerAuth?.details?.userId,
            PageUrl: window.location.pathname,
            ErrorDescription:  msg,
          };

          this.logOnServer(errorData);
          reject();
          console.log(err);
        }
      );
    });

  }


  markUserCards(winningCards, users) {
    for (let i = 0; i < winningCards?.length; i++) {
      for (let j = 0; j < users.length; j++) {
        for (let k = 0; k < users[j].cards.length; k++) {
          if(winningCards[i]?.value === users[j].cards[k]?.value
            && winningCards[i]?.suit === users[j].cards[k]?.suit) {
            //   // @ts-ignore
            users[j].cards[k].isWinning = true;
          }
        }
      }
    }

    return users;
  }

  checkUserStatus() {
    const friendList = this.store.snapshot().eliteFriends.friends || [];
    this.isFriend = friendList.find(x => x?.userId === this.user?.id);
  }


  addFriend(event) {
    event.stopPropagation();
    event.preventDefault();

    this.userService.sendFriendsRequest({ UserId: this.user?.id}).subscribe(
        res => {
          console.log(res);
          this.notificationService.showSuccess('Friend request is sent.');
        },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );

    console.log("add friend")
  }

  resetCards(users) {
    for (let j = 0; j < users.length; j++) {
      for (let k = 0; k < users[j]?.cards?.length; k++) {
        if(users[j].cards[k]) {
          users[j].cards[k].isWinning = false;
        }
      }
    }
    return users;
  }

  setUserPositionAnimation(position) {
    switch (position) {
      case 9:
        this.coinAnimationData = {
          default: {
            transform: 'translate(-20%, -50%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(8.8vw, 3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 8:
        this.coinAnimationData = {
          default: {
            transform: 'translate(-70%, 0)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(19.2vw, -3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 7:
        this.coinAnimationData = {
          default: {
            transform: 'translate(-70%, 70%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(20vw, -20.3vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 6:
        this.coinAnimationData = {
          default: {
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(14.3vw, -38.1vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 5:
        this.coinAnimationData = {
          default: {
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(9.1vw, -38vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 4:
        this.coinAnimationData = {
          default: {
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(-14.3vw, -38.1vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 3:
        this.coinAnimationData = {
          default: {
            transform: 'translate(50%, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(-20vw, -20.3vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 2:
        this.coinAnimationData = {
          default: {
            transform: 'translate(50%, 0)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(-19.2vw, -3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 1:
        this.coinAnimationData = {
          default: {
            transform: 'translate(50%, -200%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          out:{
            transform: 'translate(-8.8vw, 4vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      default:
        console.log('Today only, 3 + 1 free!!');
    }
  }
  setPullMoneyUserAnimation(position) {
    switch (position) {
      case 9:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(-20%, -50%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(8.8vw, 3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 8:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(-70%, 0)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(19.2vw, -3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 7:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(-70%, 70%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(20vw, -20.3vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 6:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(14.3vw, -38.1vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 5:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(9.1vw, -38vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 4:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(0, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(-14.3vw, -38.1vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 3:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(50%, 100%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(-20vw, -20.3vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 2:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(50%, 0)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(-19.2vw, -3.5vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      case 1:
        this.pullCoinAnimationData = {
          out:{
            transform: 'translate(50%, -200%)',
            opacity: 0,
            visibility: 'hidden'},
          in:{
            transform: 'translate(0, 0)',
            opacity: 1,
            visibility: 'visible'},
          default: {
            transform: 'translate(-8.8vw, 4vh)',
            opacity: 0,
            visibility: 'hidden'
          }
        };
        break;
      default:
        console.log('Today only, 3 + 1 free!!');
    }
  }

  ionViewWillEnter() {
  }

  closePickUpModal(value) {
    this.modalPickUp = value;
  }


  removeCardFromArray(array) {
    for (let i = 0; i < array.length; i++) {
      const selectedIndex = this.defaultCards.findIndex((card: CardModel) => {
        return card?.value === array[i].value && card?.suit === array[i].suit;
      });


      if (selectedIndex > -1) { this.defaultCards.splice(selectedIndex, 1);}
    }
  }

  activUser(val: number): void {
    this.userIsActiv.emit(val);
  }

  getMyCards() {
    setTimeout(() => {
      this.appendClass();
    }, 1000);
    const elitPokerGame = this.store.snapshot().elitPokerGame;

    if(elitPokerGame?.table[this.position]?.status === 4 ||
      elitPokerGame?.table[this.position]?.status === 1 || elitPokerGame?.table[this.position]?.status === 2
      || elitPokerGame?.table[this.position]?.status === 5 || elitPokerGame?.table[this.position] === null) { return; }

    if(this.store.snapshot().elitPokerGame?.game?.status === -1  || this.store.snapshot().elitPokerGame?.game?.status === 0
      || this.store.snapshot().elitPokerGame?.game?.status === 10) {return;}

    if (!this.store.snapshot().elitPokerHelper?.chairNumber) {return;}

    this.myCardsRequest = this.gameService.getMyCard(this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey).subscribe(
      res => {
        this.defaultCards = res;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage || 'GET_MY_CARDS').toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    this.stopUserActionInterval();
    if(this.myCardsRequest) {
      this.myCardsRequest.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  startUserActionInterval(): void {

    const clientServerTimeDiff = this.store.snapshot().elitPokerHelper.timeDiff

    if (this.user?.status === 4) return;
    this.stopUserActionInterval();

    const circle: SVGAElement = document.querySelector(`.progress-ring-circle-${this.position}`);
    if(!circle) {
      return;
    }


    const currentTime = new Date().getTime();
    const roundExpTime = new Date(this.game?.eventEndTime).getTime();
    const timerTime = Math.round((roundExpTime - currentTime + clientServerTimeDiff) / 1000);

    const svgProgress: SVGAElement = document.querySelector(`.progress-ring-circle-${this.position}`);
    circle.style.setProperty( '--dataProgress' ,  157 + 'px');

    const TIME_LIMIT = timerTime;
    let timePassed = 0;
    this.clockInterval = setInterval(() => {
      timePassed = timePassed += 1;
      const timeLeft = TIME_LIMIT - timePassed;

      if( this.calculateTimeFraction(TIME_LIMIT - timePassed + 1, TIME_LIMIT) * 157 <= 0 ) {
        clearInterval(this.clockInterval);
        timePassed = 0;
        return;
      }
      circle.style.setProperty( 'transition' ,   'all 3s');
      svgProgress.style.setProperty( '--dataProgressColor' , '#41fd2f' );

      if( timerTime - timePassed === 0 || timerTime - timePassed < 2 ) {
        svgProgress.style.setProperty( '--dataProgressColor' , '#f11414' );
      }
      svgProgress.style.setProperty( '--dataProgress' ,this.calculateTimeFraction(timeLeft, TIME_LIMIT) * 157 + 'px');

    }, 1000);
  };

  calculateTimeFraction(timeLeft, timeLimit) {
    return timeLeft / timeLimit;
  }

  stopUserActionInterval() {
    clearInterval(this.clockInterval);

    const circle: SVGAElement = document.querySelector(`.progress-ring-circle-${this.position}`);
    if(!circle) {
      return;
    }
    circle.style.setProperty( 'transition' ,   'unset');
    circle.style.setProperty( '--dataProgress' ,  157 + 'px');
    circle.style.setProperty(  'stroke-dasharray',  157 + 'px');
  }

  getUserData(): void {

    this.userService.getUser().subscribe(
      res => {
        this.userData = res;
        this.getCountry();
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

}
