import { GameModule } from './pages/game/game.module';
import { AuthModule } from './pages/auth/auth.module';
import {ErrorHandler, NgModule} from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { RouletteModule } from './pages/roulette/roulette.module';
import {GlobalErrorHandler} from "./core/helper/GlobalErrorHandler";

const routes: Route[] = [
  {
   path: '',
   redirectTo: 'login',
   pathMatch: 'full'
 },
 {
   path: '',
   loadChildren: () => AuthModule
 },

 {
  path: 'game',
  loadChildren: () => GameModule
},
 {
   path: 'roulette',
   loadChildren: () => RouletteModule
 },
 {
   path: '**',
   redirectTo: 'login'
 }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash:true })
  ],
  exports: [RouterModule],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ]
})
export class AppRoutingModule { }
