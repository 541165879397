import { LoginInterface, VerifyOtpInterface } from '../../core/interfaces';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public data: LoginInterface) {}
}

export class VerifyOtp {
  static readonly type = '[Auth] VerifyOtp';
  constructor(public data: VerifyOtpInterface) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}


export class RememberMe {
  static readonly type = '[Auth] RememberMe';
  constructor(public data: any) {}
}

export class SetUserKey {
  static readonly type = '[Auth] SetUserKey';
  constructor(public userKey: string) {}
}

export class SetBalance {
  static readonly type = '[Auth] SetBalance';
  constructor(public balance: number) {}
}

export class SetMainBalance {
  static readonly type = '[Auth] SetMainBalance';
  constructor(public mainBalance: number) {}
}

export class SetUserAvatar {
  static readonly type = '[Auth] SetUserAvatar';
  constructor(public userAvatar: string) {}
}
