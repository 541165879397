import { MusicService } from 'src/app/core/services/music/music.service';
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-delimiter-style */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameService} from '../../../core/services/game/game.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {CardModel} from '../../../core/models/CardModel';
import {EventService} from '../../../core/services/event/event.service';
import {Store} from '@ngxs/store';
import {ReturnCardForUser} from '../../../store/game/game.actions';
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";

@Component({
  selector: 'app-pick-up',
  templateUrl: './pick-up.component.html',
  styleUrls: ['./pick-up.component.scss'],
})
export class PickUpComponent implements OnInit {
  @Output() closePickUp = new EventEmitter();
  @Input() data: {cards: CardModel[], discardCardNumber: number};
  public gameKey: any = null;
  public musicClick: any = null;
  public selectedCard: any = [];

  constructor(private gameService: GameService,
              private route: ActivatedRoute,
              private eventService: EventService,
              private store: Store,
              private errorLogService: ErrorLogService,
              private musicService: MusicService,
              private translateService: TranslateService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    this.gameKey = this.route.snapshot.queryParams.gameKey;
  }

  confirmPickUp($event): void {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    const data = {
      cards: this.selectedCard
    };

    this.gameService.discardCards(data, this.gameKey).subscribe(
      res => {
        this.store.dispatch(new ReturnCardForUser());
        this.eventService.removeCard.next(this.selectedCard);
        this.eventService.pickCardStatus.next(true);

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );

    console.log('confirm');
    this.closePickUp.emit();
    // this.musicService.playClick();
    if (this.musicClick) { this.musicClick.play(); }

  }

  foldFromGame($event){
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.gameService.fold(this.gameKey).subscribe(
      res => console.log(res),
      async err => {
        console.log(err);
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();
        this.notificationService.showError(msg);
        this.eventService.pickCardStatus.next(true);

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
    // this.musicService.playClick();
    if (this.musicClick) { this.musicClick.play(); }

  }

  select($event,selectedCard) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    console.log(selectedCard);
    const selectedIndex = this.selectedCard.findIndex((card: CardModel) => {
      return card.value === selectedCard.value && card.suit === selectedCard.suit;
    });


    if (selectedIndex < 0) {
      selectedCard.isSelected = true;
      this.selectedCard.push(selectedCard);
    } else {
      selectedCard.isSelected = false;

      this.selectedCard.splice(selectedIndex, 1);
    }

    // this.musicService.playClick();
    if (this.musicClick) { this.musicClick.play(); }

    console.log(this.selectedCard);
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
