import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {UserService} from "../../../core/services/user/user.service";
import {Store} from "@ngxs/store";
import {SetMyInvitations} from "../../../store/friends/friends.action";
import {FriendsState} from "../../../store/friends/friends.state";
import {SetGameStatus, SetNumberOfChair} from "../../../store/game/game.actions";
import {GameService} from "../../../core/services/game/game.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {NavController} from "@ionic/angular";
import {Router} from "@angular/router";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";

@Component({
  selector: 'app-invitations-modal',
  templateUrl: './invitations-modal.component.html',
  styleUrls: ['./invitations-modal.component.scss'],
})
export class InvitationsModalComponent implements OnInit {
  @Output() closeModalEmit = new EventEmitter();
  @Output() openInvitationModalEmit = new EventEmitter();
  public invitationList$ = this.store.select(FriendsState.getMyInvitations);

  public fadeInRow = false;
  constructor(private userService: UserService,
              private gameService: GameService,
              private router: Router,
              private errorLogService: ErrorLogService,
              private navController: NavController,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private store: Store) { }

  ngOnInit() {
    setTimeout(() => {
      this.fadeInRow = true;
    }, 500);
    this.getMyInvitations();
  }

  closeModal(value = false){
    this.closeModalEmit.emit(value);
  }

  openInvitationModal(){
    this.openInvitationModalEmit.emit();
  }

  getMyInvitations(): void {
    this.userService.getMyInvitations().subscribe(
      res => {
        this.store.dispatch(new SetMyInvitations(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }
  joinGame({gameKey}) {
    console.log('join in game');
    // this.router.navigate(['game'],{queryParams:{gameKey}});
    this.closeModal(true);
    this.navController.navigateForward([`/game/loading-invitation/${gameKey}`]);

    // this.gameService.getTableInfo(gameKey).subscribe(
    //   res => {
    //     this.store.dispatch(new SetGameStatus(res));
    //     this.store.dispatch(new SetNumberOfChair(res?.maxNumberOfUsers));
    //     // this.navController.navigateForward(['/game/welcome']);
    //    this.router.navigate(['game'],{queryParams:{gameKey}});
    //   },
    //   async err => {
    //     const msg = await this.translateService.get(err.error.systemMessage).toPromise();
    //     this.notificationService.showError(msg);
    //     console.log(err);
    //   }
    // );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
