import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cards'
})
export class CardsPipe implements PipeTransform {

  transform(value: string, ...args: string[]): unknown {
    const strToReplace = args[0];
    const replacementStr = args[1];

    return value.replace(new RegExp(this.escapeStr(strToReplace), 'g'), replacementStr);
  }
  escapeStr(str) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

}
