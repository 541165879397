import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(data: any, gameKey: any): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);

    return this.http.post<any>( environment.pokerURL + `/user/message`, data, {headers});
  }

  sendTypingEvent(value: any, gameKey) {

    const data = {
      isTyping: value
    };

    const headers = new HttpHeaders().set('game-key', gameKey);

    return this.http.post<any>( environment.pokerURL + `/user/typing`, data, {headers});

  }
}
