import { NavController } from '@ionic/angular';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { UserDetailsModel } from '../../core/models';
import { Login, VerifyOtp, SetUserKey, Logout, RememberMe, SetMainBalance, SetBalance, SetUserAvatar } from './auth.actions';

export interface AuthStateModel {
  token: string;
  verificationType: number;
  deviceId: string;
  rememberMe: any;
  details: any;
  userKey: string;
  user: UserDetailsModel;
  balance: number;
  mainBalance: number;
  userAvatar: string;
}

@Injectable()

@State<AuthStateModel>({
  name: 'elitPokerAuth',
  defaults: {
    token: null,
    deviceId: null,
    rememberMe: null,
    details: null,
    verificationType: null,
    userKey: null,
    user: null,
    balance: null,
    mainBalance: null,
    userAvatar: null,
  }
})

export class AuthState {

  constructor( private ngZone: NgZone,
               private navController: NavController) {}

  @Selector()
  static getDeviceId(state: AuthStateModel): any {
    return state.deviceId || null;
  }

  @Selector()
  static getUser(state: AuthStateModel): any {
    return state.user || null;
  }

  @Selector()
  static getUserBalance(state: AuthStateModel): any {
    return state.balance || '';
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, {data}: Login): void {
    ctx.patchState({
      token: data.token,
      user: data.user,
      deviceId: data.deviceId,
      details: data.details,
    });
  }


  @Action(RememberMe)
  rememberMe(ctx: StateContext<AuthStateModel>, {data}: RememberMe): void {
    ctx.patchState({
      rememberMe: data
    });
  }

  @Action(VerifyOtp)
  verifyOtp(ctx: StateContext<AuthStateModel>, {data}: VerifyOtp): void {
    ctx.patchState({
      token: data.token,
      user: data.user,
      deviceId: data.deviceId,
      verificationType: data.verificationType,
    });
  }

  @Action(SetUserKey)
  setUserKey(ctx: StateContext<AuthStateModel>, {userKey}: SetUserKey): void {
    ctx.patchState({
      userKey
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      token: null
    });
    this.ngZone.run(() => this.navController.navigateForward(['/login']));
  }

  @Action(SetBalance)
  setBalance(ctx: StateContext<AuthStateModel>, {balance}: SetBalance): void {
    ctx.patchState({
      balance
    });
  }

  @Action(SetMainBalance)
  setMainBalance(ctx: StateContext<AuthStateModel>, {mainBalance}: SetMainBalance): void {
    ctx.patchState({
      mainBalance
    });
  }

  @Action(SetUserAvatar)
  setUserAvatar(ctx: StateContext<AuthStateModel>, {userAvatar}: SetUserAvatar): void {
    ctx.patchState({
      userAvatar
    });
  }
}
