/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AuthState } from './store/auth/auth.state';
import {GameState} from './store/game/game.state';
import {ChatState} from './store/chat/chat.state';
import { ToastrModule } from 'ngx-toastr';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, ResponseInterceptor, ServerStatusInterceptor } from './core/intercepters';
import { NgxsModule } from '@ngxs/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Camera } from '@ionic-native/camera/ngx';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {GameRoulleteState} from './store/game-roulette/game-roulette.state';
import {BetRouletteState} from './store/bet-game-roulette/bet-roulette.state';
import {HelperState} from './store/helper/helper.state';
import {ShareModule} from "./share/share.module";
import {FriendsState} from "./store/friends/friends.state";
import {ChatRouletteState} from "./store/roulette-chat/roulette-chat.state";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MultipleGameState} from "./store/multiple-game/multiple-game.state";

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 0,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forRoot([
      AuthState,
      GameState,
      ChatState,
      FriendsState,
      HelperState,
      GameRoulleteState,
      BetRouletteState,
      MultipleGameState,
      ChatRouletteState
    ], {
      developmentMode: false
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['elitPokerAuth', 'elitPokerChat', 'elitRoulettePokerChat', 'elitPokerHelper', 'elitPokerGame',
        'eliteFriends', 'rouletteGame', 'betRoulette', 'elitePokerMultipleGame']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ToastrModule.forRoot({
      tapToDismiss: true,
      autoDismiss: true,
      timeOut: 5000
    }),
    ShareModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:100'
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ServerStatusInterceptor, multi: true},
    Camera,
    ScreenOrientation
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

