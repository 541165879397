import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-avatar-slider',
  templateUrl: './choose-avatar-slider.component.html',
  styleUrls: ['./choose-avatar-slider.component.scss'],
})
export class ChooseAvatarSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  public slides = [
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
    {img: "assets/images/avatar1.png"},
  ];

  public slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "infinite": true,
    "centerMode": true,
  };

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

}
