import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})export class MusicService {

   // public backgroundMusic = null;
   public fold = null;
   public check = null;
   public call = null;
   public raise = null;
   public click = null;
   public dealingCards = null;
   public oneCard = null;
   public pot = null;
   public allIn = null;
   public myTurn = null;

  public isMuted = new BehaviorSubject(false);
  public isScreenTouched = new BehaviorSubject(false);
  public isAudioFileLoaded = new BehaviorSubject(false);

  constructor(private store: Store) { }

  unmute(): void {
    this.isMuted.next(false);
    // this.backgroundMusic.play();

  }

  mute(): void {
    this.isMuted.next(true);
    // this.backgroundMusic.pause();
  }

  setupMusicReference(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      // this.backgroundMusic = document.getElementById('beep');
      this.fold = document.getElementById('fold-tag');
      this.check = document.getElementById('check-tag');
      this.call = document.getElementById('call-tag');
      this.raise = document.getElementById('raise-tag');
      this.click = document.getElementById('click-tag');
      this.dealingCards = document.getElementById('cards-tag');
      this.oneCard = document.getElementById('card-tag');
      this.pot = document.getElementById('coins-tag');
      this.allIn = document.getElementById('all-tag');
      this.myTurn = document.getElementById('myTurn-tag');
      this.isAudioFileLoaded.next(true);
      resolve(true);
    });
  }


  prepareMusicInBackground(): void {
    this.isScreenTouched.next(true);
    this.click.play();
    this.allIn.play();
    // this.backgroundMusic.play();
    this.fold.play();
    this.check.play();
    this.call.play();
    this.raise.play();
    this.dealingCards.play();
    this.oneCard.play();
    this.pot.play();
    this.myTurn.play();

    setTimeout(() => {
      this.click.pause();
      // this.backgroundMusic.pause();
      this.fold.pause();
      this.check.pause();
      this.allIn.pause();
      this.call.pause();
      this.raise.pause();
      this.dealingCards.pause();
      this.oneCard.pause();
      this.pot.pause();
      this.myTurn.pause();
    },1);
  }

  playMyTurn(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.myTurn.play();
    }
  }

  // playBackgroundMusic(): void {
  //   if(this.isAudioFileLoaded.getValue()) {
  //     this.backgroundMusic.play();
  //   }
  // }
  // stopBackgroundMusic(): void {
  //   if(this.isAudioFileLoaded.getValue()) {
  //     this.backgroundMusic.pause();
  //   }
  // }

  playClick(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.click.play();
    }
  }

  playFold(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.fold.play();
    }
  }

  playPot(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.pot.play();
    }
  }

  playOneCard(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.oneCard.play();
    }
  }

  playDealingCards(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.dealingCards.play();
    }
  }

  playRaise(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.raise.play();
    }
  }

  playCheck(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.check.play();
    }
  }

  playAllIn(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.allIn.play();
    }
  }

  playCall(): void {
    if(this.isAudioFileLoaded.getValue()) {
      this.call.play();
    }
  }


  // inFocus(): void {
  //   if(this.isAudioFileLoaded.getValue()) {
  //     this.backgroundMusic.volume = 1;
  //   }
  // }
  //
  //
  // outFocus(): void {
  //   if(this.isAudioFileLoaded.getValue()) {
  //     this.backgroundMusic.volume = 0;
  //   }
  // }


}
