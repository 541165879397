import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TableService} from "../../../core/services/table/table.service";
import {GetTableQuery} from "../../../core/models";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {ModalController, ViewWillEnter} from "@ionic/angular";
import {SelectTable} from "../../../store/game/game.actions";
import {Store} from "@ngxs/store";
import {PasswordComponent} from "../../../share/components/password/password.component";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-loading-invitation',
  templateUrl: './loading-invitation.component.html',
  styleUrls: ['./loading-invitation.component.scss'],
})
export class LoadingInvitationComponent implements OnInit, ViewWillEnter {
  public getTableQueries = new GetTableQuery('', 1, 1000, false);
  public gameKey = null;

  constructor(private route: ActivatedRoute,
              private notificationService: NotificationService,
              private store: Store,
              private modalCtrl: ModalController,
              private router: Router,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private tableService: TableService) { }

  async ngOnInit() {
    this.gameKey = this.route.snapshot.params.id;
    // this.store.dispatch(new SetAutoActionStatus(0));
  }

  ionViewWillEnter() {
    this.gameKey = this.route.snapshot.params.id

    this.getTables();
  }

  getTables() {
    this.tableService.getAvailableTables(this.getTableQueries).subscribe(
      async res => {
        console.log(res);
        const table = res.result.find( x => x.gameKey === this.gameKey);
        console.log('tableeeee', table)
        if(table.isLocked) {
          const modal = await this.modalCtrl.create({
            component: PasswordComponent,
            componentProps: {gameKey: table.gameKey}
          });


          modal.onDidDismiss().then(data => {
            console.log(data?.data);
            if(data?.data.password && data?.data.password.length > 0) {
              this.store.dispatch(new SelectTable(table));
              this.router.navigate(['game'],{queryParams:{gameKey: table.gameKey, password: data?.data.password}});
            } else {
              this.router.navigate(['/game/home'],{queryParams:{gameKey: table.gameKey, password: data?.data.password}});
            }
          });
          return modal.present();

        } else {
          this.store.dispatch(new SelectTable(table));
          setTimeout(() => {
            this.router.navigate(['game'],{queryParams:{gameKey: table.gameKey}});
          }, 1500)
        }

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
