import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';

export const tableCardAnimation =  trigger('tableCardAnimation', [
    // Transition from any state to any state

    state('in', style({ opacity: 0 })),

    transition('* => *', [
      // Initially the all cards are not visible
      query(':enter', style({ opacity: 0 }), { optional: true }),

      // Each card will appear sequentially with the delay of 1000ms
      query(':enter', stagger('500ms', [
        animate('500ms ease-in-out', keyframes([
            style({
              offset: 0,
              opacity: 0,
              transform: 'translate3d(0, -30%, 0) scale(.5)',

            }),
            style({
              offset: .3,
              opacity: 1,
              transform: 'translate3d(0, 0, 0) scale(1)',
              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: .6,
              transform: 'translate3d(0, 0, 0) scale(1.1)',
              'box-shadow': '0 0 12px 5px #2f75ee',

            }),
            style({
              offset: .9,

              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: .98,
              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: 1,
              transform: 'translate3d(0, 0, 0) scale(1)',
              'box-shadow': '0 0 10px 10px transparent',
            })

        ]))]), { optional: true }),

      // Cards will disappear sequentially with the delay of 300ms
      query(':leave', stagger('0ms', [
        animate('0ms', keyframes([
          style({ offset: 1,display:'none' }),
        ]))]), { optional: true })
    ]),
  ])

  export const tableCardAnimation1 =  trigger('tableCardAnimation1', [
    // Transition from any state to any state

    state('in', style({ opacity: 0 })),

    transition('* => *', [
      // Initially the all cards are not visible
      query(':enter', style({ opacity: 0 }), { optional: true }),

      // Each card will appear sequentially with the delay of 1000ms
      query(':enter', stagger('500ms', [
        animate('500ms ease-in-out', keyframes([
            style({
              overflow:'hidden',
              offset: 0,
              opacity: 0,
              transform: 'translate3d(0, -30%, 0) scale(.5)',

            }),
            style({
              offset: .3,
              opacity: 1,
              transform: 'translate3d(0, 0, 0) scale(1)',
              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: .6,
              transform: 'translate3d(0, 0, 0) scale(1.05)',
              'box-shadow': '0 0 12px 5px #2f75ee',

            }),
            style({
              offset: .9,

              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: .98,
              'box-shadow': '0 0 12px 5px #2f75ee',
            }),
            style({
              offset: 1,
              transform: 'translate3d(0, 0, 0) scale(1)',
              'box-shadow': '0 0 10px 10px transparent',
            })

        ]))]), { optional: true }),

      // Cards will disappear sequentially with the delay of 300ms
      query(':leave', stagger('0ms', [
        animate('0ms', keyframes([
          style({ offset: 1,display:'none' }),
        ]))]), { optional: true })
    ]),
  ])
