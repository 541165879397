// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false,
   serverURL: 'https://auth.elite.bets4us.com',
   accountURL: 'https://account.elite.bets4us.com',
   bettingURL: 'https://virtualgameapi.uat.elitespoker.com',
   bettingRealURL: 'https://virtualgameapi.uat.elitespoker.com',
   pokerURL: 'https://poker.elite.bets4us.com',
   errorURL: 'https://errors.elite.bets4us.com',
   domain: 'https://pokergame.elite.bets4us.com',
   version: '2.0.6',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
