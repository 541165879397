import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AccountInfoInterface,
  CashWithdrawalInterface,
  UserInterface,
  UsersNumberInterface,
  UserStatisticInterface
} from '../../interfaces';
import {AvatarModel, EditUserModel, PutBetModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private http: HttpClient) { }

  getPayments(): Observable<AccountInfoInterface> {
    return this.http.get<AccountInfoInterface>( environment.accountURL + `/account`);
  }

  getTime(): Observable<AccountInfoInterface> {
    return this.http.get<AccountInfoInterface>( environment.pokerURL + `/user/time`);
  }

  getAccountInfo(): Observable<AccountInfoInterface> {
    return this.http.get<AccountInfoInterface>( environment.pokerURL + `/user/accountinfo`);
  }

  getInvitationList(): Observable<AccountInfoInterface> {
    return this.http.get<AccountInfoInterface>( environment.pokerURL + `/user/friends/requests`);
  }

  getAccountType(data: any): Observable<any> {
    return this.http.get<any>( environment.pokerURL + `/user/accounttype`, {
      params: data
    });
  }

  getBalance(): Observable<any> {
    return this.http.get<any>( environment.pokerURL + `/user/accountinfo`);
  }

  getMyFriends(): Observable<any> {
    return this.http.get<any>( environment.pokerURL + `/user/friends`);
  }

  checkTablePassword(data): Observable<any> {
    return this.http.post<any>( environment.pokerURL + `/user/checkpassword`, data);
  }

  sendFriendsRequest(data): Observable<any> {
    return this.http.post<any>( environment.pokerURL + `/user/friends`, data);
  }

  getAccountBalance(): Observable<any> {
    return this.http.get<any>( environment.accountURL + `/account`);
  }

  getUserStatistic(): Observable<UserStatisticInterface> {
    return this.http.get<UserStatisticInterface>( environment.pokerURL + `/user/statistics`);
  }

  getUser(): Observable<UserInterface> {
    return this.http.get<UserInterface>( environment.serverURL + `/user`);
  }

  getUsersNumber(): Observable<UsersNumberInterface> {
    return this.http.get<UsersNumberInterface>( environment.pokerURL + `/user/usernumbers`);
  }

  getMyInvitations(): Observable<any> {
    return this.http.get<any>( environment.pokerURL + `/user/friends/invites`);
  }

  editUser(data: EditUserModel): Observable<UserInterface> {
    return this.http.post<UserInterface>( environment.serverURL + `/user/edituser`, data);
  }

  editAvatar(data: AvatarModel): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/user/avatar`, data);
  }

  inviteFriend(gameKey: any, data: any ): Observable<any> {
    return this.http.post<any>( environment.pokerURL + `/user/friends/invite/${gameKey}`, data);
  }

  autoAction(data: any, gameKey: any): Observable<any> {
    const headers = new HttpHeaders().set('game-key', gameKey);
    return this.http.post<any>( environment.pokerURL + `/gametables/autoaction`, data, {headers});
  }

  acceptFriendRequest(userId: any): Observable<any> {
    return this.http.put<any>( environment.pokerURL + `/user/friends/${userId}/accept`, {});
  }

  rejectFriendRequest(userId: any): Observable<any> {
    return this.http.put<any>( environment.pokerURL + `/user/friends/${userId}/reject`, {});
  }

  blockFriendRequest(userId: any): Observable<any> {
    return this.http.put<any>( environment.pokerURL + `/user/friends/${userId}/block`, {});
  }

  deleteFriend(userId: any): Observable<any> {
    return this.http.delete<any>( environment.pokerURL + `/user/friends/${userId}`);
  }


  /*For elit roulette*/
  getGameInfo(): Observable<any> {
    return this.http.get<any>( environment.bettingURL + `/user/game`);
  }

  putBet(data: PutBetModel): Observable<CashWithdrawalInterface> {
    return this.http.post<CashWithdrawalInterface>( environment.bettingURL + `/user/bet`, data);
  }
  putBetReal(data: PutBetModel): Observable<CashWithdrawalInterface> {
    return this.http.post<CashWithdrawalInterface>( environment.bettingRealURL + `/user/bet`, data);
  }
}

