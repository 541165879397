import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut } from 'src/app/animations/fadeInOut.animations';
import {UserService} from '../../../core/services/user/user.service';
import {Store} from '@ngxs/store';
import {SetFriendList, SetFriendRequestList} from '../../../store/friends/friends.action';
import {FriendsState} from '../../../store/friends/friends.state';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";

@Component({
  selector: 'app-friend-modal',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class FriendsComponent implements OnInit {
  @Output() closeModalEmit = new EventEmitter();
  @Output() openInvitationModalEmit = new EventEmitter();
  @Input() gameKey = null;
  public friendList$ = this.store.select(FriendsState.getFriendList);
  public friendRequestList$ = this.store.select(FriendsState.getFriendRequests);

  public inviteTabValueModal = 1;
  public tabActive = null;
  public fadeInRow = false;
  public removeFriend = false;
  public clearCheckbox = true;
  public selectedFriend = null;
  public date = new Date();
  public userFriendIds = [];

  constructor(private userService: UserService,
              private store: Store,
              private notificationService: NotificationService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService) { }

  ngOnInit() {
    setTimeout(() => {
      this.fadeInRow = true;
    }, 500);
    this.tabActive = this.inviteTabValueModal;
    this.loadMyFriendsList();
    this.loadMyFriendsRequest();
  }

  openModalPopup(value) {
    this.selectedFriend = value;
    this.removeFriend = true;
  }

  tabSwitcher(numb: number) {
    console.log(numb);
    this.tabActive = numb;
  }

  closeModal(){
    this.closeModalEmit.emit();
  }

  openInvitationModal(){
    this.openInvitationModalEmit.emit();
  }


  loadMyFriendsList(): void {
    this.userService.getMyFriends().subscribe(
      res => {
        console.log('getMyFriends', res);
        this.store.dispatch(new SetFriendList(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  selectFriend({userId}): void {
    // userFriendIds
    const index = this.userFriendIds.findIndex((element) => {
      return element === userId;
    });
    index < 0 ? this.userFriendIds.push(userId) : this.userFriendIds.splice(index, 1);
  }

  loadMyFriendsRequest(): void {
    this.userService.getInvitationList().subscribe(
      res => {
        console.log('getInvitationList', res);
        this.store.dispatch(new SetFriendRequestList(res));
      },
      err => console.log(err)
    );
  }

  accept({userId}): void {
    this.userService.acceptFriendRequest(userId).subscribe(
      res => {
        console.log(res)
        this.loadMyFriendsList();
        this.loadMyFriendsRequest();
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  decline({userId}): void {
    this.userService.rejectFriendRequest(userId).subscribe(
      res => {
        console.log(res);
        this.loadMyFriendsRequest();
        },
      async err => {
        const msg = await this.translateService.get(err.error.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  deleteFriend({userId}): void {
    this.userService.deleteFriend(userId).subscribe(
      res => {
        console.log(res);
        this.removeFriend = false;
        this.loadMyFriendsList();
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  invite(): void {
    console.log(this.userFriendIds)
    const data = {
      userFriendIds: this.userFriendIds
    }
    this.userService.inviteFriend(this.gameKey, data).subscribe(
      res => {
        console.log(res)
        this.clearCheckbox = false;
        this.notificationService.showSuccess('Invitations are send to the player');
        setTimeout(() => {
          this.clearCheckbox = true;
        },10)
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    )
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

}
