// @ts-ignore
import {ChatMessageModel} from '../../core/models/ChatMessageModel';

export class AddRouletteNewMessage {
  static readonly type = '[chat] AddRouletteNewMessage';
  constructor(public message: ChatMessageModel) {}
}

export class SetRouletteNumberOfOnlineUser {
  static readonly type = '[chat] SetNumberOfOnlineUser';
  constructor(public numberOfOnlineUser: number) {}
}

export class UnreadRouletteMessagesIncrease {
  static readonly type = '[chat] UnreadMessagesIncrease';
  constructor() {}
}

export class ResetRouletteUnreadMessages {
  static readonly type = '[chat] ResetUnreadMessages';
  constructor() {}
}

export class SetRouletteChatStatus {
  static readonly type = '[chat] SetChatStatus';
  constructor(public isChatOpen: boolean) {}
}

