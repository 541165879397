import { MusicService } from './../../../core/services/music/music.service';
import { Router } from '@angular/router';
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraOptions, Camera } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { UserInterface, UserStatisticInterface } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Logout, SetBalance } from 'src/app/store/auth/auth.actions';
import { ToastrService } from 'ngx-toastr';
import {EventService} from "../../../core/services/event/event.service";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() closeModalEmit = new EventEmitter();
  @Input() showProfileModal: boolean;
  public codeToggle = false;
  public selectedCountry: any = 'Select Country';
  public countries = null;
  public tabActive = 1;
  public userStatistic: UserStatisticInterface = null;
  public balance = 0;
  public mainBalance = 0;
  public depositForm: FormGroup;
  public depositOtpForm: FormGroup;
  public withdrawalForm: FormGroup;
  public withdrawalOtpForm: FormGroup;
  public userData: UserInterface = null;
  public editUser: FormGroup;
  public userAvatar: any;
  public triggerBuyCoinsModal = false;
  public accountInfo = null;
  public musicClick = null;

  public gelleryOptions: CameraOptions = {
    quality: 100,
    sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
    destinationType: this.camera.DestinationType.DATA_URL,
    allowEdit: true,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
  };
  private checkBalanceInterval = null;

  constructor(private dataService: DataService,
              private userService: UserService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private store: Store,
              private router: Router,
              private camera: Camera,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private eventService: EventService,
              private musicService: MusicService,
              private sanitizer: DomSanitizer,
              private platform: Platform) { }


  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    this.getCountry();
    this.getPayments();
    this.createForms();
    this.getBalance();
    this.getMainAccountBalance();
    this.getUserStatistic();
    this.getUserData();


    /*this.checkBalanceInterval = setInterval(() => {
      this.getBalance();
      this.getMainAccountBalance();
    }, 2);*/
  }

  getPayments(): void {
    this.userService.getPayments().subscribe(
      res => {
        this.accountInfo = res;

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  async getUserData() {
    await this.userService.getUser().subscribe(
       res => {
        console.log("response ", res);
        this.userData = res;
        this.editUser.patchValue({
          userName: this.userData.userName.charAt(0).toUpperCase() + this.userData.userName.substr(1),
          country: this.userData.country,
          preferredLanguage: this.userData.preferredLanguage,
        });
        this.selectedCountry = this.countries.find(x => x.name === this.userData.country)

           this.userAvatar = this.sanitizer.bypassSecurityTrustResourceUrl(this.userData.avatar);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  updateUserData() {
    console.log('www')
  }

  onEditUser(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.editUser.markAllAsTouched();
    // if (this.editUser.invalid) { return; }


    /*
    const pn = String(this.editUser.value.phoneNumber);

    if ( pn.charAt(0) === '0') {
      this.editUser.controls.phoneNumber.setValue(`${pn.substring(1)}`);
    }
    const phoneNumber = '+' + this.selectedCountry.dialCode + this.editUser.value.phoneNumber;
*/
    const data = {
      userName: this.editUser.value.userName,
      country: this.selectedCountry.name,
      preferredLanguage: this.editUser.value.preferredLanguage,
    };

    this.userService.editUser(data).subscribe(
      res => {
        console.log("response ", res);
        this.eventService.updateUserProfile.next();
        this.getUserData();
        },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.balance = res.balance;
        this.store.dispatch(new SetBalance(res.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getMainAccountBalance(): void {
    this.userService.getAccountBalance().subscribe(
      res => {
        this.mainBalance = res.balance;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getUserStatistic(): void {
    this.userService.getUserStatistic().subscribe(
      res => {
        this.userStatistic = res;
        console.log(this.userStatistic);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  openGallery() {
    // this.musicService.playClick();

    if (this.musicClick) {
      this.musicClick.play();
    }

    if(this.platform.is('desktop') || document.URL.indexOf('http') === 0) {
      this.fileInput.nativeElement.click();
      console.log('desktop');
    } else {
      this.camera.getPicture(this.gelleryOptions).then(
        imgData => {
          console.log('image data =>  ', imgData);
          const base64Img = 'data:image/jpeg;base64,' + imgData;
          this.uploadAvatar(base64Img);
          this.eventService.updateUserProfile.next()
        },
        async err => {
          const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
          this.notificationService.showError(msg);
          const store = this.store.snapshot();

          const errorData = {
            AppName: 'Elites Poker',
            DeviceId: store.elitPokerAuth?.deviceId,
            UserId: store.elitPokerAuth?.details?.userId,
            PageUrl: window.location.pathname,
            ErrorDescription:  msg,
          };

          this.logOnServer(errorData);
          console.log(err);
        });
    }
  }

  onFileChanged(event) {
    const selectedFile = <File>event.target.files[0];
    const base64Img = selectedFile;
    const reader = new FileReader();
    reader.onload = () => {
      const avatar = reader.result as string;
      this.uploadAvatar(avatar);
      this.eventService.updateUserProfile.next()
    };
    reader.readAsDataURL(base64Img);
  }

  uploadAvatar(data) {
    this.userService.editAvatar({avatar: data}).subscribe(
      res => {
        this.toastrService.success('Avatar success change!');
        this.eventService.updateUserProfile.next();
        this.getUserData();
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  deposit(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }

    this.depositForm.markAllAsTouched();
    if ( this.depositForm.invalid ) { return; }

    const data = {
      transactionType: 10,
      amount: Number(this.depositForm.value.amount)
    };

    console.log(data);

    this.authService.getAccountOTP(data).subscribe(
      res => {
        console.log(res);
        this.notificationService.showSuccess('OTP send to your account');
        this.depositOtp();
      },
        async err => {
          const msg = await this.translateService.get('OTP not send to your account').toPromise();
          this.notificationService.showError(msg);
          const store = this.store.snapshot();

          const errorData = {
            AppName: 'Elites Poker',
            DeviceId: store.elitPokerAuth?.deviceId,
            UserId: store.elitPokerAuth?.details?.userId,
            PageUrl: window.location.pathname,
            ErrorDescription:  msg,
          };

          this.logOnServer(errorData);
          console.log(err);
        }
    );
    console.log(this.depositForm.value);
  }

  depositOtp(): void {
    // console.log('confirmDepositTransaction', this.addMoneyConfirmation.value)
    this.depositOtpForm.markAllAsTouched();
    if ( this.depositOtpForm.invalid ) { return; }

    const data = {
      amount:  Number(this.depositOtpForm.value.amount),
      accountTypeFrom: 0,
      accountTypeTo: 300,
      otpNumber: Number(this.depositOtpForm.value.otp),
      description: ''
    };

    this.authService.transferAccountType(data).subscribe(
      res => {
        console.log(res);
        this.depositOtpForm.reset();
        this.depositOtpForm.reset();
        this.notificationService.showSuccess('Transfer done.');
        this.getBalance();
      },

      async err => {
        let msg;
        if (err?.error?.systemMessage === 'EXCEPTION_NOT_ENOUGHT_BALANCE') {
          msg = await this.translateService.get('Don\'t have enough money.').toPromise();
          return;
        } else {
          msg = await this.translateService.get('Transfer fail.').toPromise();
        }
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  withdrawal(): void {
    // this.musicService.playClick();

    if (this.musicClick) {
      this.musicClick.play();
    }
    this.withdrawalForm.markAllAsTouched();
    if (this.withdrawalForm.invalid) { return; }

    const data = {
      transactionType: 10,
      amount: Number(this.withdrawalForm.value.withdrawal)
    };

    this.authService.getAccountOTP(data).subscribe(
      res => {
        console.log(res);
        this.notificationService.showSuccess('OTP send to your account');
        this.withdrawalOtp();
      },
      async err => {
        const msg = await this.translateService.get('OTP not send to your account').toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
    console.log(this.withdrawalForm.value);
  }

  withdrawalOtp(): void {
    console.log('confirmDepositTransaction', this.withdrawalOtpForm.value);
    this.withdrawalOtpForm.markAllAsTouched();
    if ( this.withdrawalOtpForm.invalid ) { return; }

    const data = {
      amount: Number(this.withdrawalOtpForm.value.withdrawal),
      accountTypeFrom: 300,
      accountTypeTo: 0,
      otpNumber: Number(this.withdrawalOtpForm.value.otp),
      description: ''
    };

    console.log('data', data);

    this.authService.transferAccountType(data).subscribe(
      res => {
        console.log(res);
        this.withdrawalOtpForm.reset();
        this.withdrawalOtpForm.reset();
        this.notificationService.showSuccess('Transfer done.');
        this.getBalance();
        this.getMainAccountBalance();

      },
      async err => {
        let msg;
        if (err?.error?.systemMessage === 'EXCEPTION_NOT_ENOUGHT_BALANCE') {
          msg = await this.translateService.get('Don\'t have enough money.').toPromise();
          return;
        } else {
          msg = await this.translateService.get('Transfer fail.').toPromise();
        }
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  createForms(): void {
    this.editUser = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      preferredLanguage: new FormControl('', [Validators.required]),
    }, {
      updateOn: 'change'
    });

    this.depositForm = new FormGroup({
      amount: new FormControl('', []),
    }, {
      updateOn: 'submit'
    });

    this.depositOtpForm = new FormGroup({
      otp: new FormControl('123456', [Validators.compose([Validators.required])]),
    }, {
      updateOn: 'submit'
    });

    this.withdrawalForm = new FormGroup({
      amount: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(1)])]),
    }, {
      updateOn: 'submit'
    });

    this.withdrawalOtpForm = new FormGroup({
      otp: new FormControl('123456', [Validators.compose([Validators.required])]),
    }, {
      updateOn: 'submit'
    });
  }

  selectCountry(value): void {
    this.selectedCountry = value;
    this.codeToggle = false;
    console.log(value);
  }

  logout(): void {
    // this.musicService.playClick();

    if (this.musicClick) {
      this.musicClick.play();
    }

    this.authService.logout().subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    )
    this.store.dispatch(new Logout());
    //this.navController.navigateForward(['/login']);
    this.router.navigate(['/auth/login']);
  }

  getCountry() {
    return new Promise<void>((resolve, reject) =>  {
      this.dataService.getCountries().subscribe(
        res => {
          this.countries = res.list;
          this.selectedCountry = res.list[0];
          resolve();
        },

      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription: msg,
        };

        reject();
        this.logOnServer(errorData);
        console.log(err);
      });
    });
  }

  tabSwitcher(numb: number) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    console.log(numb);
    this.tabActive = numb;
  }

  playClickFunc(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  closeModal(){
    this.musicService.playClick();

    this.closeModalEmit.emit(5);
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription: msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }
}
