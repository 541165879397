import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';



import { RoulettePage } from './roulette.page';
import { Route, RouterModule } from '@angular/router';
import { RouletteWheelComponent } from './roulette-wheel/roulette-wheel.component';
import {ShareModule} from '../../share/share.module';
import {NumberComponent} from './number/number.component';
import { JustNumberComponent } from './just-number/just-number.component';


const routes: Route[] = [
  {
    path: '',
    component: RoulettePage
  }
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ShareModule
  ],
  declarations: [RoulettePage,RouletteWheelComponent, NumberComponent, JustNumberComponent]
})
export class RouletteModule {}
