/* eslint-disable eqeqeq */
import {DOCUMENT} from '@angular/common';

declare let $: any;
import {
  ChangeDetectorRef,
  Component,
  ElementRef,

  HostListener,
  Inject,
  OnDestroy,
  OnInit,

  ViewChild
} from '@angular/core';
import {SvgService} from '../../core/services/svg/svg.service';
import {UserService} from '../../core/services/user/user.service';
import {NavController, Platform, ViewWillEnter} from '@ionic/angular';
import {SocketRouletteGameService} from '../../core/services/socket-roullete-game/socket-roulette-game.service';
import {MusicService} from '../../core/services/music/music.service';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {EventRouletteService} from '../../core/services/event-roulette/event-roulette.service';
import {AvailableGameModel, GameRoundModel, PutBetModel, TableFields} from '../../core/models';
import {Subscription, timer} from 'rxjs';
import {GameRoulleteState} from '../../store/game-roulette/game-roulette.state';
import {ResetBets, SetBalance, SetCoinValue, SetTotalBet} from '../../store/bet-game-roulette/bet-roulette.actions';
import {createElementFromHTML} from '../../core/helper/helper-function';
import {Logout} from '../../store/auth/auth.actions';
import { fadeInOut } from 'src/app/animations/fadeInOut.animations';
import {ChatRouletteState} from '../../store/roulette-chat/roulette-chat.state';
import {CurrentWin, LastWin, SetIsGameStartStatus} from '../../store/game-roulette/game-roulette.actions';
import { AddNewMessage, ResetUnreadMessages} from '../../store/chat/chat.actions';
import {debounceTime} from 'rxjs/operators';
import {AddCoinDirective} from '../../share/directives/addCoin/add-coin.directive';
import {ChatRouletteService} from '../../core/services/roulette-chat/roulette-chat.service';
import {GameService} from '../../core/services/game/game.service';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {HelperState} from '../../store/helper/helper.state';
import {SetRouletteChatStatus} from '../../store/roulette-chat/roulette-chat.actions';
import {SetConnecting} from '../../store/helper/helper.action';
import {AuthService} from '../../core/services/auth/auth.service';
import {ErrorLogService} from '../../core/services/error-log/error-log.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../core/services/notification/notification.service';

@Component({
  selector: 'app-roulette',
  templateUrl: './roulette.page.html',
  styleUrls: ['./roulette.page.scss'],
  animations: [
    fadeInOut,
  ]
})
export class RoulettePage implements OnInit, OnDestroy, ViewWillEnter {
  public cTableRounded = null;
  public tableRounded = null;
  public currentGame: AvailableGameModel = null;
  public coinBoolean = false;
  public infoModal = false;
  public landscape = true;
  public roundStatus = '';
  public previousOrientation: any = false;
  public music;
  public gameStartInSec: any = 15;
  public gameStartCountDown: any = null;
  @ViewChild('scrollChat') private scrollChat: ElementRef;
  public muteSound$ = this.store.select(HelperState.getMusicStatus);
  public musicTag = null;
  public musicClick = null;

  public winningCombination = false;

  public isOpenMenu = false;
  public slotModal = false;
  public device = 'mobile';
  public isOpenSettings = false;
  public isOpenChat = false;
  public isOpenMobileChat = false;
  public changeTableColor = true;
  public isDropDownOpen = false;
  public showBets = true;
  public isGameStart = false;
  public bets = [];
  public lastBets = [];
  public betsConfirmed = false;
  public isViewMainTable = true;
  public messagesData: string = null;
  public mainBets = [];
  public roundedBets = [];
  public isMyTurn = false;

  public coin05;
  public coin01;
  public coin1;
  public coin5;
  public coin2;
  public coin10;
  public coin20;
  public coin50;
  public coin100;
  public coin200;
  public selectedCoin;
  public selectedCoinValue = 1;
  public winningNumbers;
  public balanceAfterBet = 0;
  public totalBet = 0;
  public balance = 0;
  public tableField = new TableFields();
  public tableWinningField = new TableFields();
  public subscriptions = new Subscription();
  public numberOfOnlineUser = this.store.select(ChatRouletteState.getNumberOfOnlineUser);
  public lastWin$ = this.store.select(GameRoulleteState.getLastWin);
  public currentWin$ = this.store.select(GameRoulleteState.getCurrentWin);
  public unreadMessagesCount$ = this.store.select(ChatRouletteState.getUnreadMessages);
  public maxBet = 0;
  public biggestWinners$ = this.store.select(GameRoulleteState.getBiggestWinners);
  public gameSync = false;
  public savedBets = [];
  public termsOfUse: number = null;
  public addCoinDirective;
  public clockInterval;
  public numbersCombinations = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24,16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28,12, 35, 3, 26, 0];
  public indexOfWinNumber: number = null;
  public statisticTrigger = false;
  public popupPlaceBets = false;
  public closeChatModal = false;
  public chatType = 'chat';
  public msgInput = '';
  public messages$ = this.store.select(ChatRouletteState.getMessages);
  public viewConnecting = null;
  public userName = null;
  public inputFocus = false;
  public gameKey = null;
  public statisticsList: any = null;
  public playMusic: boolean;
  public muteSound: boolean;
  public doubleBtn: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(window.innerWidth < 768) {
      this.device = 'mobile';
    } else {
      this.device = 'desktop';
    }
  }


  @ViewChild('carousel') el: ElementRef;
  public headerDropdown = false;
  public tableType = true;
  public tableColor = true;
  public doubleBetDisabled = false;
  public undoBetDisabled = false;
  public clearBetDisabled = false;
  public confirmBetDisabled = false;
  public racetrackMessage = '';

  constructor(@Inject(DOCUMENT) document,
              private svgService: SvgService,
              private userService: UserService,
              private cdr: ChangeDetectorRef,
              private navController: NavController,
              private eventService: EventRouletteService,
              private actions$: Actions,
              private platform: Platform,
              private musicService: MusicService,
              private authService: AuthService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private so: ScreenOrientation,
              private notificationService: NotificationService,
              private rouletteChatService: ChatRouletteService,
              private socketRouletteGameService: SocketRouletteGameService,
              private socketService: SocketRouletteGameService,
              private gameService: GameService,
              private store: Store) {
    this.addCoinDirective = new AddCoinDirective(this.store,
      this.socketService,
      this.eventService);
  }



  async ngOnInit(): Promise<any> {
    this.musicTag = document.getElementById('beep');
    this.musicClick = document.getElementById('click-tag');
    this.muteSound = this.musicService.isMuted.getValue();
    this.store.dispatch(new SetIsGameStartStatus(false));

    this.getStatistics();
    await this.socketRouletteGameService.connect();

    this.subscriptions.add(
      timer(1000, 500).subscribe(() => {
        this.checkOrientation();
      })
    );

    if(window.innerWidth <= 768) {
      this.device = 'mobile';
    } else {
      this.device = 'desktop';
    }

    this.store.select(HelperState.getConnecting).subscribe((elem) => {
      this.viewConnecting = elem;
      console.log('--------',this.viewConnecting);
    });


    this.getGameInfo();
    this.getBalance();
    if (this.store.snapshot().rouletteGame.gameRound) {
      this.maxBet = this.store.snapshot().rouletteGame.gameRound.rules.maxBet;
    } else {
      this.maxBet = 500;
    }

    this.subscriptions.add(this.socketRouletteGameService.syncWithGame.subscribe(res => this.gameSync = res));


    this.subscriptions.add(this.socketService.gameStatus.subscribe((res: GameRoundModel) => {
      console.log('game status', res);
      this.roundStatus = res && res.roundStatus;
      this.store.dispatch(new SetConnecting(false));

      if(res && res.roundStatus) {
        switch(res.roundStatus) {
          case 'IDLE':
            // code block
            console.log('IDLE status', res);
            if (this.gameSync) {
              this.winningCombination = true;
            }
            this.isMyTurn = false;
            this.getBalance();
            this.popupPlaceBets = false;
            this.setWinningNumber(res.winningCombinations);
            this.indexOfWinNumber = this.numbersCombinations.indexOf(Number(res?.lastWinningNumber));
            console.log('Win Number ', res?.lastWinningNumber);
            console.log('Index Win Number ', this.indexOfWinNumber);
            this.doubleBtn = false;

            break;
          case 'SPIN':
            // code block
            this.setWinningNumber(res.winningCombinations);
            console.log('SPIN status');
            if(!this.betsConfirmed) {
              this.clear();
            }
            this.winningCombination = false;
            this.tableWinningField = new TableFields();
            this.isMyTurn = false;
            this.doubleBtn = false;
            if (this.gameSync) {
              this.messagesData = null;
            }
            this.popupPlaceBets = false;

            break;
          case 'BETTING':
            // code block
            this.popupPlaceBets = true;
            setTimeout(() => {this.popupPlaceBets = false;}, 2000);
            this.clear();
            this.winningCombination = false;
            this.betsConfirmed = false;
            this.isMyTurn = true;
            this.doubleBtn = true;
            console.log('BETTING status');
            if (this.gameSync) {
              setTimeout(() => {
                this.startGameClock(String(res?.rules?.gameBettingTimeoutInSeconds));
              },1);
              this.eventService.blockBet.next(false);
              this.messagesData = 'Place your bets';
            }
            this.store.dispatch(new CurrentWin(0));

            break;
          default:
          // code block
        }
      }

    }));

    this.winningNumbers = this.store.select(GameRoulleteState.getWinningNumbers);
    this.coin1 = await this.loadCoinSVG('assets/coins/coin-1.svg');
    this.coin2 = await this.loadCoinSVG('assets/coins/coin-2.svg');
    this.coin5 = await this.loadCoinSVG('assets/coins/coin-5.svg');
    this.coin10 = await this.loadCoinSVG('assets/coins/coin-10.svg');
    this.coin20 = await this.loadCoinSVG('assets/coins/coin-20.svg');
    this.coin50 = await this.loadCoinSVG('assets/coins/coin-50.svg');
    this.coin100 = await this.loadCoinSVG('assets/coins/coin-100.svg');
    this.coin200 = await this.loadCoinSVG('assets/coins/coin-200.svg');
    this.selectedCoin = this.coin1;
    this.store.dispatch(new SetCoinValue(this.selectedCoinValue));

    this.currentGame = this.store.snapshot().rouletteGame.currentGame;
    await this.socketService.connect();



    this.subscriptions.add(
      this.actions$
        .pipe(ofActionDispatched(AddNewMessage), debounceTime(1))
        .subscribe(data => {
          console.log('nova poruka');
          this.scrollToBottom();
    }));
    this.userName = this.store.snapshot().elitPokerAuth?.user?.userName;

    $('.owl-carousel').children().each( function( index ) {
      $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
    });

    $('.owl-carousel').owlCarousel({
      loop: true,
      nav: false,
      center:true,
      dots: false,
      mouseDrag: false,
      touchDrag: false,
      responsive:{
        320:{
          items:3
        },
        550:{
          items:5
        },
        1440:{
          items:5,
        }
      }
    });
    let center = 0;

    $('.owl-next').click((e) =>  {
      $('.owl-carousel').trigger('next.owl.carousel');

    });
    $('.owl-prev').click((e) =>  {
      $('.owl-carousel').trigger('prev.owl.carousel');

    });

    $(document).on('click', '.owl-item>div',function()  {
      const $speed = 300;
      $('.owl-carousel').trigger('to.owl.carousel', [$(this).data( 'position' ), $speed] );
    });

    $('.owl-carousel').on('to.owl.carousel', (e) =>  {
      center = $('.owl-carousel').find('.center').children('.item').data('position');
      // console.log("prev" + center)
      this.selectCoinSwitch(center);
    });

    $('.owl-carousel').on('prev.owl.carousel', (e) =>  {
      center = $('.owl-carousel').find('.center').children('.item').data('position');
      // console.log("prev" + center)
      this.selectCoinSwitch(center);
    });

    $('.owl-carousel').on('next.owl.carousel', (e) => {
      center = $('.owl-carousel').find('.center').children('.item').data('position');
      // console.log("next" + center)
      this.selectCoinSwitch(center);

    });

    this.playMusic = this.store.snapshot().elitPokerHelper?.playMusic;

  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  openCloseStatic() {
    this.statisticTrigger = !this.statisticTrigger;
    if(this.statisticTrigger) {
      this.getStatistics();
    }
  }

  muteSoundFunction(){
    this.musicService.isMuted.getValue() ? this.musicService.unmute() : this.musicService.mute();
    this.muteSound = this.musicService.isMuted.getValue();
  }

  selectCoinSwitch(value) {
    // console.log('selectCoinSwitch', value)
    console.log('selectCoinSwitch', value);
    switch (value) {
      // case 0:
      //   this.selectCoin(this.coin01, 0.1)
      //   break;
      // case 1:
      //   this.selectCoin(this.coin05, 0.5)
      //   break;
      case 0:
        this.selectCoin(this.coin1, 1);
        break;
      case 1:
        this.selectCoin(this.coin2, 2);
        break;
      case 2:
        this.selectCoin(this.coin5, 5);
        break;
      case 3:
        this.selectCoin(this.coin10, 10);
        break;
      case 4:
        this.selectCoin(this.coin20, 20);
        break;
      case 5:
        this.selectCoin(this.coin50, 50);
        break;
      case 6:
        this.selectCoin(this.coin100, 100);
        break;
      case 7:
        this.selectCoin(this.coin200, 200);
        break;
      default: console.log('something wrong');

    }
  }

  ionViewWillEnter() {
    this.getGameInfo();
    this.getBalance();
    this.muteSound = this.musicService.isMuted.getValue();
    this.platform.ready().then(() => {

      if((window as any).MobileAccessibility){
        (window as any).MobileAccessibility.usePreferredTextZoom(false);
      }
      if (this.platform.is('cordova')){
        this.lockToLandscape();
      }
    });
  }

  headerDropdownTrigger() {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    this.headerDropdown = !this.headerDropdown;
  }

  setWinningNumber(combination): void {
    for ( const key of combination) {
      this.tableWinningField[key] = true;
    }
  }

  checkOrientation() {

    // @ts-ignore
    this.cdr.detectChanges();
    if(window.innerHeight < window.innerWidth) {
      this.landscape = true;
      return;
    } else {
      this.landscape = false;
      return;
    }


    // if (window.orientation !== this.previousOrientation  ) {
    //   this.previousOrientation = window.orientation;
    //   // @ts-ignore
    //   this.landscape = this.previousOrientation === 90 || this.previousOrientation === -90;
    //   console.log('is landscape', this.landscape);
    //   // orientation changed, do your magic here
    // }
  };

  getBalance(): void {
    this.userService.getAccountBalance().subscribe(
      res => {
        this.balanceAfterBet = res.balance;
        this.balance = res.balance;
        this.store.dispatch(new SetBalance(this.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getGameInfo(): void {
    this.userService.getGameInfo().subscribe(
      res => {
        console.log(res);

        if(!res) {
          this.messagesData = 'Game temporary not working';
        }

        if(!res) {return;}
        const clientServerTimeDiff = this.store.snapshot().elitPokerHelper.timeDiff;

        const currentTime = new Date().getTime() + clientServerTimeDiff;
        const roundExpTime = new Date(res.nextStartTime).getTime();
        this.gameStartInSec = Math.round((roundExpTime - currentTime) / 1000);

        if(!this.gameStartCountDown) {
          this.gameStartCountDown = setInterval(() => {
            this.gameStartInSec -=1;
            if (this.gameStartInSec < 1) {
              console.log('game start in:', this.gameStartInSec);
              clearInterval(this.gameStartCountDown);
              this.gameStartCountDown = null;
              this.socketRouletteGameService.syncWithGame.next(true);
            }
          },1000);
        }
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  clear(): void {
    this.showBets = false;
    this.bets = [];
    this.savedBets = [];
    this.mainBets = [];
    this.roundedBets = [];
    setTimeout(() => this.showBets = true,1);
    this.totalBet = 0;
    this.balanceAfterBet = this.balance;
    this.store.dispatch(new ResetBets(this.balance));
    this.tableField = new TableFields();
    this.doubleBtn = true;
  }

  putBet(fieldId): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }


    const betsGroup = this.bets.filter((x) => x.betCombination ===  fieldId);

    const totalGroupAmount = this.calculateFieldAmount(betsGroup);

    console.log('totalField', totalGroupAmount + this.selectedCoinValue);

    if (fieldId === 'VOISINSDUZERO') {
      if ( totalGroupAmount + this.selectedCoinValue % 9 === 0) {
        this.messagesData = 'Total amount on Voisins Du Zero must be dividable by 9.';
      }
    }

    else if (fieldId === 'ORPHELINS' ) {
      if ( totalGroupAmount + this.selectedCoinValue % 5 === 0) {
        this.messagesData = 'Total amount on Orphelins must be dividable by 6.';
      }
    }

    else if (fieldId === 'TIER' ) {
      if ( totalGroupAmount + this.selectedCoinValue % 6 === 0) {
        this.messagesData = 'Total amount on Tier must be dividable by 6.';
      }
    }

    const fieldLimit = this.checkFieldType(fieldId);

    if (fieldLimit) {



      if( (totalGroupAmount + this.selectedCoinValue) > 80000) {
        // console.log('max bet per field. ')
        const field = document.getElementById(fieldId);
        field.removeChild(field.lastChild);
        return;
      }
    }


    if (this.betsConfirmed) {return;}

    if(!this.isGameStart) {return;}


    const roundStatus = this.store.snapshot().rouletteGame.gameStatus;

    if(roundStatus !== 'BETTING') {
      return;
    }

    const currentGame = this.store.snapshot().rouletteGame.gameRound;

    if(!currentGame) {
      return;
    }

    const maxBet = this.store.snapshot().rouletteGame.gameRound.rules.maxBet;
    if(this.totalBet + this.selectedCoinValue > this.balance || this.totalBet + this.selectedCoinValue > maxBet) {
      return;
    }
    this.totalBet += this.selectedCoinValue;
    this.balanceAfterBet = this.balance - this.totalBet;
    this.store.dispatch(new SetTotalBet(this.totalBet));
    this.tableField[fieldId] = true;
    this.bets.push({betCombination: fieldId, amount: this.selectedCoinValue, selectedCoin: this.selectedCoin});
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isViewMainTable ?
      this.mainBets.push({betCombination: fieldId, amount: this.selectedCoinValue, selectedCoin: this.selectedCoin}) :
      this.roundedBets.push({betCombination: fieldId, amount: this.selectedCoinValue, selectedCoin: this.selectedCoin});
    if (this.bets.length) {
      this.messagesData = 'Confirm bet.';
    }
    console.log(this.bets);
  }

  repeatBet(): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    console.log('repeat');

    if (!this.doubleBtn) { return; }
    console.log('repeat bet', this.lastBets);
    this.lastBets.forEach(bet => {

      if (this.betsConfirmed) {return;}

      if(!this.isGameStart) {return;}


      const roundStatus = this.store.snapshot().rouletteGame.gameStatus;

      if(roundStatus !== 'BETTING') {
        return;
      }

      const currentGame = this.store.snapshot().rouletteGame.gameRound;

      if(!currentGame) {
        return;
      }

      const maxBet = this.store.snapshot().rouletteGame.gameRound.rules.maxBet;

      if(this.totalBet + this.selectedCoinValue > this.balance || this.totalBet + this.selectedCoinValue > maxBet) {
        return;
      }
      this.totalBet += bet.amount;
      this.balanceAfterBet = this.balance - this.totalBet;
      this.store.dispatch(new SetTotalBet(this.totalBet));
      this.tableField[bet.betCombination] = true;
      this.bets.push({betCombination: bet.betCombination, amount: bet.amount, selectedCoin: this.selectedCoin});
      this.addCoinDirective.addCoinManual(bet);
      console.log(this.bets);
    });
    this.double();
  }

  undo(): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }

    if ( !this.bets.length ) { return; }
    const bet = this.bets.pop();
    this.totalBet-= bet.amount;
    this.store.dispatch(new SetTotalBet(this.totalBet));
    const field = document.getElementById(bet.betCombination);
    field.removeChild(field.lastChild);

    console.log(bet);
    const count = this.bets.find( x => x.betCombination === bet.betCombination);
    console.log('count same bets',count);
    if (!count) {
      this.tableField[bet.betCombination] = false;
    }

    if (!this.bets.length && this.gameSync) {
      this.messagesData = 'Put your bets';
    }

    if (this.bets.length < 1 ) {
      this.doubleBtn = true;
    }
  }

  selectCoin(coin, value): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }

    this.selectedCoin = coin;
    this.selectedCoinValue = value;
    this.store.dispatch(new SetCoinValue(value));
  }

  checkFieldType(value): boolean {
    const reg = RegExp('STRAIGHT');

    return reg.test(value);
  }

  startGameClock(time: string): void {
    this.store.dispatch(new SetIsGameStartStatus(true));
    const clientServerTimeDiff = this.store.snapshot().elitPokerHelper.timeDiff;

    const currentTime = new Date().getTime();
    const roundExpTime = new Date().getTime() + Number(time) * 1000;
    const timerTime = Math.round((roundExpTime - currentTime + clientServerTimeDiff) / 1000);

    const selfClock: HTMLParagraphElement = document.querySelector('.clock');
    const svgProgress: SVGAElement = document.querySelector('.svg-progress');
    let min: number; let sec: any;

    if(Number(timerTime) > 59) {
      min = 1;
      sec = +timerTime - 59;
    } else {
      min = 0;
      sec = timerTime;
    }
    const timeLimit = sec;


    console.log('Clock', clientServerTimeDiff);
    console.log('Clock', timerTime);

    if ( this.isGameStart ) {
      return;
    }
    this.clockInterval = setInterval(() => {
      sec = sec < 10 ? '0'+sec : sec;
      selfClock.innerHTML = `${min}:${sec}`;
      selfClock.style.color = '#EDD188';
      svgProgress.style.setProperty( '--dataProgressColor' , '#EDD188' );
      svgProgress.style.setProperty( '--dataProgress' , ((sec / timeLimit) * 297).toFixed(2) + 'px');
      this.isGameStart = true;
      this.eventService.gameStatus.next(true);
      if( min == 0 && sec < 10 ) {
        svgProgress.style.setProperty( '--dataProgressColor' , '#de4141' );
        selfClock.style.color = '#de4141';
      }
      if( sec == 0 ) {
        if(min == 0 && sec == 0) {
          clearInterval(this.clockInterval);
          this.isGameStart = false;
          this.eventService.gameStatus.next(false);
        }
        min = 0;
        sec = 60;
      }
      sec--;
    }, 1000);
  }

  loadCoinSVG(url): any {
    return new Promise((resolve, rejects) => {
       this.svgService.loadSvg(url).subscribe(
         res => {
          resolve(createElementFromHTML(res)) ;
         },
          async err => {
            const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
            const store = this.store.snapshot();

            const errorData = {
              AppName: 'Elites Poker',
              DeviceId: store.elitPokerAuth?.deviceId,
              UserId: store.elitPokerAuth?.details?.userId,
              PageUrl: window.location.pathname,
              ErrorDescription:  msg,
            };

            rejects(null);
            this.logOnServer(errorData);
            console.log(err);
          }
      );
     });
  }

  confirm(): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }

    if (this.betsConfirmed) {return;}
    console.log('confirm bet');
    const roundStatus = this.store.snapshot().rouletteGame.gameRound.roundStatus;

    if(roundStatus !== 'BETTING') {
      return;
    }

    if(this.bets.length < 1) {
      return;
    }

    const roundToken = this.store.snapshot().rouletteGame.gameRound.roundToken;
    console.log(roundToken);

    const bet = new PutBetModel(roundToken, this.bets);
    this.userService.putBet(bet).subscribe(
      res => {
        this.lastBets = [...this.bets];
        this.bets = [];
        this.betsConfirmed = true;
        this.refreshBalance(res.balance);
        this.messagesData = 'No more bets.';
        this.eventService.blockBet.next(true);
      },
      async err => {


        if ( err.error.systemMessage === 'ERROR_BET_AMOUNT_NOT_DIVISIBLE_BY_5') {
          this.racetrackMessage = 'Total bet amount must be divisible by 5 on Orphelins';
          this.messagesData = 'Total bet amount must be divisible by 5 on Orphelins';
        } else if ( err.error.systemMessage === 'ERROR_BET_AMOUNT_NOT_DIVISIBLE_BY_6') {
          this.racetrackMessage = 'Total bet amount must be divisible by 6 on Tier';
          this.messagesData = 'Total bet amount must be divisible by 6 on Tier';
        } else if ( err.error.systemMessage === 'ERROR_BET_AMOUNT_NOT_DIVISIBLE_BY_9') {
          this.racetrackMessage = 'Total bet amount must be divisible by 9 on Voisins Du Zero';
          this.messagesData = 'Total bet amount must be divisible by 9 on Voisins Du Zero';
        }  else if ( err.error.systemMessage === 'ERROR_BET_AMOUNT_NOT_ALLOWED') {
          this.messagesData = 'Bet amount not allowed.';
        } else {
          this.messagesData = 'Minimum bet is 5$';
        }

        setTimeout(() => {
          this.messagesData = 'Confirm bet.';
          this.racetrackMessage = '';

        },3000);


        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
        console.log(this.bets);
      },
    );

    this.doubleBtn = false;
  }

  changeChatStatus(){
    if (this.musicClick) { this.musicClick.play(); };

    this.closeChatModal = !this.closeChatModal;
    this.store.dispatch(new SetRouletteChatStatus(this.closeChatModal));
    if(this.closeChatModal) {
      setTimeout(()=> {
        this.scrollToBottom();
      },200);
    }
  }

  submitChat(): void {
    if ( !this.msgInput.length ) { return; }
    console.log('Messages: '+ this.msgInput);
    this.rouletteChatService.sendMessage({message: this.msgInput, name: this.userName}).subscribe(
      res => {
        console.log(res);
        this.msgInput = '';
        this.scrollToBottom();

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.msgInput = '';
        this.logOnServer(errorData);
        console.log(err);
      }
    );

  }

  scrollToBottom(): void {
    try {
      this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;
    } catch (err) { }
  }

  openChat(){
    this.scrollToBottom();
    this.store.dispatch(new SetRouletteChatStatus(true));
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); };
    this.store.dispatch(new ResetUnreadMessages());
  }

  closeChat(){
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    const status = this.store.snapshot().elitRoulettePokerChat?.isChatOpen;
    if(status === false) { return; }
    this.store.dispatch(new SetRouletteChatStatus(false));
  }

  switchMainTable(): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    this.isViewMainTable = !this.isViewMainTable;
    this.tableField = new TableFields();
    console.log('switch to main table bet', this.bets);
    setTimeout(() => {
      this.mainBets.forEach(bet => {
        if (bet.betCombination !== 'TIER' && bet.betCombination !== 'ORPHELINS' && bet.betCombination && 'VOISINSDUZERO') {
          this.addCoinDirective.addCoinManual(bet);
          this.tableField[bet.betCombination] = true;
        }
      });
    },100);
  }

  switchRoundedTable(): void {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    this.tableField = new TableFields();
    this.isViewMainTable = !this.isViewMainTable;
    console.log('switch table bet', this.bets);
    setTimeout(() => {
      this.roundedBets.forEach(bet => {
        this.addCoinDirective.addCoinManual(bet);
        this.tableField[bet.betCombination] = true;
      });
    },100);
  }

  calculateFieldAmount(input): number {
    let sum = 0;

    input.forEach((x) => {
      sum += x.amount;
    });

    console.log(sum);

    return sum;
  }

  double(): void {
    console.log('Double');
    if ( !this.bets.length) {return;}
    if ((this.totalBet * 2) >= this.maxBet) {return;}
    this.totalBet = 0;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.bets.length; i++) {
      this.bets[i].amount = this.bets[i].amount * 2;
      this.totalBet += this.bets[i].amount;
      this.isViewMainTable ?
        this.mainBets.push({betCombination: this.bets[i].betCombination, amount: this.bets[i].amount, selectedCoin: this.bets[i].selectedCoin}) :
        this.roundedBets.push({betCombination: this.bets[i].betCombination, amount: this.bets[i].amount, selectedCoin: this.bets[i].selectedCoin});
    }
    this.balanceAfterBet = this.balance - this.totalBet;
    this.store.dispatch(new SetTotalBet(this.totalBet));
    this.doubleBtn = false;
  }

  ifDoubleAvailable(): boolean {
    return (this.totalBet * 2) >= this.maxBet;
  }

  ifLastBetAvailable(): boolean {
    return false;
  }

  refreshBalance(balance): void {
    this.balance = balance;
  }

  logout(): void {
    this.authService.logout().subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
    if (this.musicTag) { this.musicTag.pause(); }
    this.store.dispatch(new Logout());
    this.navController.navigateForward(['/login']);
  }

  leaveGame(){
    if (this.musicClick) { this.musicClick.play(); }
    this.navController.navigateForward(['/game/choose_game']);
  }

  playClick(): void {
    if (this.musicClick) { this.musicClick.play(); }
  }

  ngOnDestroy(): void {
    this.socketService.disconnect();
    this.subscriptions.unsubscribe();
    clearInterval(this.clockInterval);
  }

  async goToAccount(): Promise<void> {
    // this.musicPlayer.playClick();
    if (this.musicClick) { this.musicClick.play(); }
    await this.navController.navigateForward(['/my-account']);
  }

  getStatistics(): void {
    this.gameService.getStatistics().subscribe(
      res => {
        console.log('Statistics', res);
        this.statisticsList = res;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
