import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {Store} from "@ngxs/store";
import {SetLastInvitation} from "../../../store/friends/friends.action";
import {NavController} from "@ionic/angular";
import {ResetChat} from "../../../store/chat/chat.actions";
import {SetChairNumber} from "../../../store/helper/helper.action";

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss'],
})
export class InvitationModalComponent implements OnInit {
  @Output() closeModalEmit = new EventEmitter();
  @Output() returnInviteModalEmit = new EventEmitter();
  public invitation = null;
  constructor(private store: Store,
              private navController: NavController) { }

  ngOnInit() {
    this.invitation = this.store.snapshot().eliteFriends.invitation;
  }

  closeModal(){
    this.closeModalEmit.emit();
  }

  returnInviteModal(){
    this.returnInviteModalEmit.emit();
  }

  decline(){
    this.store.dispatch(new SetLastInvitation(null));
  }

  join(){
    this.leaveTable();
    this.store.dispatch(new SetLastInvitation(null));
    this.navController.navigateForward([`/game/loading-invitation/${this.invitation.gameKey}`]);
  }

  leaveTable(): void {
    this.store.dispatch(new ResetChat());
    this.store.dispatch(new SetChairNumber(null));
  }

}
