import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  LoginInterface,
  RecoverPasswordInterfaces,
  RegisterInterface,
  VerifyOtpInterface
} from '../../interfaces';
import {
  ChangePasswordModel,
  LoginModel,
  RecoverPassword,
  RegisterModel,
  ResendOtpModel,
  SetNewPassword,
  VerifyOtpModel
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(data: LoginModel): Observable<LoginInterface> {
    return this.http.post<LoginInterface>( environment.serverURL + `/login`, data);
  }

  logout(data?): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/logout`, data);
  }

  register(data: RegisterModel): Observable<RegisterInterface> {
    console.log(data);
    return this.http.post<RegisterInterface>( environment.serverURL + `/registration`, data);
  }

  verifyOtp(data: VerifyOtpModel): Observable<VerifyOtpInterface> {
    return this.http.post<VerifyOtpInterface>( environment.serverURL + `/login/otp`, data);
  }

  resendOtp(data: ResendOtpModel): Observable<VerifyOtpInterface> {
    return this.http.post<VerifyOtpInterface>( environment.serverURL + `/login/resendotp`, data);
  }

  changePassword(data: ChangePasswordModel): Observable<VerifyOtpInterface> {
    return this.http.post<VerifyOtpInterface>( environment.serverURL + `/user`, data);
  }

  resetPassword(data: RecoverPassword ): Observable<RecoverPasswordInterfaces> {
    return this.http.post<RecoverPasswordInterfaces>( environment.serverURL + `/login/recover/requestsms`, data);
  }

  setNewPassword(data: SetNewPassword ): Observable<RecoverPasswordInterfaces> {
    return this.http.post<RecoverPasswordInterfaces>( environment.serverURL + `/login/recover/confirm`, data);
  }

  getAccountType(): Observable<any> {
    return this.http.get<any>( environment.bettingURL + `/user/accounttype`);
  }

  getAccountOTP(data): Observable<any> {
    return this.http.post<any>( environment.accountURL + `/account/generateotp`, data);
  }

  transferAccountType(data): Observable<any> {
    return this.http.post<any>( environment.accountURL + `/account/transferaccounttype`, data);
  }
}

