import { MusicService } from '../../../core/services/music/music.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {GameState} from '../../../store/game/game.state';
import {Store} from '@ngxs/store';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {GameService} from '../../../core/services/game/game.service';
import {SetBalance} from '../../../store/auth/auth.actions';
import {UserService} from '../../../core/services/user/user.service';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';

@Component({
  selector: 'app-buy-coins-modal',
  templateUrl: './buy-coins-modal.component.html',
  styleUrls: ['./buy-coins-modal.component.scss'],
})
export class BuyCoinsModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() confirmBuy = new EventEmitter();
  @Input() inputRangeValue;
  public rangeValue: number;
  public buyAmount = 1;
  public minJoinTableAmount = 1;
  public sliderValue = 1;
  public maxRange = 0;
  public table$ = this.store.select(GameState.getTable);
  public authStoreUser$ = this.store.select(GameState.getAuthUserGameInfo);
  public isLoading = false;
  public musicClick = null;

  constructor(private store: Store,
              private notificationService: NotificationService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private musicService: MusicService,
              private userService: UserService,
              private gameService: GameService) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    const storeSnapshot = this.store.snapshot()

    if(storeSnapshot.elitePokerMultipleGame?.selectedTables[0]?.maxJoinTableAmount >= storeSnapshot.elitPokerAuth.balance){
      this.maxRange = storeSnapshot.elitPokerAuth.balance;

    } else {
      this.maxRange = storeSnapshot.elitePokerMultipleGame?.selectedTables[0]?.maxJoinTableAmount;
    }

    const authUserOnTable = this.store.snapshot().elitPokerGame;
    if(authUserOnTable?.authUser?.status === 1 ) {
      this.minJoinTableAmount = 1;
    }

    if(authUserOnTable?.authUser?.status === 5) {
      this.minJoinTableAmount = 1;
      this.buyAmount = this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.joinTableAmount;
    }
  }


  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res?.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  closeModal(value): void {
    // this.musicService.playClick();
    // this.musicPlayer.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.close.emit(value);
  }

  confirm(): void {
    this.isLoading = true;

    const tableBalance = this.store.snapshot().elitPokerGame?.authUser?.tableBalance;
    const maxJoinTableAmount = this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.maxJoinTableAmount;
    console.log(this.buyAmount);
    console.log(tableBalance);
    console.log(maxJoinTableAmount);
    if(Number(this.buyAmount) + tableBalance > maxJoinTableAmount ) {
      this.notificationService.showError('Amount is bigger then max table amount.');
      return;
    }
    const data = {
      amount:  Number(this.buyAmount)
    };
    const gameKey = this.store.snapshot().elitPokerGame.game.gameKey;
    this.gameService.boyCoins(data, gameKey).subscribe(
      res => {
        console.log(res);
        this.closeModal(false);
        this.isLoading = false;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();
        this.isLoading = false;
        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
        this.closeModal(true);
      }
    );
    this.confirmBuy.emit(Number(this.buyAmount));
    // if (this.musicClick) { this.musicClick.play(); }
    // this.musicPlayer.playClick();
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
