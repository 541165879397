import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngxs/store";
import { ResetJackpotCommission } from 'src/app/store/helper/helper.action';


@Component({
  selector: 'app-jackpot',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.scss'],
})
export class JackpotComponent implements OnInit, OnDestroy {
  @Input() value: any = 0;
  @Input() positionUser: any = null;
  @Input() numberOfChairs: any = null;
  public positionDefinition: any = {
    six: {
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 5,
      '5': 7,
      '6': 8,
    },
    seven: {
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 5,
      '5': 7,
      '6': 8,
      '7': 9,
    },
    nine: {
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 4,
      '5': 5,
      '6': 6,
      '7': 7,
      '8': 8,
      '9': 9,
    },
  };
  public valueOfCounter: number = 0;
  public valueCountStop;
  public jackpotAnimationData: any = null;
  public jackpotScale:boolean = false;
  public animate:boolean = false;

;

  constructor(
    private store: Store,
  ) {

  }


  ngOnInit() {

    console.log('Input Value for jackpot amount',this.value)

    switch (this.numberOfChairs) {
      case 6:
          const chairNumberDefSix = this.positionDefinition['six'];
          const chairPositionForAnimationSix =  chairNumberDefSix[this.positionUser];
          setTimeout(() => {
            this.setUserPositionAnimation(chairPositionForAnimationSix);
          }, 6000);

        break;
      case 7:
        const chairNumberDefSeven = this.positionDefinition['seven'];
        const chairPositionForAnimationSeven =  chairNumberDefSeven[this.positionUser];
        setTimeout(() => {
          this.setUserPositionAnimation(chairPositionForAnimationSeven);
        }, 6000);

        break;
      case 9:
          const chairNumberDefNine = this.positionDefinition['nine'];
          const chairPositionForAnimationNine =  chairNumberDefNine[this.positionUser];
          setTimeout(() => {
            this.setUserPositionAnimation(chairPositionForAnimationNine);
          }, 6000);

          break;
      default: console.log('def char log');
    }

    setTimeout(() => {
      this.animate = true;
    }, 2000);

    setTimeout(() => {
      this.jackpotScale= true;
    }, 6000);

    setTimeout(() => {
      // this.valueOfCounter = this.value;
      this.increaseMoney();

    }, 4000);


  }

  increaseMoney(){

    this.valueOfCounter = this.value;

    //
    // let step = 20;
    //
    // if (this.value > 50) {
    //   step = 30
    // }
    //
    // if (this.value > 100) {
    //   step = 150
    // }
    //
    // if (this.value > 1000) {
    //   step = 500
    // }
    //
    // this.valueCountStop = setInterval(() => {
    //   this.valueOfCounter+= step;
    //
    //   if(this.valueOfCounter >= this.value) {
    //     clearInterval(this.valueCountStop);
    //     this.valueOfCounter = this.value;
    //
    //   }
    // },300);
  }


  setUserPositionAnimation(positionUser) {
    switch (positionUser) {
      case 9:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(-10.5vw, -114%) scale(0.35)',
            }
          };
          break;
      case 8:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(-27vw, -82%) scale(0.35)',
            }
          };
          break;
      case 7:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(-27.5vw, -41%) scale(0.35)',
            }
          };
          break;
      case 6:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(-18vw, -10%) scale(0.35)',
            }
          };
          break;
      case 5:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(-9vw, -10%) scale(0.35)',
            }
          };
          break;
      case 4:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(18vw, -10%) scale(0.35)',
            }
          };
          break;
      case 3:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(27.5vw, -41%) scale(0.35)',
            }
          };
          break;
      case 2:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(27vw, -82%) scale(0.35)',
            }
          };
          break;
      case 1:
          this.jackpotAnimationData = {
            default: {
              transform: 'translate(0, 0)',
            },
            in:{
              transform: 'translate(10.5vw, -114%) scale(0.35)',
            }
          };
          break;
      default:
          console.log('Today only, 3 + 1 free!!');
  }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetJackpotCommission());
  }
}
