/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {SetJoinTableInfo} from '../../../store/helper/helper.action';
import {HelperState} from '../../../store/helper/helper.state';
import {GameService} from '../../../core/services/game/game.service';
import {MusicService} from '../../../core/services/music/music.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {SetBalance} from '../../../store/auth/auth.actions';
import {UserService} from '../../../core/services/user/user.service';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';
import {AuthState} from '../../../store/auth/auth.state';

@Component({
  selector: 'app-buy-in-modal',
  templateUrl: './buy-in-modal.component.html',
  styleUrls: ['./buy-in-modal.component.scss'],
})
export class BuyInModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() confirmBuy = new EventEmitter();
  @Input() inputRangeValue;
  public maxRange = 0;
  public rangeValue: number;
  public rangeBuyValue = 120;
  public sliderValue = 0;
  public gameKey = null;
  public tableInfo$ = this.store.select(HelperState.getTableInfo);
  public userBalance$ = this.store.select(AuthState.getUserBalance);
  public isLoading = false;
  public musicClick = null;

  constructor(private store: Store,
              private gameService: GameService,
              private userService: UserService,
              private notificationService: NotificationService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private musicService: MusicService) { }

  ngOnInit() {
    this.getBalance();
    const storeSnapshot = this.store.snapshot();
    this.musicClick = document.getElementById('click-tag');


    if(storeSnapshot.elitPokerGame.selectedTable?.maxJoinTableAmount >= storeSnapshot.elitPokerAuth.balance){
      this.maxRange = storeSnapshot.elitPokerAuth.balance;

    } else {
      this.maxRange = storeSnapshot.elitePokerMultipleGame?.selectedTables[0]?.maxJoinTableAmount;

    }

    this.gameKey = storeSnapshot.elitePokerMultipleGame?.selectedTables[0]?.gameKey;

    this.gameService.getJoinTableInfo(this.gameKey).subscribe(
      res => {
        console.log(res);
        this.store.dispatch(new SetJoinTableInfo(res));
        this.sliderValue = res?.joinTableAmount;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res?.balance));
        this.isLoading = false;
      },
      async err => {
        this.isLoading = false;
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
      }
    );
  }

  closeModal(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.close.emit();
  }

  confirm(): void {
    this.isLoading = true;
    this.confirmBuy.emit(Number(this.sliderValue));
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicService.playClick();
  }

  changeRange(): void {
    this.rangeBuyValue = this.rangeValue;
  };

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
