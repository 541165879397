export class SetChairNumber {
  static readonly type = '[Helper] SetChairNumber';
  constructor(public chairNumber: number) {}
}

export class SetWinner {
  static readonly type = '[Helper] SetWinner';
  constructor(public winner: any, public delayTime: number) {}
}

export class SetPotAmount {
  static readonly type = '[Helper] SetPotAmount';
  constructor(public potAmounts: any) {}
}

export class SetAvatar {
  static readonly type = '[Helper] SetAvatar';
  constructor(public avatarSelected: any) {}
}

export class ResetPotAmount {
  static readonly type = '[Helper] ResetPotAmount';
  constructor() {}
}

export class SetJoinTableInfo {
  static readonly type = '[Helper] SetJoinTableInfo';
  constructor(public tableInfo: any) {}
}

export class SetNewJackpot {
  static readonly type = '[Helper] SetNewJackpot';
  constructor(public jackpotAmount: any) {}
}

export class InfoMessage {
  static readonly type = '[Helper] InfoMessage';
  constructor(public infoMessage: any) {}
}

export class SetNumberOfOnlineUsers {
  static readonly type = '[Helper] SetNumberOfOnlineUsers';
  constructor(public numberOfOnlineUsers: any) {}
}

export class SetShowPotAmount {
  static readonly type = '[Helper] SetShowPotAmount';
  constructor(public showPotAmounts: any) {}
}

export class UpdateCurrentJackpot {
  static readonly type = '[Helper] UpdateCurrentJackpot';
  constructor(public jackpot: any) {}
}

export class UpdatePotAmount {
  static readonly type = '[Helper] UpdatePotAmount';
  constructor(public winner: any) {}
}

export class SetMusic {
  static readonly type = '[Helper] SetMusic';
  constructor(public playMusic: boolean) {}
}
export class ResetHelper {
  static readonly type = '[Helper] ResetHelper';
  constructor() {}
}

export class ResetJackpotCommission {
  static readonly type = '[Helper] ResetJackpotCommission';
  constructor() {}
}

export class SetBigBlindConfirmed {
  static readonly type = '[Helper] SetMusic';
  constructor(public bigBlindConfirmed: boolean) {}
}

export class ResetMusic {
  static readonly type = '[Helper] ResetMusic';
  constructor() {}
}

export class SetCheck {
  static readonly type = '[Helper] SetCheck';
  constructor(public musicCheck: number) {}
}

export class MuteAll {
  static readonly type = '[Helper] MuteAll';
  constructor(public muteAll: boolean) {}
}

export class SetIsUserActive {
  static readonly type = '[Helper] SetIsUserActive';
  constructor(public isUserActive: boolean) {}
}

export class SetTimeDiff {
  static readonly type = '[Helper] SetTimeDiff';
  constructor(public timeDiff: any) {}
}

export class SetAutoActionStatus {
  static readonly type = '[Helper] SetAutoActionStatus';
  constructor(public autoActionStatus: any) {}
}

export class SetConnecting {
  static readonly type = '[Helper] SetConnecting';
  constructor(public setConnecting: any) {}
}
