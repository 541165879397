import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut } from 'src/app/animations/fadeInOut.animations';

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  animations: [
    fadeInOut
  ]
})
export class InviteModalComponent implements OnInit {
  @Output() closeModalEmit = new EventEmitter();

  @Input() inviteTabValueModal = 1;
  public tabActive = null;
  public fadeInRow = false;
  public removeFriend = false;



  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.fadeInRow = true;
    }, 500);
    this.tabActive = this.inviteTabValueModal;
  }

  tabSwitcher(numb: number) {
    console.log(numb);
    this.tabActive = numb;
  }

  closeModal(){
    this.closeModalEmit.emit();
  }
}
