import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMoveToNext]'
})
export class MoveToNextDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {

      e.preventDefault();

      const nextEl = this.findNextTabStop(document.activeElement);
      nextEl.focus();
    }
  }

  findNextTabStop(el) {
    const universe = document.querySelectorAll(
      'input, button, select, textarea, a[href]'
    );
    // eslint-disable-next-line arrow-body-style
    const list = Array.prototype.filter.call(universe, item => {
      return item.tabIndex >= '0';
    });
    const index = list.indexOf(el);
    return list[index + 1] || list[0];
  }

}
