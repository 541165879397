import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  AddNewLogMessage,
  AddNewMessage, ResetChat,
  ResetChatLog, ResetUnreadMessages,
  SetChatStatus,
  SetNumberOfOnlineUser,
  UnreadMessagesIncrease
} from './chat.actions';
import { Injectable } from '@angular/core';

export interface ChatStateModel {
  usersColor: any[];
  numberOfOnlineUser: number;
  messages: any[];
  logMessages: any[];
  unreadMessages: number;
  isChatOpen: boolean;
  test: boolean;
}

@Injectable()

@State<ChatStateModel>({
  name: 'elitPokerChat',
  defaults: {
    numberOfOnlineUser: null,
    messages: [],
    logMessages: [],
    usersColor: [],
    unreadMessages: 0,
    isChatOpen: false,
    test: false
  }
})

export class ChatState {

  constructor() {
  }
  @Selector()
  static getNumberOfOnlineUser(state: ChatStateModel): any {
    return state.numberOfOnlineUser || null;
  }

  @Selector()
  static getMessages(state: ChatStateModel): any {
    return state.messages || null;
  }

  @Selector()
  static getLogMessages(state: ChatStateModel): any {
    return state.logMessages || null;
  }

  @Selector()
  static getUnreadMessages(state: ChatStateModel): any {
    return state.unreadMessages || 0;
  }

  @Selector()
  static getIsChatOpen(state: ChatStateModel): any {
    return state.isChatOpen || 0;
  }

  @Action(AddNewMessage)
  addMessage(ctx: StateContext<ChatStateModel>, {message}: AddNewMessage): void {

    console.log('message from chat state', message)
    const messages = ctx.getState().messages;

    const usersColor = ctx.getState().usersColor;

    const user = usersColor.find(x => x.name === message.name);

    const randomHex = this.getRandomColor();
    let newMessage;
    if(!user) {
      usersColor.push({ name: message.name, color: randomHex});
      newMessage = {
        name: message.name,
        message: message.message,
        color: randomHex
      };
      messages.push(newMessage);

    } else {
      newMessage = {
        name: message.name,
        message: message.message,
        color: user.color
      };
      messages.push(newMessage);

    }

    console.log(ctx.getState());

    if (messages.length > 20) {
      messages.shift();
    }

    ctx.patchState({
      messages
    });

    ctx.dispatch(new UnreadMessagesIncrease());
  }

  @Action(AddNewLogMessage)
  addLogMessage(ctx: StateContext<ChatStateModel>, {logMessage}: AddNewLogMessage): void {

    ctx.patchState({
      logMessages: logMessage
    });


  }

  @Action(SetNumberOfOnlineUser)
  setNumberOfOnlineUser(ctx: StateContext<ChatStateModel>, {numberOfOnlineUser}: SetNumberOfOnlineUser): void {
    ctx.patchState({
      numberOfOnlineUser
    });
  }

  @Action(ResetChat)
  resetChatLog(ctx: StateContext<ChatStateModel>, {}: ResetChat): void {
    ctx.patchState({
      messages:[]
    });
  }

  @Action(SetChatStatus)
  setChatStatus(ctx: StateContext<ChatStateModel>, {isChatOpen}: SetChatStatus): void {
    ctx.patchState({
      isChatOpen,
      unreadMessages: 0
    });
  }


  @Action(UnreadMessagesIncrease)
  setUnreadMessages(ctx: StateContext<ChatStateModel>, {}: UnreadMessagesIncrease): void {
    const unreadMessages = ctx.getState().unreadMessages;
    const numberOfUnreadMessages = unreadMessages + 1;
    const isChatOpen = ctx.getState().isChatOpen;
    if(!isChatOpen) {
      ctx.patchState({
        unreadMessages: numberOfUnreadMessages
      });
    }
  }


  @Action(ResetUnreadMessages)
  resetUnreadMessages(ctx: StateContext<ChatStateModel>, {}: ResetUnreadMessages): void {
    ctx.patchState({
      unreadMessages: 0
    });
  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

}
