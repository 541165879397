import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {MusicService} from "../../../core/services/music/music.service";

@Component({
  selector: 'app-raise',
  templateUrl: './raise.component.html',
  styleUrls: ['./raise.component.scss'],
})
export class RaiseComponent implements OnInit {
  @Input() viewRaise: boolean;
  @Input() tableBalance: any = 0;
  @Output() closeRaise = new EventEmitter();
  @Output() confirmRaise = new EventEmitter();
  public raiseValue = '';
  public musicClick = null;

  constructor(public store: Store,
              private musicService: MusicService,
              public notificationService: NotificationService) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

  }

  close(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.closeRaise.emit(false);
  }

  addReiseValue(val): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    if((this.raiseValue + val).length > 5) {return;}
    this.raiseValue = this.raiseValue + val;
    console.log(this.raiseValue);
  }

  confirm() {
    const user = this.store.snapshot().elitPokerGame.authUser;
    console.log('user', user);
    console.log('game.maxBetAmount',user.maxBetAmount);
    console.log('game.maxBetAmount',user.minBetAmount);
    if (Number(this.raiseValue)  >  user.minBetAmount ) {
      this.confirmRaise.emit(this.raiseValue);
    } else  {
      this.notificationService.showError('Amount is not valid.');
    }
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  removeReiseValue(): void {
    this.raiseValue = this.raiseValue.substr(0, this.raiseValue.length - 1);
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }
}
