import {Directive, HostListener} from '@angular/core';
import {MusicService} from '../../../core/services/music/music.service';

@Directive({
  selector: '[appMusicCheck]'
})
export class MusicCheckDirective {

  private isMusicActive = false;

  @HostListener('click', ['$event.target'])
  async onClick(btn) {
    this.isMusicActive = this.musicService.isScreenTouched.getValue();
    if (!this.isMusicActive) {
      await this.musicService.setupMusicReference();
      this.musicService.prepareMusicInBackground()
    }
  }

  constructor(private musicService: MusicService) { }

}
