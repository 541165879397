import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssService {


  setDark(){
    this.change({
        '--main-background1': '#01dcdf',
        '--main-background2': '#01efb1'
      }
    );
  }

  setWhite(){
    this.change({
        '--main-background1': 'red',
        '--main-background2': 'black'
      }
    );
  }


  change(theme: any): void {
    Object.keys(theme).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        theme[property]
      );
    });
  }
}
