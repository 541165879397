import {Store} from '@ngxs/store';

import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../../core/services/socket/socket.service';
import {SelectGame} from '../../../store/game-roulette/game-roulette.actions';
import {NavController, Platform, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {AvailableGameModel} from '../../../core/models';
import {GameLobbyService} from '../../../core/services/game-lobby/game-lobby.service';
import { fadeInOut } from 'src/app/animations/fadeInOut.animations';
import {Subscription, timer} from 'rxjs';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {SetBalance} from '../../../store/auth/auth.actions';
import {UserService} from '../../../core/services/user/user.service';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-choose-game',
  templateUrl: './choose-game.component.html',
  styleUrls: ['./choose-game.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class ChooseGameComponent implements OnInit, ViewWillEnter, ViewWillLeave {
  @ViewChild('carousel') el: ElementRef;
  public availableGames: AvailableGameModel[] = [];
  public addClass = false;
  public triggerProfileModal = false;
  public allTableModal: any = false;
  public selectedGameType: any = '';
  public musicModal = false;
  public subscriptions = new Subscription();
  public previousOrientation: any = false;
  public landscape = true;
  public musicClick = null;


  constructor(private socketService: SocketService,
              private store: Store,
              private userService: UserService,
              private navController: NavController,
              private platform: Platform,
              // private musicService: MusicService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private so: ScreenOrientation,
              private cdr: ChangeDetectorRef,
              private gameLobbyService: GameLobbyService) { }

  ionViewWillEnter() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.getBalance();


    this.socketService.connect();
    this.getAvailableGames();

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.lockToLandscape();
      }
    });


    this.subscriptions.add(
      timer(1000, 500).subscribe(() => {
        this.checkOrientation();
      })
    );
  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  getAvailableGames(){
    this.gameLobbyService.getAvailableGames().subscribe(
      res => {
        this.availableGames = res;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    // if(!this.musicService.isMuted.getValue()) {
      // this.musicService.playBackgroundMusic();
    // }
    this.subscriptions.add(
      timer(1000, 500).subscribe(() => {
        this.checkOrientation();
      })
    );
    this.getBalance();
    this.getAvailableGames();
    setTimeout(() => {this.addClass = true;},500);
    // $('#owl-demo').owlCarousel({
    //   navigation : true
    // });
    // $('.owl-carousel').owlCarousel({
    //   loop: true,
    //   nav: false,
    //   dots: false,
    //   responsive:{
    //       320:{
    //           items:1
    //       },
    //       600:{
    //           items:2
    //       },
    //       1280:{
    //           items:3
    //       }
    //   }
    // });

    // $('.owl-next').click(function() {
    //   $('.owl-carousel').trigger('next.owl.carousel');
    // });
    // // Go to the previous item
    // $('.owl-prev').click(function() {
    //   // With optional speed parameter
    //   // Parameters has to be in square bracket '[]'
    //   $('.owl-carousel').trigger('prev.owl.carousel', [300]);
    // });
  }

  closeTableModal(value: any) {
    this.selectedGameType = '';
    this.allTableModal = false;
    this.triggerProfileModal = false;
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  checkOrientation() {
    // @ts-ignore
    this.cdr.detectChanges();
    if(window.innerHeight < window.innerWidth) {
      this.landscape = true;
      return;
    } else {
      this.landscape = false;
      return;
    }
  };

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  triggerProfileModalEmit(value: any) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();
    this.triggerProfileModal = value;
  }

  goToRoulette() {
    this.store.dispatch(new SelectGame(this.availableGames[0]));
    this.navController.navigateForward(['/roulette']);
  }

  playClick(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();
  }

  ionViewWillLeave() {
    this.subscriptions.unsubscribe();
  }
}
