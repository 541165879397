import {Action, Selector, State, StateContext} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {ResetBets, SetBalance, SetCoinValue, SetTotalBet} from './bet-roulette.actions';

export interface BetRouletteStateModel {
  totalBet: number;
  coinValue: number;
  balance: number;
}

@Injectable()

@State<BetRouletteStateModel>({
  name: 'betRoulette',
  defaults: {
    totalBet: 0,
    balance: 0,
    coinValue: 0,
  }
})

export class BetRouletteState {

  constructor() {
  }

  @Selector()
  static getTotalBet(state: BetRouletteStateModel): any {
    return state.totalBet || null;
  }

  @Selector()
  static getBalance(state: BetRouletteStateModel): any {
    return state.balance || null;
  }

  @Selector()
  static getCoinValue(state: BetRouletteStateModel): any {
    return state.coinValue || null;
  }


  @Action(SetTotalBet)
  setTotalBet(ctx: StateContext<BetRouletteStateModel>, {totalBet}: SetTotalBet): void {
    ctx.patchState({
      totalBet
    });
  }

  @Action(SetBalance)
  setBalance(ctx: StateContext<BetRouletteStateModel>, {balance}: SetBalance): void {
    ctx.patchState({
      balance
    });
  }

  @Action(SetCoinValue)
  setSelectedCoinValue(ctx: StateContext<BetRouletteStateModel>, {coinValue}: SetCoinValue): void {
    ctx.patchState({
      coinValue
    });
  }

  @Action(ResetBets)
  resetBets(ctx: StateContext<BetRouletteStateModel>, {balance}: ResetBets): void {
    ctx.patchState({
      balance,
      totalBet: 0
    });
  }


}
