import { RotateDeviceComponent } from './components/rotate-device/rotate-device.component';
import { RaiseComponent } from './components/raise/raise.component';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordStrenghtBarComponent } from './components/password-strenght-bar/password-strenght-bar.component';
import { ChatComponent } from './components/chat/chat.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { MoveToNextDirective } from './directives/move-to-next/move-to-next.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { HeaderMainComponent } from './components/header-main/header-main.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';
import { JoinGameModalComponent } from './components/join-game-modal/join-game-modal.component';
import { BuyInModalComponent } from './components/buy-in-modal/buy-in-modal.component';
import { BuyCoinsModalComponent } from './components/buy-coins-modal/buy-coins-modal.component';
import { AllTablesComponent } from '../pages/game/all-tables/all-tables.component';
import { CarouselSliderComponent } from './components/carousel-slider/carousel-slider.component';
import { SlickCarouselModule} from 'ngx-slick-carousel';
import { ChooseAvatarSliderComponent } from './components/choose-avatar-slider/choose-avatar-slider.component';
import { NotificationsComponent} from './components/notifications/notifications.component';
import { PickUpComponent} from './components/pick-up/pick-up.component';
import { CardComponent} from './components/card/card.component';
import { JackpotComponent } from './components/jackpot/jackpot.component';
import { MusicCheckComponent } from './components/music-check/music-check.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FriendsComponent } from './components/friends/friends.component';
import { InvitationModalComponent } from './components/invitation-modal/invitation-modal.component';
import { AddCoinDirective } from './directives/addCoin/add-coin.directive';
import { GameInfoComponent} from './components/game-info/game-info.component';
import { LinebreaksPipe } from './pipes/linebreaks/linebreaks.pipe';
import { FilterByParamPipe} from './pipes/filter-by-param/filter-by-param.pipe';
import { MyFriendsPipe } from './pipes/myFriends/my-friends.pipe';
import { InvitationsModalComponent } from './components/invitations-modal/invitations-modal.component';
import { InviteModalComponent} from './components/invite-modal/invite-modal.component';
import { DoubleTapDirective } from './directives/double-tap/double-tap.directive';
import { HoldOnModalComponent } from './components/hold-on-modal/hold-on-modal.component';
import { CardsPipe } from './pipes/cards/cards.pipe';
import { LimitStringPipe } from './pipes/limit-string/limit-string.pipe';
import { TapDirective } from './directives/tap/tap.directive';
import { MusicCheckDirective } from './directives/musicCheck/music-check.directive';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    MoveToNextDirective,
    ClickOutsideDirective,
    PasswordStrenghtBarComponent,
    ChatComponent,
    HeaderMainComponent,
    ProfileModalComponent,
    JoinGameModalComponent,
    BuyInModalComponent,
    BuyCoinsModalComponent,
    CarouselSliderComponent,
    ChooseAvatarSliderComponent,
    RaiseComponent,
    PickUpComponent,
    NotificationsComponent,
    CardComponent,
    JackpotComponent,
    MusicCheckComponent,
    PreloaderComponent,
    RotateDeviceComponent,
    FriendsComponent,
    InvitationModalComponent,
    InvitationsModalComponent,
    GameInfoComponent,
    LinebreaksPipe,
    FilterByParamPipe,
    MyFriendsPipe,
    InviteModalComponent,
    AddCoinDirective,
    DoubleTapDirective,
    HoldOnModalComponent,
    CardsPipe,
    LimitStringPipe,
    TapDirective,
    MusicCheckDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule
  ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        OnlyNumberDirective,
        MoveToNextDirective,
        ClickOutsideDirective,
        PasswordStrenghtBarComponent,
        ChatComponent,
        TranslateModule,
        HeaderMainComponent,
        ProfileModalComponent,
        JoinGameModalComponent,
        BuyInModalComponent,
        BuyCoinsModalComponent,
        CarouselSliderComponent,
        ChooseAvatarSliderComponent,
        RaiseComponent,
        NotificationsComponent,
        PickUpComponent,
        CardComponent,
        JackpotComponent,
        MusicCheckComponent,
        PreloaderComponent,
        RotateDeviceComponent,
        FriendsComponent,
        InvitationModalComponent,
        InvitationsModalComponent,
        GameInfoComponent,
        LinebreaksPipe,
        FilterByParamPipe,
        MyFriendsPipe,
        BuyCoinsModalComponent,
        CarouselSliderComponent,
        ChooseAvatarSliderComponent,
        RaiseComponent,
        NotificationsComponent,
        PickUpComponent,
        CardComponent,
        JackpotComponent,
        MusicCheckComponent,
        PreloaderComponent,
        RotateDeviceComponent,
        InviteModalComponent,
        InvitationModalComponent,
        AddCoinDirective,
        DoubleTapDirective,
        HoldOnModalComponent,
        LimitStringPipe,
        TapDirective,
        MusicCheckDirective
    ]
})
export class ShareModule { }
