export class RegisterModel {
  phoneNumber: string;
  userName: string;
  email: string;
  password: string;
  country: string;
  preferredLanguage: string;
  birthDate: Date;

  constructor(data?: any) {
    this.phoneNumber = data.phoneNumber;
    this.userName = data.userName;
    this.email = data.email;
    this.password = data.password;
    this.country = data.country;
    this.preferredLanguage = data.preferredLanguage;
    this.birthDate = data.birthDate;
  }
}


