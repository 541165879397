import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    transition('* => *', [

        query(':enter', stagger('.5s', [
          animate('{{time}}ms ease-in', keyframes([
            style({ 
                opacity: 0, 
                visibility: 'hidden', 
                offset: 0 
            }),
            style({ 
                opacity:1, 
                visibility: 'visible',
                offset: 1}),
          ]))]), { optional: true }),
     
        query(':leave', stagger('.5s', [
          animate('{{time}}ms ease-out', keyframes([
            style({ 
                opacity: 1, 
                visibility: 'visible', 
                offset: 0 
            }),
            style({ 
                opacity: 0, 
                visibility: 'hidden',
                offset: 1}),
          ]))]), { optional: true })
      ]),
])
