import { GameService } from 'src/app/core/services/game/game.service';
import { SocketService } from '../../../../core/services/socket/socket.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { Store } from '@ngxs/store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MusicService } from 'src/app/core/services/music/music.service';
import { SetAutoActionStatus, SetChairNumber, SetJoinTableInfo } from 'src/app/store/helper/helper.action';
import { Logout } from 'src/app/store/auth/auth.actions';
import { ResetChar, ResetGame, SelectTable } from 'src/app/store/game/game.actions';
import { NavController, ViewWillEnter } from '@ionic/angular';
import { ResetChat } from '../../../../store/chat/chat.actions';
import { FriendsState } from '../../../../store/friends/friends.state';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ErrorLogService } from '../../../../core/services/error-log/error-log.service';
import { TranslateService } from '@ngx-translate/core';
import {RemoveTable} from "../../../../store/multiple-game/multiple-game.actions";

@Component({
  selector: 'app-header-game',
  templateUrl: './header-game.component.html',
  styleUrls: ['./header-game.component.scss'],
})
export class HeaderGameComponent implements OnInit, ViewWillEnter {
  @Input() joinGameConfirmed: boolean;
  @Input() gameKey: any;
  @Output() triggerInvitaionsModalEmit = new EventEmitter();
  @Output() triggerInviteModalEmit = new EventEmitter();
  @Output() triggerInviteValueModalEmit = new EventEmitter();
  public invitationList$ = this.store.select(FriendsState.getMyInvitations);
  public friendRequestList$ = this.store.select(FriendsState.getFriendRequests);
  public selectedCharNumber: number = null;
  public isOpenMenu = false;
  public muteSound: boolean;
  public userInfo: any = null;
  public userDetails: any = null;
  public invitationModal = false;
  public musicClick = null;

  constructor(
    private router: Router,
    private store: Store,
    private navController: NavController,
    private authService: AuthService,
    private gameService: GameService,
    private notificationService: NotificationService,
    private socketService: SocketService,
    private musicService: MusicService,
    private errorLogService: ErrorLogService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    this.muteSound = this.musicService.isMuted.getValue();
    this.selectedCharNumber = this.store.snapshot().elitPokerHelper?.chairNumber;
    this.userInfo = this.store.snapshot().elitPokerAuth.user;
    this.userDetails = this.store.snapshot().elitPokerAuth.details;
  }

  triggerInvitationsModal(value) {
    this.triggerInvitaionsModalEmit.emit(value);
  }

  triggerInviteModal(value){
    this.triggerInviteModalEmit.emit(true);
    this.triggerInviteValueModalEmit.emit(value);
  }

  ionViewWillEnter(): void {
    this.muteSound = this.musicService.isMuted.getValue();
    this.userInfo = this.store.snapshot().elitPokerAuth.user;
    this.userDetails = this.store.snapshot().elitPokerAuth.details;
  }

  leaveGame(){
    this.store.dispatch(new SetAutoActionStatus(0));
    this.joinGameConfirmed = false;
    this.store.dispatch(new ResetChat());


    this.socketService.invokeRemove(this.gameKey);
    this.socketService.disconnect();
    this.gameService.leaveTable(this.gameKey).subscribe(
      res => {
        this.resetGameInfo();
        // this.router.navigate(['/game/home']);
        this.navController.navigateForward(['/game/home']);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        // this.notificationService.showError(msg);
        const store = this.store.snapshot();
        this.navController.navigateForward(['/game/home']);
        this.resetGameInfo();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };
        console.log('[GAME]LeaveGameError', err);
        this.logOnServer(errorData);
        console.log(err);
      }
    );

    const store = this.store.snapshot();

    const errorDataLog2 = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  'User trigger leave game, header component',
    };

    this.logOnServer(errorDataLog2);
  }

  logOnServer(error): void {
    console.log('[ServerLog]', error);

    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLogSent]');
      },
      err => console.log(err)
    );
  }

  leaveTable(){
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.store.dispatch(new SetAutoActionStatus(0));

    this.joinGameConfirmed = false;
    this.isOpenMenu = false;
    this.selectedCharNumber = null;
    this.store.dispatch(new ResetChat());

    this.gameService.leaveTable(this.gameKey).subscribe(
      res => {
        this.getJoinTableInfo();
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        // this.notificationService.showError(msg);
        const store = this.store.snapshot();
        this.store.dispatch(new SetChairNumber(null));
        this.getJoinTableInfo();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );

    const store = this.store.snapshot();

    const errorDataLog = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  'User trigger stand up from table, header component',
    };

    this.logOnServer(errorDataLog);

  }

  getJoinTableInfo() {
    this.gameService.getJoinTableInfo(this.gameKey).subscribe(
      res => {
        console.log(res);
        this.store.dispatch(new SetJoinTableInfo(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  muteSoundFunction(){
    this.musicService.isMuted.getValue() ? this.musicService.unmute() : this.musicService.mute();
    this.muteSound = this.musicService.isMuted.getValue();
  }

  resetGameInfo(): void {
    this.store.dispatch(new SelectTable(null));
    this.store.dispatch(new RemoveTable(null));
    this.store.dispatch(new ResetChar());
    this.store.dispatch(new ResetGame());
    this.store.dispatch(new SetChairNumber(null));
  }

  playClickMusic(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  logout(): void {
    this.socketService.invokeRemove(this.gameKey);

    this.authService.logout().subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
    this.store.dispatch(new SetAutoActionStatus(0));
    // this.musicService.playClick()

    if (this.musicClick) {
      this.musicClick.play();
    }

    // this.musicPlayer.playClick();
    this.musicService.mute();
    this.leaveTable();
    this.store.dispatch(new Logout());
    //this.navController.navigateForward(['/login']);
    this.router.navigate(['/auth/login']);
  }
}
