import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventRouletteService {
  public gameStatus = new BehaviorSubject(false);
  public blockBet = new BehaviorSubject(false);
  constructor() { }
}
