/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ResetChar,
  ResetGame,
  SelectTable,
  SetGameStatus,
  SetNumberOfChair,
  SetWaitingList
} from 'src/app/store/game/game.actions';
import {ResetChat, ResetChatLog, SetChatStatus} from '../../../store/chat/chat.actions';
import {
  ResetJackpotCommission,
  ResetPotAmount,
  SetAutoActionStatus,
  SetChairNumber,
  SetIsUserActive,
  SetJoinTableInfo,
  SetWinner
} from '../../../store/helper/helper.action';
import {CardModel} from '../../../core/models';
import {GameState} from '../../../store/game/game.state';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../../core/services/event/event.service';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {MusicService} from '../../../core/services/music/music.service';
import {NavController, Platform, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {GameService} from '../../../core/services/game/game.service';
import {Actions, Store} from '@ngxs/store';
import {HelperState} from '../../../store/helper/helper.state';
import {ChatState} from '../../../store/chat/chat.state';
import {Subscription, timer} from 'rxjs';
import {GameStatusEnum} from '../../../core/enum/game-status-enum';
import {AuthState} from '../../../store/auth/auth.state';
import {fadeInOut} from 'src/app/animations/fadeInOut.animations';
import {tableCardAnimation, tableCardAnimation1} from 'src/app/animations/tableCard.animations';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {SocketService} from '../../../core/services/socket/socket.service';
import {SetBalance} from '../../../store/auth/auth.actions';
import {UserService} from '../../../core/services/user/user.service';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';
import {RemoveTable} from '../../../store/multiple-game/multiple-game.actions';

const NEXT_WINNER_TIME = 3300;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  animations: [
    fadeInOut,
    tableCardAnimation,
    tableCardAnimation1
  ]
})
export class GameComponent implements OnInit, OnDestroy, AfterViewInit, ViewWillEnter, ViewWillLeave {
  @ViewChild('rangeValue') rangeValue: ElementRef;
  @ViewChild('rangeInput') rangeInput: ElementRef;

  dropdownEdit = false;
  dropdownPot = false;
  dropdownRange = false;
  closeChatModal = false;

  public timeouts = [];
  public music;
  public buyInAmount = 0;
  public rangeBuyValue = 0;
  public raiseValueSlider = 0;
  public jackpotUserPosition = 1;
  public communityCards = [];
  public communityCardsForTwice = [];
  public showWinnerIndex = 0;
  public waitingList = null;
  public raiseData = null;
  public viewBuyIn = false;
  public moreChips = false;
  public swipingElement = null;

  public swipingLeft = null;
  public swipingRight = null;


  public friendsModal = false;
  public invitationModal = false;
  public invitationsModal = false;
  public dontBuyChips = false;
  public viewRaise = false;
  public isOpenMenu = false;
  public isActive = false;
  public betConfirmed = false;
  public showWinner = true;
  public showPickUp = false;
  public preBlock = false;
  public userOnChair = null;
  public table = null;
  public showJackpot = false;
  public selectedRaiseType = '';
  public user;
  public joinGame = false;
  public joinGameConfirmed = false;
  public unreadMessagesCount$ = this.store.select(ChatState.getUnreadMessages);
  public authUserInGame$ = this.store.select(GameState.getAuthUserGameInfo);
  public numberOfChair$ = this.store.select(GameState.getNumberOfChair);
  public jackpot$ = this.store.select(HelperState.getCurrentJackpot);

  public subscriptions = new Subscription();
  public maxRaiseAmount = 0;
  public previousOrientation: any = false;
  public landscape = true;

  public gameStatusEnum = GameStatusEnum;

  public gameKey: any = null;
  public selectedCharNumber: any = null;
  public tablePassword: any = null;
  public subscription: any = new Subscription();
  public game: any;
  public userSub;
  public authUserInfo;
  public authUserStatus;
  public activeUser = null;
  public playMusic: boolean;

  public numberOfChair: number = null;

  public jackpot = false;
  public isMyTurn = false;
  public potAmounts$ = this.store.select(HelperState.getShowPotAmount);
  public getWinner$ = this.store.select(HelperState.getWinner);
  public userBalance = null;

  public musicClick = null;
  public musicMyTurn = null;
  public musicRaise = null;
  public musicFold = null;
  public musicCall = null;
  public musicAll = null;

  public pullMoneyJackpot = false;
  public pullMoneyGirl = false;
  public isOnceOrTwiceConfirmed = false;
  public inviteValueModal = null;
  public commission$ = this.store.select(HelperState.getCommission);
  public getAutoActionStatus$ = this.store.select(HelperState.getAutoActionStatus);
  public isApk: boolean;


  constructor(private store: Store,
              private gameService: GameService,
              private navController: NavController,
              private router: Router,
              private musicService: MusicService,
              private changeDetectorRef: ChangeDetectorRef,
              private socketService: SocketService,
              private musicPlayer: MusicService,
              private notificationService: NotificationService,
              private eventService: EventService,
              private userService: UserService,
              private so: ScreenOrientation,
              private platform: Platform,
              private actions$: Actions,
              private cdr: ChangeDetectorRef,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private route: ActivatedRoute) {
  }

  @HostListener('window:focus', ['$event'])
  detectFocus(event) {
    this.changeDetectorRef.detectChanges();
    const store = this.store.snapshot();

    const errorData = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  'User put app in focus',
    };

    this.logOnServer(errorData);
  }


  @HostListener('window:blur', ['$event'])
  detectBlur(event) {
    const store = this.store.snapshot();

    const errorData = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  'User put app out of focus',
    };

    this.logOnServer(errorData);
  }

  closeRaiseInputDropdown() {
    this.dropdownRange = false;
  }

  closeRaiseDropdown() {
    this.viewRaise = false;
  }

  closeDropdownPot() {
    this.dropdownPot = false;
  }

  closeChatOutside() {
    this.closeChatModal = false;
  }

  openRangeDropdown() {
    this.dropdownRange = !this.dropdownRange;
    this.rangeInput.nativeElement.focus();
  }

  // stopSwiping(){
  //
  //   this.swipingLeft = document.getElementById('left-panel');
  //   this.swipingRight = document.getElementById('right-panel');
  //
  //   if(this.swipingLeft && this.swipingRight) {
  //     console.log('touch listener initialized')
  //
  //     this.swipingLeft.addEventListener('touchstart', (e: any) => {
  //       console.log('no click swipingLeft',  e.touches[0].clientX)
  //         if (Math.round(e.touches[0].clientX) < 10) {
  //           console.log('left edge')
  //
  //           e.preventDefault();
  //           return;
  //         }
  //       // is not near edge of view, exit
  //        e.preventDefault();
  //         return;
  //     });
  //
  //     this.swipingRight.addEventListener('touchstart', (e: any) => {
  //       // is not near edge of view, exit
  //       console.log('no click swipingRight')
  //       if (Math.round(e.touches[0].clientX) > window.innerWidth - 10) {
  //         console.log('right edge')
  //
  //         e.preventDefault();
  //         return;
  //       }
  //
  //
  //       e.preventDefault();
  //       return;
  //     });
  //
  //   }
  //
  //   // if(this.swipingElement) {
  //   //   this.swipingElement.addEventListener("touchstart", (e: any) => {
  //   //     // is not near edge of view, exit
  //   //
  //   //     if (e.targetTouches[0].clientX < 30 ) {
  //   //       e.preventDefault();
  //   //       return;
  //   //     };
  //   //
  //   //     if (e.targetTouches[0].clientX > window.innerWidth - 30) {
  //   //       e.preventDefault();
  //   //       return;
  //   //     };
  //   //
  //   //
  //   //   });
  //   // }
  //
  //
  // }

  ionViewWillEnter(): void {
    // this.swipingElement = document.getElementById("stop-swiping");

    // this.stopSwiping();

    if(this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey) {
      this.gameKey = this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey;
    }

    this.loadMusic();
    this.store.dispatch(new ResetJackpotCommission());

    this.getBalance();
    this.socketService.connect();

    this.platform.ready().then(() => {

      if((window as any).MobileAccessibility){
        (window as any).MobileAccessibility.usePreferredTextZoom(false);
      }
      if (this.platform.is('cordova')){
        this.lockToLandscape();
      }
    });

    this.selectedCharNumber = this.store.snapshot().elitPokerHelper?.chairNumber || null;


    // if(this.store.snapshot().elitPokerGame?.selectedTable?.gameKey) {
    //   this.gameKey = this.store.snapshot().elitPokerGame?.selectedTable?.gameKey;
    // } else {
    //   this.gameKey = this.route.snapshot.queryParams.gameKey;
    // }




    this.socketService.invokeJoin(this.gameKey);

    this.tablePassword = this.route.snapshot.queryParams.password;
    this.getTableStatus(this.gameKey);
    this.getWaitingListInfo(this.gameKey);


    this.getJoinTableInfo();

    console.log('socket successful connected');
    this.user = this.store.snapshot().elitPokerAuth?.user;

    this.subscription.add(
      this.eventService.pickCardStatus.subscribe(res => this.showPickUp = res)
    );

    this.subscription.add(
      this.eventService.reconnectedSuccessful.subscribe(res => {
        this.communityCards = [];
        this.communityCardsForTwice = [];
        this.getTableStatus(this.gameKey);
        this.socketService.invokeJoin(this.gameKey);
      })
    );

    this.subscription.add(
      this.eventService.pullMoney.subscribe((res: any) => {
        this.pullMoneyGirl = res;
      })
    );

    this.subscription.add(
      this.eventService.resetChair.subscribe(res => {
        if(!this.viewBuyIn) {
          this.selectedCharNumber = null;
        }
      })
    );



    this.subscription.add(
      this.store.select( store => store.elitPokerGame).subscribe(
        res => {
          this.notificationService.setReconnecting(false);

          if (res?.authUser?.autoAction) {
            this.store.dispatch(new SetIsUserActive(res?.authUser?.autoAction || 0));
          }

          this.preBlock = false;
          const authUser = this.store.snapshot().elitPokerAuth;

          this.isMyTurn = authUser?.user?.userId === res?.activeUserId;

          const users = this.store.snapshot()?.elitPokerGame?.game?.users;
          this.authUserStatus = res?.table[this.store.snapshot().elitPokerHelper?.chairNumber];


          if(this.authUserStatus?.status === 5 && !this.dontBuyChips) {
            if(this.authUserStatus.tableBalance + this.authUserStatus.addToTableBalance > this.authUserStatus.bigBlindAmount ) {return;}
            this.moreChips = true;
            this.dontBuyChips = true;
          }

          this.showPickUp = false;

          if (res.table[this.selectedCharNumber]?.status === 5) {
            this.getJoinTableInfo();
            this.joinGameConfirmed = true;
          };

          if (this.authUserInfo?.status === 1 && !this.joinGameConfirmed && users?.length > 2 && !this.authUserInfo.payBigBlind) {
            this.joinGame = true;
            this.joinGameConfirmed = true;
          };




          if(this.userOnChair?.status === 4 && !this.viewBuyIn) {
            this.selectedCharNumber = null;
            this.store.dispatch(new SetChairNumber(null));
          }

          if (this.authUserStatus?.status === 4) {
            this.store.dispatch(new SetChairNumber(null));
            this.getJoinTableInfo();
          }

          this.rangeBuyValue = res?.table[this.store.snapshot().elitPokerHelper?.chairNumber]?.checkAmount;

          this.isActive = res?.game?.activeUserId === this.user?.userId;

          this.store.dispatch(new SetIsUserActive(this.isActive));
          if (this.isActive) {
            this.selectedRaiseType = '';
            this.musicService.playMyTurn();
          } else {
            this.triggerOtherPlayerSound(res?.lastActivityInfo?.type || null);
          }

          //aaaaa

          this.game = res?.game;
          this.showJackpot = false;

          if(this.userOnChair && res?.game?.users?.length > 0) {
            this.setRaiseLimit(res.game?.users);
          }

          this.isActive = res?.game?.activeUserId ===  this.user?.userId;

          if (res?.game?.status === 1 ) {
            this.store.dispatch(new SetWinner(null,null));
          }

          if (res?.game?.status !== 3 ) {
            this.isOnceOrTwiceConfirmed = false;
          }

          if (res?.game?.status === 0 ) {
            this.store.dispatch(new SetWinner(null, null));
            // this.dontBuyChips = false;
            this.communityCards = [];
            this.communityCardsForTwice = [];
          }

          if (res?.game?.status === 20 ) {
            if(res?.game?.jackpotAmount === 0) {
              this.store.dispatch(new ResetPotAmount());
            }
            this.showWinner = true;

            this.eventService.pullMoney.next(false);

            this.store.dispatch(new SetWinner(null, null));
            // this.dontBuyChips = false;
            this.communityCards = [];
            this.communityCardsForTwice = [];
          }


          if(this.communityCards?.length === 0 && res?.game?.communityCards?.length > 0) {
            this.communityCards = res?.game?.communityCards;
          }

          if(this.communityCards?.length < res.game?.communityCards.length) {
            this.addNewCard(res?.game?.communityCards);
          }

          if (res?.game?.winningInfo && res?.game?.status === 10 && this.showWinner) {

              this.eventService.pullMoney.next(true);

            this.timeouts.push(
              setTimeout(() => {
                const table = this.store.snapshot().elitPokerGame.table;
                for (const property in table) {
                  if(this.game?.winningInfo?.jackpotUserId !== undefined && table[property]?.id === this.game?.winningInfo?.jackpotUserId) {
                    this.jackpotUserPosition = table[property]?.chairNumber;
                  }
                }
                this.showJackpot = true;
              },(res?.game?.winningInfo?.startJackpotTimeInMiliseconds ))
            );

            this.timeouts.push(
              setTimeout(() => {
                this.communityCardsForTwice = res?.game?.communityCardsForTwice;
              }, res?.game?.winningInfo?.startSecondRowTimeInMiliseconds )
            );

              this.timeouts.push(
                setTimeout(() => {
                  this.showWinnerIndex = 0;
                  this.store.dispatch(new SetWinner(res?.game?.winningInfo?.winners[this.showWinnerIndex], NEXT_WINNER_TIME));
                  this.markWinningCards(this.game);
                // },timerForSecRow + NEXT_WINNER_TIME));
                },res?.game?.winningInfo?.startWinnerTimeInMiliseconds  ));
              this.showWinner = false;
          }


          this.selectedRaiseType = '';
          // this.raiseData = null;


          if (!this.isActive) {
             this.triggerOtherPlayerSound(res?.lastActivityInfo?.type);
          }
        }
      )
    );


    this.subscriptions.add(
      this.store.select(AuthState.getUser).subscribe(
        res => {
          this.userSub = res;
        }
      )
    );

    this.subscriptions.add(
      this.store.select(GameState.getAuthUserGameInfo).subscribe(
        res => {
          this.authUserInfo = res;
          this.raiseValueSlider = this.authUserInfo?.minBetAmount || 1;
          this.raiseData =  {
            amount: this.raiseValueSlider,
            allIn: false
          };
        }
      )
    );

    this.subscription.add(
      this.store.select( store => store?.elitPokerGame?.table).pipe().subscribe(
        res => {
          this.table = res;

          this.userOnChair = res[this.store.snapshot().elitPokerHelper?.chairNumber];
          if(this.game?.users?.length>0) {
            this.setRaiseLimit(this.game.users);
          }

          if(res[this.selectedCharNumber]?.status === 4) {
            this.selectedCharNumber = null;
            this.store.dispatch(new SetChairNumber(null));
          }




          // if(res[this.selectedCharNumber]?.status === 5 && res[this.selectedCharNumber]?.tableBalance === 0 && !this.dontBuyChips) {
          //   this.moreChips = true;
          //   this.dontBuyChips = true;
          // }

          if(res[this.selectedCharNumber]?.status === 1 ||
            res[this.selectedCharNumber]?.status === 2 ||
            res[this.selectedCharNumber]?.status === 3 ||
            res[this.selectedCharNumber]?.status === 4) {
            this.dontBuyChips = false;
          }


        }
      )
    );

    this.subscriptions.add(
      timer(1000, 500).subscribe(() => {
        this.checkOrientation();
      })
    );

    this.playMusic = this.store.snapshot().elitPokerHelper?.playMusic;

  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  loadMusic(): void {
    this.musicClick = document.getElementById('click-tag');
    this.musicMyTurn = document.getElementById('myTurn-tag');
    this.musicRaise = document.getElementById('raise-tag');
    this.musicFold = document.getElementById('fold-tag');
    this.musicCall = document.getElementById('call-tag');
    this.musicAll = document.getElementById('all-tag');
  }

  getJoinTableInfo() {
    this.gameService.getJoinTableInfo(this.gameKey).subscribe(
      res => {
        this.store.dispatch(new SetJoinTableInfo(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage || 'SERVER_ERROR').toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res?.balance));
        this.userBalance = res?.balance;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  triggerOtherPlayerSound(type){
    // switch(type) {
    //   case 'Call':
    //     // code block
    //     this.musicService.playCall();
    //     break;
    //   case 'Raise':
    //     this.musicService.playRaise();
    //     // code block
    //     break;
    //   case 'Fold':
    //     // code block
    //     this.musicService.playFold();
    //     break;
    //   default:
    //   // code block
    // }
  }

  markWinningCards(data){

    for(let k = 0; k < this.communityCards.length;k++) {
      this.communityCards[k].isWinning = false;
    }

    for(let n = 0; n < this.communityCardsForTwice.length;n++) {
      this.communityCardsForTwice[n].isWinning = false;
    }
    this.cdr.detectChanges();

    this.timeouts.push(
      setTimeout(() => {
        for (let i = 0; i < data?.winningInfo?.winners[this.showWinnerIndex]?.winningCombination.length; i++) {

          for(let j = 0; j < this.communityCards.length; j++) {
            if( this.communityCards[j].value === data?.winningInfo?.winners[this.showWinnerIndex]?.winningCombination[i].value &&
              this.communityCards[j].suit === data?.winningInfo?.winners[this.showWinnerIndex]?.winningCombination[i].suit) {
              this.communityCards[j].isWinning = true;
            }
          }

          for(let m = 0; m < this.communityCardsForTwice.length; m++) {
            if( this.communityCardsForTwice[m].value === data?.winningInfo?.winners[this.showWinnerIndex]?.winningCombination[i].value &&
              this.communityCardsForTwice[m].suit === data?.winningInfo?.winners[this.showWinnerIndex]?.winningCombination[i].suit) {
              this.communityCardsForTwice[m].isWinning = true;
            }
          }
        }
        this.cdr.detectChanges();
        this.showWinnerIndex++;
      }, 250)
    );

    if (this.showWinnerIndex<= data?.winningInfo?.winners.length - 1){

      this.timeouts.push(
        setTimeout(() => {

          if(data?.winningInfo?.winners[this.showWinnerIndex]) {

            this.store.dispatch(new SetWinner(data?.winningInfo?.winners[this.showWinnerIndex], NEXT_WINNER_TIME));

            this.markWinningCards(this.game);
          }

        }, NEXT_WINNER_TIME)
      );


    }
  }

  closeMoreChips(value){
    this.moreChips = false;
  }

  ngAfterViewInit() {
    // this.swipingElement = document.getElementById("home-wrapper");
    // this.stopSwiping()
  }


  ngOnInit() {
    // this.swipingElement = document.getElementById("home-wrapper");
    // this.stopSwiping()


    if(this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey) {
      this.gameKey = this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey;
    }

    this.isApk = window.hasOwnProperty('cordova');
    this.socketService.connect();
    this.tablePassword = this.route.snapshot.queryParams.password;
    this.getJoinTableInfo();
  };

  closeBuyInModal(){
    this.viewBuyIn = false;
    this.selectedCharNumber = null;
    this.store.dispatch(new SetChairNumber(null));
  }

  closeInviteModal(){
    this.friendsModal = false;
  }

  openInvitationModal() {
    this.invitationModal = true;
  }

  leaveTable(): void {
    // this.musicService.playClick()
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.joinGameConfirmed = false;
    this.isOpenMenu = false;
    // this.socketService.disconnect();
    this.selectedCharNumber = null;
    this.store.dispatch(new ResetChat());

    // this.musicPlayer.stopMusic();
    this.gameService.leaveTable(this.gameKey).subscribe(
      res => {
        console.log('napustio igru');
      },
    async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();
        this.store.dispatch(new SetChairNumber(null));
        this.getJoinTableInfo();
        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );

    const store = this.store.snapshot();

    const errorDataLog = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  'User trigger leave table from invitation modal',
    };

    this.logOnServer(errorDataLog);
  }

  closeInvitationModal(){
    this.invitationModal = false;
  }

  closeInvitationsModal(value){
    if(value) {
      this.leaveTable();
    }
    this.invitationsModal = false;
  }

  returnInviteModal() {
    this.friendsModal = true;
    this.invitationsModal = true;
    this.invitationModal = false;
  }

  triggerInviteModalEmit(value: any) {
    this.friendsModal = value;
  }

  triggerInvitaionsModalEmit(value: any) {
    this.invitationsModal = value;
  }

  triggerInviteValueModalEmit(value: any) {
    this.inviteValueModal = value;
  }

  addNewCard(array){
    for (let i = 0; i < array.length; i++) {
      const selectedIndex = this.communityCards.findIndex((card: CardModel) => {
        return card?.value === array[i].value && card?.suit === array[i].suit;
      });
      if (selectedIndex === -1)  {this.communityCards.push(array[i]);}
    }

  }

  payToJoin(value) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    if (value) {
      this.gameService.payBigBlind(this.gameKey).subscribe(
        res => {
          this.joinGameConfirmed = true;

          this.joinGame = false;
        },
        async err => {
          const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
          this.notificationService.showError(msg);
          const store = this.store.snapshot();

          const errorData = {
            AppName: 'Elites Poker',
            DeviceId: store.elitPokerAuth?.deviceId,
            UserId: store.elitPokerAuth?.details?.userId,
            PageUrl: window.location.pathname,
            ErrorDescription:  msg,
          };

          this.logOnServer(errorData);
          console.log(err);
        }
      );
    } else {
      this.joinGame = value;
    }

  }

  changeChatStatus(){
    this.closeChatModal = !this.closeChatModal;
    this.store.dispatch(new SetChatStatus(this.closeChatModal));
  }

  openChat(){
    const status = this.store.snapshot().elitPokerChat?.isChatOpen;
    if(status === true) { return; }
    // this.musicService.playClick()


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.store.dispatch(new SetChatStatus(true));
  }

  closeChat(){
    const status = this.store.snapshot().elitPokerChat?.isChatOpen;
    if(status === false) { return; }
    // this.musicService.playClick()


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.store.dispatch(new SetChatStatus(false));
  }

  joinToTable(data): void {
    if(this.game?.users?.length >= 2) {
      this.joinGame = true;
    }
    this.gameService.joinTable(data).subscribe(
      res => {
        this.store.dispatch(new SetGameStatus(res?.gameInfo));
      },
      async err => {
        this.selectedCharNumber = null;
        this.store.dispatch(new SetChairNumber(null));
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  setRaise(inputValue){

    let value = inputValue;

    if (this.game?.status === 3) {return;}
    if(!this.isActive) { return; }

    if(value >= this.user.maxBetAmount) {
      value = this.user.maxBetAmount;
    }

    // this.musicService.playClick()



    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.raiseValueSlider = Number(value);
    this.selectedRaiseType = null;
    this.viewRaise = false;

    this.raiseData =  {
      amount: this.raiseValueSlider,
      allIn: false
    };

    this.selectedRaiseType = 'manual';

    this.confirmRaise();
  }

  updateRaiseData(){
    if (this.game?.status === 3) {return;}

    if(!this.isActive) { return; }
    this.selectedRaiseType = null;

    this.raiseData =  {
      amount: this.raiseValueSlider,
      allIn: false
    };

    this.selectedRaiseType = 'manual';
  }

  fold(): void {
    if(this.authUserInfo.status === 4) {return;}
    if(!this.isActive) {return;}
    if (this.game?.status === 3 ) {return;}
    // this.musicPlayer.playFold();
    // this.musicService.playFold();
    if (this.musicFold) {
      this.musicFold.play();
    }
    this.gameService.fold(this.gameKey).subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  call(): void {
    // if(this.preBlock) {return;}
    if(!this.isActive) {return;}
    if(this.betConfirmed) {return;}
    if(this.authUserInfo.status === 4) {return;}

    if (this.game?.status === 3) {return;}
    // this.musicPlayer.playCall();
    // this.musicService.playCall();

    if (this.musicCall) {
      this.musicCall.play();
    }

    const data =  {
      amount: this.store.snapshot().elitPokerGame?.authUser?.checkAmount,
      allIn:  this.userOnChair?.checkAmount > this.userOnChair?.tableBalance
    };
    this.betConfirmed = true;
    this.bet(data);

    if(this.authUserInfo?.checkAmount === 0) {

      if (this.musicClick) {
        this.musicClick.play();
      }

      // this.musicPlayer.playClick();
      return;
    }
  }

  raise(): void {
    console.log('raise');
    if (this.game.status === 3) {return;}
    // this.musicPlayer.playRaise();
    // this.musicService.playRaise();

    if (this.musicRaise.play()) {
      this.musicRaise.play();
    }

    if(this.authUserInfo.status === 4) {return;}

    this.raiseData =  {
      amount: this.raiseValueSlider,
      allIn: false
    };
  }

  confirmRaise() {
    if(!this.isActive) {return;}
    if (this.game?.status === 3 ) {return;}
    // this.musicPlayer.playAll();
    // this.musicService.playRaise();

    if (this.musicRaise.play()) {
      this.musicRaise.play();
    }
    console.log('RAISE DATA', this.raiseData);
    this.bet(this.raiseData);
  }

  setRange(val): void {
    if (this.game?.status === 3) {return;}
    if(!this.isActive) { return; }
    // this.musicService.playClick()


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.selectedRaiseType = null;

    const user = this.store.snapshot().elitPokerGame?.authUser;
    if ( val === '+' &&  this.raiseValueSlider + 10 < user.maxBetAmount) {

      this.raiseValueSlider = + this.raiseValueSlider + 10;
    } else if (val === '-' && (this.raiseValueSlider - 10) > user.minBetAmount ) {
      this.raiseValueSlider -= 10;
    }

    this.raiseData =  {
      amount: this.raiseValueSlider,
      allIn: false
    };
    this.selectedRaiseType = 'manual';
  }

  setAutoAction(autoActionInput){
    console.log('dupli klik');
    const autoActionData = {
      autoAction: autoActionInput
    };
    const status = this.store.snapshot().elitPokerHelper.autoActionStatus;

    if (status === autoActionInput) {
      autoActionData.autoAction = 0;
    }

    this.userService.autoAction(autoActionData, this.gameKey).subscribe(
        res => {
          this.store.dispatch(new SetAutoActionStatus(autoActionData?.autoAction || 0));
          console.log(res);
        },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  changeBuyRange(val): void {
    this.viewBuyIn = false;
    this.buyInAmount = val;
    this.pickChair();
  };

  checkOrientation() {
    // @ts-ignore
    this.cdr.detectChanges();
    if(window.innerHeight < window.innerWidth) {
      this.landscape = true;
      return;
    } else {
      this.landscape = false;
      return;
    }

    // if (window.orientation !== this.previousOrientation  ) {
    //   this.previousOrientation = window.orientation;
    //   // @ts-ignore
    //   this.landscape = this.previousOrientation === 90 || this.previousOrientation === -90;
    //   console.log('is landscape', this.landscape);
    // }
  };

  getTableStatus(gameKey) {
    // console.log('get table status info');
    this.gameService.getTableInfo(gameKey).subscribe(
      res => {
        this.store.dispatch(new SetGameStatus(res));
        this.numberOfChair = res?.maxNumberOfUsers;
        this.store.dispatch(new SetNumberOfChair(res?.maxNumberOfUsers));
        this.selectedCharNumber = this.store.snapshot().elitPokerHelper?.chairNumber;
        setTimeout(() => {
          this.eventService.startAnimationAgain.next(true);
          this.eventService.getMyCards.next(true);
        }, 500);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  resetGameInfo(): void {
    this.store.dispatch(new SelectTable(null));
    this.store.dispatch(new RemoveTable(null));
    this.store.dispatch(new ResetChar());
    this.store.dispatch(new ResetGame());
    this.store.dispatch(new SetChairNumber(null));
  }

  bet(data) {
    // this.eventService.stopAnimation.next(false);

    this.gameService.bet(data, this.gameKey).subscribe(
      res => {
        this.betConfirmed = false;
        this.selectedRaiseType = '';
        this.raiseData = data ? data : this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey?.minBetAmount;
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();
        // this.raiseData = null;
        this.betConfirmed = false;
        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  allIn(value){
    this.musicService.playAllIn();



    if (this.musicAll.play()) {
      this.musicAll.play();
    }


    this.raiseData =  {
      amount: this.store.snapshot().elitPokerGame?.game?.potAmount,
      allIn: true
    };
    this.selectedRaiseType = value;
  }

  halfPot(value){
    const snapshot = this.store.snapshot().elitPokerGame?.game;
    let amount = (snapshot?.potAmount / 2) + this.authUserInfo?.minBetAmount;

    if (amount < snapshot?.minBetAmount) {
      amount = snapshot?.minBetAmount;
    }
    // this.musicService.playClick()
    // this.musicPlayer.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.raiseValueSlider = amount;


    this.raiseData =  {
      amount,
      allIn: false
    };
    this.selectedRaiseType = value;
  }

  fullPot(value){
    const snapshot = this.store.snapshot().elitPokerGame?.game;
    let amount = snapshot?.potAmount + this.authUserInfo?.checkAmount || 0;

    if (amount < snapshot?.minBetAmount) {
      amount = snapshot?.minBetAmount;
    }
    // this.musicService.playClick()
    // this.musicPlayer.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.raiseValueSlider = amount;

    this.raiseData =  {
      amount,
      allIn: false
    };
    this.selectedRaiseType = value;
  }

  threeQuarterPot(value){
    const snapshot = this.store.snapshot().elitPokerGame?.game;
    let amount = ((snapshot?.potAmount / 4) * 3) + this.authUserInfo?.minBetAmount;

    if (amount < snapshot?.minBetAmount) {
      amount = snapshot?.minBetAmount;
    }
    // this.musicService.playClick()
    // this.musicPlayer.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.raiseValueSlider = amount;


    this.raiseData =  {
      amount,
      allIn: false
    };
    this.selectedRaiseType = value;
  }


  openMoreChips(): void {
    const store = this.store.snapshot();
    if(!store.elitPokerGame?.authUser) {return;}
    if(store.elitPokerGame?.authUser.status === 4) {return;}

    // if(!store.elitPokerGame?.authUser?.tableBalance) {return;}

    if (this.store.snapshot().elitePokerMultipleGame?.selectedTables[0]?.gameKey?.maxJoinTableAmount - store.elitPokerGame?.authUser?.tableBalance < 1) {
      this.notificationService.showSuccess('You already have enough money');
      return;
    };

    this.moreChips = true;
    // this.musicService.playClick()


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

  }

  jointToWaitingList() {
    const data = {
      gameKey: this.gameKey,
      password: this.tablePassword,
      joinTableAmount: this.buyInAmount
    };
    // this.musicService.playClick()
    // this.musicPlayer.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    this.gameService.joinWaitingList(this.gameKey, data).subscribe(
      res => {
        console.log(res);
        this.store.dispatch(new SetWaitingList(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  getWaitingListInfo(key): void {
    this.gameService.getWaitingListInfo(key).subscribe(
      res => {
        this.store.dispatch(new SetWaitingList(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  setRaiseLimit(users){
    const usersAmount = [];

    users.forEach((user) => {
      if(user.userId !== this.userOnChair?.userId) {
        usersAmount.push(user?.tableBalance);
      }
    });

    const max = Math.max(...usersAmount);

    this.maxRaiseAmount = max;

  }

  buyInAndJoin(charNumber): void {

    console.log(charNumber);
    const state = this.store.snapshot();
    console.log(state.elitPokerGame.table[charNumber]);

    if(state.elitPokerGame.table[charNumber] !== null) {
      this.notificationService.showError('Please select one of available chairs');
      return;
    }

    if (state.elitPokerGame?.waitingList.users[0]?.userId !== state.authPoker?.user?.userId && this.selectedCharNumber) {
      this.notificationService.showSuccess('Please wait your turn');
      return;
    }

    if(state.elitPokerHelper?.chairNumber !== null) {return;}
    if(this.store.snapshot().elitPokerHelper?.chairNumber !== null) {return;}
    // this.musicService.playClick()


    if (this.musicClick.play()) {
      this.musicClick.play();
    }
    this.selectedCharNumber = charNumber;
    this.viewBuyIn = true;
    this.store.dispatch(new SetChairNumber(charNumber));
  }

  pickChair() {
    const data = {
      gameKey: this.gameKey,
      password: this.tablePassword,
      chairNumber: this.selectedCharNumber,
      joinTableAmount: this.buyInAmount
    };

    this.joinToTable(data);
    // this.musicService.playClick()
    // this.musicPlayer.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }
  }

  startProgressCombination(elem): void {
    const circle: SVGAElement = document.querySelector('.progress-combination-circle-' + elem);
    let time = 0;
    circle.style.strokeDashoffset =  222 + '';
    circle.style.setProperty( '--dataProgressCombinationColor' , '#62FF00' );

    const interval = setInterval(() => {
      time++;

      if ( 222 - time === 0 ) {
        clearInterval(interval);
      }

      if( 222 - time === 30 ) {
        circle.style.setProperty( '--dataProgressCombinationColor' , '#f11414' );
      }

      circle.style.strokeDashoffset =  222 - time + '';
    }, 50);
  };

  playClickMusic(): void {
     // this.musicService.playClick();


    if (this.musicClick.play()) {
      this.musicClick.play();
    }
  }

  confirmOnceOrTwice(value) {
    // this.musicService.playClick()



    if (this.musicClick.play()) {
      this.musicClick.play();
    }

    // this.musicPlayer.playClick();
    console.log('confirm once or twice');
    this.isOnceOrTwiceConfirmed = true;

    const data = {
      twice: value
    };

    this.gameService.onceOrTwice(data, this.gameKey).subscribe(
      res => {
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  clearTimeouts() {
    for (let i=0; i<this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  ionViewWillLeave(): void {
    this.swipingRight.removeEventListener('touchstart', null);
    this.swipingLeft.removeEventListener('touchstart', null);

    this.socketService.invokeRemove(this.gameKey);
    this.joinGameConfirmed = false;
    this.viewBuyIn = false;
    this.viewRaise = false;
    this.isOpenMenu = false;
    this.store.dispatch(new ResetChar());
    this.communityCards = [];
    this.store.dispatch(new ResetChatLog());
    this.store.dispatch(new SetChairNumber(null));
    this.clearTimeouts();
    this.showWinner = true;

  }

  ngOnDestroy(): void {
    this.socketService.invokeRemove(this.gameKey);
    this.clearTimeouts();
    this.store.dispatch(new SetChairNumber(null));
    this.store.dispatch(new ResetChar());
    this.store.dispatch(new ResetChatLog());
    this.viewBuyIn = false;
    this.viewRaise = false;
    this.joinGameConfirmed = false;
    this.isOpenMenu = false;
    this.subscriptions.unsubscribe();
    this.showWinner = true;
  }
}
