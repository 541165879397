import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appTap]'
})
export class TapDirective {
  @Output() touchEnd = new EventEmitter<boolean>();
  @HostListener('touchend') onClick() {
    console.log('touchend release');
    this.touchEnd.emit(true);
  }


}
