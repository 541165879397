import { ErrorHandler, Injectable } from '@angular/core';
import {ErrorLogService} from '../services/error-log/error-log.service';
import {Store} from "@ngxs/store";
import {ActivatedRoute} from "@angular/router";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private erroLogService: ErrorLogService,
              private store: Store,
              private route: ActivatedRoute) { }
  handleError(error) {

    const store = this.store.snapshot();
    const errorData = {
      AppName: 'Elites Poker',
      DeviceId: store.elitPokerAuth?.deviceId,
      UserId: store.elitPokerAuth?.details?.userId,
      PageUrl: window.location.pathname,
      ErrorDescription:  error.message,
    };

    this.logOnServer(errorData);
    throw error;
  }

  logOnServer(error): void {

    this.erroLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    )
  }
}
