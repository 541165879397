import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  constructor(private httpClient: HttpClient) { }

  logError(error): any {
    const headers = new HttpHeaders().set('api-key', "err6v9y$B&EuH@Mc");
    return this.httpClient.post(environment.errorURL + '/errors', error, {headers});
  }
}
