import {Component, Input, OnInit} from '@angular/core';
import {InfoMessage} from "../../../store/helper/helper.action";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.scss'],
})
export class GameInfoComponent implements OnInit {
  @Input()msg;
  constructor(private store: Store) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.store.dispatch(new InfoMessage(null))
  }

}
