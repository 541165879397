
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  InfoMessage, MuteAll,
  ResetHelper, ResetJackpotCommission, ResetMusic,
  ResetPotAmount, SetAutoActionStatus, SetAvatar, SetBigBlindConfirmed,
  SetChairNumber, SetCheck, SetConnecting, SetIsUserActive,
  SetJoinTableInfo, SetMusic, SetNewJackpot, SetNumberOfOnlineUsers,
  SetPotAmount, SetShowPotAmount, SetTimeDiff,
  SetWinner, UpdateCurrentJackpot,
  UpdatePotAmount
} from "./helper.action";

export interface HelperStateModel {
  friendList: any[];
  commission: any;
  timeDiff: any;
  numberOfOnlineUsers: any;
  autoActionStatus: any;
  jackpotCommission: any;
  chairNumber: any;
  winner: any;
  tableInfo: any;
  jackpot: any;
  infoMessage: any;
  bigBlindConfirmed: any;
  potAmounts: any;
  showPotAmounts: any;
  playMusic: boolean;
  muteMusic: boolean;
  muteAll: boolean;
  musicCheck: number;
  isUserActive: boolean;
  setConnecting: boolean;
  avatarSelected: any;
}

@Injectable()

@State<HelperStateModel>({
  name: 'elitPokerHelper',
  defaults: {
    avatarSelected: false,
    timeDiff: 0,
    jackpot: 0,
    isUserActive: false,
    autoActionStatus: 0,
    numberOfOnlineUsers: 0,
    chairNumber: null,
    jackpotCommission: 0,
    commission: 0,
    winner: null,
    tableInfo: null,
    playMusic: null,
    muteAll: null,
    infoMessage: null,
    muteMusic: null,
    musicCheck: 1,
    bigBlindConfirmed: false,
    potAmounts: [],
    showPotAmounts: [],
    friendList: [],
    setConnecting: null,
  }
})



export class HelperState {
  private timeouts = [];
  constructor(private store: Store) {
  }

  @Selector()
  static getWinner(state: HelperStateModel): any {
    return state.winner || null;
  }

  @Selector()
  static getAutoActionStatus(state: HelperStateModel): any {
    return state.autoActionStatus || 0;
  }

  @Selector()
  static getCurrentJackpot(state: HelperStateModel): any {
    return state.jackpot || 0;
  }
  @Selector()
  static getNumberOfOnlineUsers(state: HelperStateModel): any {
    return state.numberOfOnlineUsers || 0;
  }

  @Selector()
  static getShowPotAmount(state: HelperStateModel): any {
    return state.showPotAmounts || [];
  }

  @Selector()
  static getInfoMessage(state: HelperStateModel): any {
    return state.infoMessage || [];
  }
  @Selector()
  static getIsUserActive(state: HelperStateModel): any {
    return state.isUserActive || null;
  }

  @Selector()
  static getTableInfo(state: HelperStateModel): any {
    return state.tableInfo || [];
  }

  @Selector()
  static getPotAmount(state: HelperStateModel): any {
    return state.potAmounts || [];
  }

  @Selector()
  static getConnecting(state: HelperStateModel): any {
    return state.setConnecting || false;
  }

  @Selector()
  static getChairNumber(state: HelperStateModel): any {
    return state.chairNumber || null;
  }

  @Selector()
  static getMusicStatus(state: HelperStateModel): any {
    return state.muteMusic || null;
  }

  @Selector()
  static getJackpot(state: HelperStateModel): any {
    return state.jackpotCommission || null;
  }
  @Selector()
  static getCommission(state: HelperStateModel): any {
    return state.commission || null;
  }

  @Action(SetChairNumber)
  setChairNumber(ctx: StateContext<HelperStateModel>, {chairNumber}: SetChairNumber): void {
    ctx.patchState({chairNumber});
  }

  @Action(SetWinner)
  setWinner(ctx: StateContext<HelperStateModel>, {winner, delayTime}: SetWinner): void {
    this.clearTimeouts();
    const pokerState = this.store.snapshot().elitPokerGame;

    if(winner && delayTime) {
      const userInList = pokerState.game?.users.find( user => user.id === winner.userId);
      if(!userInList) {return;}
      const table = pokerState.table;
      let currentPosition = null;

      for (const property in table) {
        if(userInList.id !== undefined && table[property]?.id === userInList.id) {
          currentPosition = table[property]?.chairNumber;
        }
      }

      this.timeouts.push(setTimeout(() => {
        table[currentPosition].tableBalance = Number(Number(table[currentPosition].tableBalance) + Number(winner.winAmount)).toFixed(2);
      },1100));

    }

    ctx.patchState({winner});
    this.store.dispatch(new UpdatePotAmount(winner))


  }

  @Action(ResetPotAmount)
  resetJackpotAndCommission(ctx: StateContext<HelperStateModel>, {}: ResetPotAmount): void {
    ctx.patchState({
      commission: 0,
      jackpotCommission: 0
    });
  }

  @Action(SetPotAmount)
  setPotAmounts(ctx: StateContext<HelperStateModel>, {potAmounts}: SetPotAmount): void {
    ctx.patchState({potAmounts});
  }

  @Action(SetAvatar)
  setAvatar(ctx: StateContext<HelperStateModel>, {avatarSelected}: SetAvatar): void {
    ctx.patchState({avatarSelected});
  }

  @Action(SetTimeDiff)
  setSetTimeDiff(ctx: StateContext<HelperStateModel>, {timeDiff}: SetTimeDiff): void {
    ctx.patchState({timeDiff});
  }

  @Action(InfoMessage)
  setInfoMessage(ctx: StateContext<HelperStateModel>, {infoMessage}: InfoMessage): void {
    ctx.patchState({infoMessage});
  }
  @Action(SetNumberOfOnlineUsers)
  setNumberOfOnlineUsers(ctx: StateContext<HelperStateModel>, {numberOfOnlineUsers}: SetNumberOfOnlineUsers): void {
    ctx.patchState({numberOfOnlineUsers});
  }

  @Action(SetIsUserActive)
  isUserActive(ctx: StateContext<HelperStateModel>, {isUserActive}: SetIsUserActive): void {
    ctx.patchState({isUserActive});
  }

  @Action(SetAutoActionStatus)
  setAutoActionStatus(ctx: StateContext<HelperStateModel>, {autoActionStatus}: SetAutoActionStatus): void {
    ctx.patchState({autoActionStatus});
  }

  @Action(ResetHelper)
  reset(ctx: StateContext<HelperStateModel>, {}: ResetHelper): void {
    ctx.patchState({
      chairNumber: null,
      jackpotCommission: 0,
      jackpot: 0,
      commission: 0,
      winner: null,
      tableInfo: null,
      playMusic: null,
      bigBlindConfirmed: false,
      potAmounts: [],
      showPotAmounts: [],
    });
  }

  @Action(SetJoinTableInfo)
  setJoinTableInfo(ctx: StateContext<HelperStateModel>, {tableInfo}: SetJoinTableInfo): void {
    ctx.patchState({tableInfo});
  }

  @Action(SetNewJackpot)
  setNewJackpot(ctx: StateContext<HelperStateModel>, {jackpotAmount}: SetNewJackpot): void {
    ctx.patchState({jackpotCommission: jackpotAmount});
  }

  @Action(UpdateCurrentJackpot)
  setCurrentJackpot(ctx: StateContext<HelperStateModel>, {jackpot}: UpdateCurrentJackpot): void {
    ctx.patchState({jackpot});
  }


  @Action(SetShowPotAmount)
  setShowPotAmount(ctx: StateContext<HelperStateModel>, {showPotAmounts}: SetShowPotAmount): void {
    // this.clearTimeouts();
    const game = this.store.snapshot().elitPokerGame.game;

    const newPots = [];

    showPotAmounts.forEach((pot) => {
      const newPot = ((100 - game.totalCommissionPercent) / 100) * pot;
      newPots.push(newPot.toFixed(2))
    });


    ctx.patchState({
      showPotAmounts: newPots
    });


    if(game?.totalCommission > 0 && game?.totalJackpotCommission > 0 ) {
      this.timeouts.push(setTimeout(() => {
        ctx.patchState({
          commission: game.totalCommission,
          jackpotCommission: game.totalJackpotCommission
        });
      }, 1200));

    }



      this.timeouts.push(setTimeout(() => {
        const newJackpot = game.jackpotAmount + game.totalJackpotCommission;
        ctx.patchState({
          jackpot: newJackpot,
        });
      }, 1700));




  }


  @Action(UpdatePotAmount)
  updatePotAmount(ctx: StateContext<HelperStateModel>, {winner}: UpdatePotAmount): void {

    const potAmounts = ctx.getState().showPotAmounts;
    if(potAmounts.length > 0) {

      const firstPotByIndex = potAmounts[0];
      if(firstPotByIndex !== undefined && winner) {
        if (firstPotByIndex && firstPotByIndex > 0 ) {
          const leftInPot = Number(firstPotByIndex) - Number(winner?.jackpotCommission) - Number(winner?.commission) - Number(winner?.winAmount);
          potAmounts[0] = leftInPot.toFixed(2);
        } else {
          potAmounts.shift();
        }
      };
    }

    ctx.patchState({showPotAmounts: potAmounts});
  }

  @Action(SetMusic)
  setMusic(ctx: StateContext<HelperStateModel>, {playMusic}: SetMusic): void {
    ctx.patchState({playMusic});
  }

  @Action(SetCheck)
  setCheck(ctx: StateContext<HelperStateModel>, {musicCheck}: SetCheck): void {
    ctx.patchState({musicCheck});
  }

  @Action(MuteAll)
  muteAll(ctx: StateContext<HelperStateModel>, {muteAll}: MuteAll): void {
    ctx.patchState({muteAll});
  }

  @Action(ResetJackpotCommission)
  resetJackpotCommission(ctx: StateContext<HelperStateModel>, {}: ResetJackpotCommission): void {
    ctx.patchState({
      commission: 0,
      jackpotCommission: 0
    });
  }

  @Action(SetBigBlindConfirmed)
  setBigBlindConfirmed(ctx: StateContext<HelperStateModel>, {bigBlindConfirmed}: SetBigBlindConfirmed): void {
    ctx.patchState({bigBlindConfirmed});
  }


  @Action(SetConnecting)
  setConnecting(ctx: StateContext<HelperStateModel>, {setConnecting}: SetConnecting): void {
    ctx.patchState({setConnecting});
  }

  @Action(ResetMusic)
  resetMusic(ctx: StateContext<HelperStateModel>, action: ResetMusic): void {
    ctx.patchState({playMusic: null});
  }

  clearTimeouts() {
    for (let i=0; i<this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
  }
}
