/* eslint-disable @typescript-eslint/no-unused-expressions */
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TableService } from 'src/app/core/services/table/table.service';
/* eslint-disable guard-for-in */
import { Store } from '@ngxs/store';
import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import { ResetChar, ResetGame, SelectTable } from 'src/app/store/game/game.actions';
import { SetChairNumber } from 'src/app/store/helper/helper.action';
import { fadeInOut } from '../../../animations/fadeInOut.animations';
import { AuthState } from 'src/app/store/auth/auth.state';
import { Platform, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import { SocketService } from '../../../core/services/socket/socket.service';
import { UserService } from '../../../core/services/user/user.service';
import { SetFriendList} from '../../../store/friends/friends.action';
import { NavController} from '@ionic/angular';
import { Subscription, timer} from 'rxjs';
import { SetBalance } from '../../../store/auth/auth.actions';
import { ErrorLogService } from '../../../core/services/error-log/error-log.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class HomeComponent implements OnInit,AfterViewInit, ViewWillEnter, ViewWillLeave {
  public availableGames = [];
  public selectedGame = null;
  public triggerHoldemModal = false;
  public triggerOmahaModal = false;
  public triggerPineappleModal = false;
  public triggerOmaha5Modal = false;
  public triggerAllTablesModal = false;
  public allTableModal: any = false;
  public selectedGameType: any = '';
  public allActiveTables = null;
  public allTables = false;
  public triggerBuyCoinsModal = false;
  public triggerProfileModal = false;
  public addClass = false;
  public userBalance$ = this.store.select(AuthState.getUserBalance);
  public previousOrientation: any = false;
  public landscape = true;
  public subscriptions = new Subscription();
  public musicClick = null;
  public swipingElement = null;

  constructor(
    private so: ScreenOrientation,
    private userService: UserService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private socketService: SocketService,
    private errorLogService: ErrorLogService,
    private translateService: TranslateService,
    private tableService: TableService,
    private notificationService: NotificationService,
    private platform: Platform,
    private navController: NavController,
  ) { }

  ngAfterViewInit() {
    // this.swipingElement = document.getElementById("home-wrapper");
    // this.stopSwiping()
  }

  ngOnInit() {

    this.musicClick = document.getElementById('click-tag');

    this.socketService.connect();
    setTimeout(() => {this.addClass = true;},500);
    this.getAvailableTables();
    this.store.dispatch(new SelectTable(null));
    this.store.dispatch(new ResetChar());
    this.store.dispatch(new ResetGame());
    this.store.dispatch(new SetChairNumber(null));
    this.getBalance();
    this.subscriptions.add(
      timer(1000, 500).subscribe(() => {
        this.checkOrientation();
      })
    );
  }

  stopSwiping(){
    // if(this.swipingElement) {
    //   this.swipingElement.addEventListener('touchstart', (e: any) => {
    //     // is not near edge of view, exit
    //
    //     if (e.targetTouches[0].clientX < 30 ) {
    //       e.preventDefault();
    //       return;
    //     };
    //
    //     if (e.targetTouches[0].clientX > window.innerWidth - 30) {
    //       e.preventDefault();
    //       return;
    //     };
    //
    //   });
    // }


  }

  checkOrientation() {
    // @ts-ignore
    this.cdr.detectChanges();
    if(window.innerHeight < window.innerWidth) {
      this.landscape = true;
      return;
    } else {
      this.landscape = false;
      return;
    }

  };


  ionViewWillEnter(): void {
    // this.stopSwiping()
    this.socketService.connect();
    this.loadMyFriendsList();
    this.getBalance();

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.lockToLandscape();
      }
    });
  }

  backToChooseGame() {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.navController.navigateForward(['./game/choose_game']);
  }

  // Lock to portrait
  lockToPortrait(){
    this.so.lock(this.so.ORIENTATIONS.PORTRAIT);
  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  getAvailableTables(): void {
    //setTimeout(() => {this.setAnimationClass = true;},500);
    this.tableService.getAvailableGames().subscribe(
      res => {
        this.availableGames = [];
        for (const key in  res) {
          this.availableGames.push({id: key, value: res[key]});
        }
        console.log('available table', this.availableGames);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  openTableModal(game, allActive: any = null, all: any = false ) {
    this.selectedGameType = game;
    this.allActiveTables = false;
    this.allTables = all;
    this.allTableModal = true;
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
  }

  openActiveTableModal() {
    this.selectedGameType = '';
    this.allActiveTables = true;
    this.allTables = true;
    this.allTableModal = true;
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();
    console.log('w', this.selectedGameType);
  }

  openAllTableModal() {
    this.selectedGameType = '';
    this.allActiveTables = false;
    this.allTables = true;
    this.allTableModal = true;
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();
    console.log('w', this.selectedGameType);
  }

  closeTableModal(value: any) {
    this.selectedGameType = '';
    this.allTableModal = false;
    this.triggerProfileModal = false;
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();

  }

  triggerProfileModalEmit(value: any) {
    // this.musicService.playClick();

    if (this.musicClick) {
      this.musicClick.play();
    }

    // this.musicPlayer.playClick();
    this.triggerProfileModal = value;
  }

  triggerBuyCoinsModalEmit(value: any) {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    // this.musicPlayer.playClick();
    this.triggerBuyCoinsModal = value;
  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res.balance));
        console.log('Balance');

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  loadMyFriendsList(): void {
    this.userService.getMyFriends().subscribe(
      res => {
        this.store.dispatch(new SetFriendList(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  ionViewWillLeave() {
    console.log('okino se renove listgener')
    // this.swipingElement.removeEventListener('touchstart', null);
    this.subscriptions.unsubscribe();
  }
}
