import { MusicService } from '../../../core/services/music/music.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {SetBalance} from '../../../store/auth/auth.actions';
import {UserService} from '../../../core/services/user/user.service';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {Store} from '@ngxs/store';
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-join-game-modal',
  templateUrl: './join-game-modal.component.html',
  styleUrls: ['./join-game-modal.component.scss'],
})
export class JoinGameModalComponent implements OnInit {
  @Output() joinToGame = new EventEmitter();
  public musicClick = null;

  constructor(private musicService: MusicService,
              private userService: UserService,
              private store: Store,
              private translateService: TranslateService,
              private errorLogService: ErrorLogService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.getBalance();
    this.musicClick = document.getElementById('click-tag');
  }

  pay(value): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.joinToGame.emit(value);
  }

  getBalance(): void {
    this.userService.getBalance().subscribe(
      res => {
        this.store.dispatch(new SetBalance(res?.balance));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
