import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/store/auth/auth.actions';
import {AuthService} from "../../../core/services/auth/auth.service";

@Component({
  selector: 'app-rotate-device',
  templateUrl: './rotate-device.component.html',
  styleUrls: ['./rotate-device.component.scss'],
})
export class RotateDeviceComponent implements OnInit {
  constructor(private store: Store,
    private router: Router,
    private authService: AuthService,
    private navController: NavController) { }

  ngOnInit() {
  }

  logout(): void {
    this.authService.logout().subscribe(
      res => console.log(res),
      err => console.log(err)
    );
  this.store.dispatch(new Logout());
  this.router.navigate(['/login']);
  }
}
