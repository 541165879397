import { Injectable } from '@angular/core';
import {
  AddWinningNumber, LastWin,
  SetCurrentRound,
  SetGameStatus,
  SetNextWinningNumber
} from '../../../store/game-roulette/game-roulette.actions';
import {
  AddRouletteNewMessage, SetRouletteNumberOfOnlineUser,

} from '../../../store/roulette-chat/roulette-chat.actions';
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Store} from '@ngxs/store';
import {NotificationService} from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SocketRouletteGameService {
  private reconectCheckInterval = null;
  private notificationHub = null;
  public notificationHubStatus = new BehaviorSubject(false);
  public gameStatus = new BehaviorSubject(null);
  public syncWithGame = new BehaviorSubject(false);

  constructor(private store: Store,
              private notificationService: NotificationService,) {
    this.notificationHubStatus.subscribe((res) => {
      console.log('socket status ');
      this.reconnect();
    });
  }

  async connect(): Promise<void> {

    if (!this.notificationHub) {
      await this.createBuilder();
      this.connectionToHub();
    } else {
      console.log('is not null', this.notificationHub);
    }
  }

  createBuilder(): Promise<void> {

    const token = this.store.snapshot().elitPokerAuth.token;

    return new Promise((resolve, rejects) => {
      const builder =
        new HubConnectionBuilder()
          .configureLogging(LogLevel.Information)
          .withUrl(`${environment.bettingURL}/gameHub`,  {
            accessTokenFactory: () => token
          });
      this.notificationHub = builder.build();
      this.notificationHub.serverTimeoutInMilliseconds = 16000;
      this.notificationHub.keepAliveIntervalInMilliseconds = 8000;
      resolve();
    });
  }

  connectionToHub(): void {
    this.notificationHub
      .start()
      .then(() => {
        this.initEventListener();

        this.reconectCheckInterval = setInterval(async () => {
          console.log('reconnect check')
          await this.reconnect();
        }, 3000);
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        console.log('hub notification status error', this.notificationHub)
      });
  }

  initEventListener(): void {

    console.log('Connection started', this.notificationHub);
    if (this.notificationHub && this.notificationHub.connectionState === 'Connected') {


    }


    this.notificationHub.on('newChatMessage', (userName, newMessage) => {
      console.log('newChatMessage', userName, newMessage);
      this.store.dispatch(new AddRouletteNewMessage({name: userName, message: newMessage}));
    });

    this.notificationHub.on('changeNumberOfOnlineUsers', (data) => {
      console.log('changeNumberOfOnlineUsers', data);
      this.store.dispatch(new SetRouletteNumberOfOnlineUser(data));
    });

    this.notificationHub.on('winnerEvent', (data) => {
      console.log('winnerEvent', data);
      this.store.dispatch(new LastWin(data));

    });

    this.notificationHub.on('newGameEvent', (data) => {
      console.log('newGameEvent', data);
      this.gameStatus.next(data);
      if (data.roundStatus === 'IDLE') {
        this.syncWithGame.next(true);
        this.store.dispatch(new AddWinningNumber(data));
        this.store.dispatch(new SetCurrentRound(data));
        this.store.dispatch(new SetGameStatus('IDLE'));

      }

      if (data.roundStatus === 'BETTING') {
        this.store.dispatch(new SetCurrentRound(data));
        this.store.dispatch(new SetGameStatus('BETTING'));

      }
      if (data.roundStatus === 'SPIN') {
        this.store.dispatch(new SetNextWinningNumber(data.winningNumber));
        this.store.dispatch(new SetGameStatus('SPIN'));

      }

    });



    this.notificationHub.onclose( () => {
      console.log('connection closed by server');
      delete this.notificationHub;
    });
  }

  async reconnect(): Promise<void> {
    if (window.location.pathname !== '/login') {
      if (this.notificationHub  ) return ;

      const status = this.notificationHub && this.notificationHub.connectionState;
      if (this.notificationHub && status !== 'Connected') {
        this.notificationService.setReconnecting(true);

        console.log('we need to reconnect');
        delete this.notificationHub;
        await this.createBuilder();
        this.notificationHub
          .start()
          .then(() => {

            this.initEventListener();
            this.notificationService.setReconnecting(false);

            console.log('succesful reconnected');
          })
          .catch(err => {
            console.log('Error while starting connection: ' + err);
            console.log('hub notification status error', this.notificationHub);
          });
      }
    } else {
      clearInterval(this.reconectCheckInterval);
      this.notificationHub = null;
      this.notificationService.setReconnecting(false);
    }



  }

  disconnect(): void {
    clearInterval(this.reconectCheckInterval);

    console.log('disconnect form hub', this.notificationHub);
    try {

      if (this.notificationHub && this.notificationHub.connectionState === 'Connected') {
        // this.notificationHub.invoke('RemoveFromGroup');


        setTimeout(() => {
          if(this.notificationHub) {
            this.notificationHub.stop().then((res) => {
              this.notificationHub = null;
              console.log(res);
            }).catch((err) => {
              this.notificationHub = null;
              console.log(err)});
          }

        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }

  }

  resetSyncWithGame(): void {
    this.syncWithGame.next(false);
  }

}
