import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitString'
})
export class LimitStringPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value || value.length <= args[0]) {
      return value;
    }
    const numberForSubstring = Number(args[0]);
    return value.toString().slice(0, numberForSubstring) + '...';
  }

}
