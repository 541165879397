import {Directive, HostListener, Input} from '@angular/core';
import {Store} from '@ngxs/store';
import {SocketRouletteGameService} from '../../../core/services/socket-roullete-game/socket-roulette-game.service';
import {EventRouletteService} from '../../../core/services/event-roulette/event-roulette.service';

@Directive({
  selector: '[appAddCoin]'
})
export class AddCoinDirective {
  private moveToLeft = 22.5;
  private width: string = null;
  private height: string = null;
  @Input('coin') coin;


  constructor(private store: Store,
              private socketGameService: SocketRouletteGameService,
              private eventService: EventRouletteService) {

  }



  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if(window.innerWidth < 500) {
      this.width = '14px';
      this.height = '14px';
      this.moveToLeft = 8;
    } else if(window.innerWidth < 768) {
      this.width = '17px';
      this.height = '17px';
      this.moveToLeft = 8;
    } else if (window.innerWidth < 1000) {
      this.width = '21px';
      this.height = '21px';
      this.moveToLeft = 10;
    } else if (window.innerWidth < 1200) {
      this.width = '23px';
      this.height = '23px';
      this.moveToLeft = 10;
    } else if (window.innerWidth < 1400) {
      this.width = '24px';
      this.height = '24px';
      this.moveToLeft = 12;
    } else if (window.innerWidth < 1500) {
      this.width = '27px';
      this.height = '27px';
      this.moveToLeft = 13;
    } else if (window.innerWidth < 1650) {
      this.width = '30px';
      this.height = '30px';
      this.moveToLeft = 15;
    } else if (window.innerWidth >= 1650) {
      this.width = '35px';
      this.height = '35px';
      this.moveToLeft = 22.5;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event): void {
    event.stopPropagation();
    event.preventDefault();

    const currentGame = this.store.snapshot().rouletteGame.gameRound;
    const roundStatus = this.store.snapshot().rouletteGame.gameStatus;

    console.log(roundStatus);
    if (!this.eventService.gameStatus.getValue()) { return; }
    if (this.eventService.blockBet.getValue()) { return; }
    if (!this.socketGameService.syncWithGame.getValue()) { return; }

    if (roundStatus !== 'BETTING') {
      return;
    }

    if (!currentGame) {
      return;
    }

    const totalBet = this.store.snapshot().betRoulette.totalBet;
    const selectedCoinValue = this.store.snapshot().betRoulette.coinValue;
    const balance = this.store.snapshot().betRoulette.balance;
    const maxBet = this.store.snapshot().rouletteGame.gameRound.rules.maxBet;
    console.log(totalBet);
    console.log(selectedCoinValue);
    console.log(balance);
    console.log(maxBet);

    if (totalBet + selectedCoinValue > balance || totalBet + selectedCoinValue > maxBet) {
      return;
    }
    this.checkWidth();

    const coin = this.coin.cloneNode(true);
    coin.classList.add('coin');
    coin.style.position = 'absolute';
    coin.style.top = '50%';
    coin.style.left = '50%';
    coin.style.borderRadius = '50px';
    coin.style.width = this.width;
    coin.style.height = this.height;
    coin.style.zIndex = -50;
    // coin.style.transform = `translate(-50%,-50%)`;
    const numberOfCoins = event.currentTarget.childElementCount <= 2 ? event.currentTarget.childElementCount : 2;
    const coinPosition = -(this.moveToLeft - (numberOfCoins * 3));
    coin.style.transform = `translate(` + coinPosition + `px, -50%) scaleY(0.8)`;
    coin.style.margin = event.childElementCount * 10 + 'px';
    event.currentTarget.appendChild(coin);
  }


  addCoinManual(bet): void {
    this.checkWidth();
    const coin = bet.selectedCoin.cloneNode(true);
    coin.classList.add('coin');
    coin.style.position = 'absolute';
    coin.style.top = '50%';
    coin.style.left = '50%';
    coin.style.borderRadius = '50px';
    coin.style.width = this.width;
    coin.style.height = this.height;
    coin.style.zIndex = -50;
    // coin.style.transform = `translate(-50%,-50%)`;
    const ev = document.getElementById(bet.betCombination);
    const numberOfCoins = ev.childElementCount <= 2 ? ev.childElementCount : 2;
    const coinPosition = -(this.moveToLeft - (numberOfCoins * 3));
    coin.style.transform = `translate(` + coinPosition + `px, -50%) scaleY(0.8)`;
    coin.style.marginLeft = ev.childElementCount + 4 + 'px';
    ev.appendChild(coin);
  }

  checkWidth(): void {
    if(window.innerWidth < 500) {
      this.width = '14px';
      this.height = '14px';
      this.moveToLeft = 8;
    } else if (window.innerWidth < 768) {
      this.width = '17px';
      this.height = '17px';
      this.moveToLeft = 8;
    } else if (window.innerWidth < 1000) {
      this.width = '21px';
      this.height = '21px';
      this.moveToLeft = 10;
    } else if (window.innerWidth < 1200) {
      this.width = '23px';
      this.height = '23px';
      this.moveToLeft = 10;
    } else if (window.innerWidth < 1400) {
      this.width = '24px';
      this.height = '24px';
      this.moveToLeft = 12;
    } else if (window.innerWidth < 1500) {
      this.width = '27px';
      this.height = '27px';
      this.moveToLeft = 13;
    } else if (window.innerWidth < 1650) {
      this.width = '30px';
      this.height = '30px';
      this.moveToLeft = 15;
    } else if (window.innerWidth >= 1650) {
      this.width = '35px';
      this.height = '35px';
      this.moveToLeft = 22.5;
    }
  }
}
