import {AvailableGameModel, GameRoundModel, GameRuleModel} from '../../core/models';

export class SelectGame {
  static readonly type = '[Game Roulette] SelectGame';
  constructor(public data: AvailableGameModel) {}
}

export class AddWinningNumber {
  static readonly type = '[Game Roulette] AddWinningNumber';
  constructor(public winningNumber: number) {}
}

export class LastWin {
  static readonly type = '[Game Roulette] LastWin';
  constructor(public lastWin: number) {}
}
export class CurrentWin {
  static readonly type = '[Game Roulette] CurrentWin';
  constructor(public currentWin: number) {}
}

export class SetNextWinningNumber {
  static readonly type = '[Game Roulette] SetNextWinningNumber';
  constructor(public winningNumber: number) {}
}
export class SetGameStatus {
  static readonly type = '[Game Roulette] SetGameStatus';
  constructor(public gameStatus: string) {}
}

export class SetGameRules {
  static readonly type = '[Game Roulette] SetGameRules';
  constructor(public gameRules: GameRuleModel) {}
}

export class SetCurrentRound {
  static readonly type = '[Game Roulette] SetCurrentRound';
  constructor(public gameRound: GameRoundModel) {}
}

export class SetSyncStatus {
  static readonly type = '[Game Roulette] SetSyncStatus';
  constructor(public status: boolean) {}
}

export class SetIsGameStartStatus {
  static readonly type = '[Game Roulette] SetIsGameStartStatus';
  constructor(public isGameStart: boolean) {}
}
