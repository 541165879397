/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SetLastInvitation, SetFriendList, SetFriendRequestList, SetMyInvitations} from "./friends.action";

export interface FriendsStateModel {
  friends: any[];
  invitation: null;
  invitations: any[];
  friendsRequests: any[];
}
@Injectable()

@State<FriendsStateModel>({
  name: 'eliteFriends',
  defaults: {
    friends: [],
    invitations: [],
    friendsRequests: [],
    invitation: null
  }
})

export class FriendsState {

  @Selector()
  static getFriendList(state: FriendsStateModel) {
    return state.friends || [];
  }

  @Selector()
  static getInvitation(state: FriendsStateModel) {
    return state.invitation || null;
  }


  @Selector()
  static getFriendRequests(state: FriendsStateModel) {
    return state.friendsRequests || [];
  }

  @Selector()
  static getMyInvitations(state: FriendsStateModel) {
    return state.invitations || [];
  }

  @Action(SetFriendRequestList)
  setFriendsRequests(ctx: StateContext<FriendsStateModel>, {friendsRequests}: SetFriendRequestList): void {
      ctx.patchState({friendsRequests});
  }

  @Action(SetFriendList)
  setFriends(ctx: StateContext<FriendsStateModel>, {friends}: SetFriendList): void {
    ctx.patchState({friends});
  }

  @Action(SetMyInvitations)
  setInvitations(ctx: StateContext<FriendsStateModel>, {invitations}: SetMyInvitations): void {
    ctx.patchState({invitations});
  }

  @Action(SetLastInvitation)
  lastInvitation(ctx: StateContext<FriendsStateModel>, {invitation}: SetLastInvitation): void {
    ctx.patchState({invitation});
  }
}
