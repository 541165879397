import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByParam'
})
export class FilterByParamPipe implements PipeTransform {

  transform(value: any, ...args: any): any {

    if(!value) return [];
    if(!args[1]) return value;

    const searchText = args[1].toLowerCase();


    return value.filter( it => {
      return it[args[0]].toString().toLowerCase().includes(searchText);
    });
  }

}
