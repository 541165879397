import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {GameRoulleteState} from '../../../store/game-roulette/game-roulette.state';
import {UserService} from '../../../core/services/user/user.service';
import {GameRoundModel} from '../../../core/models';
import {SocketRouletteGameService} from '../../../core/services/socket-roullete-game/socket-roulette-game.service';
import * as $ from 'jquery';
import {ChatState} from '../../../store/chat/chat.state';
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-roulette-wheel',
  templateUrl: './roulette-wheel.component.html',
  styleUrls: ['./roulette-wheel.component.scss'],
})
export class RouletteWheelComponent implements OnInit, OnDestroy {
  @Select(GameRoulleteState.getWinningNumbers) winingNumber: number[];
  public numberOfOnlineUser = this.store.select(ChatState.getNumberOfOnlineUser);
  public minBet$ = this.store.select(GameRoulleteState.getMinBet);
  public isGameStart$ = this.store.select(GameRoulleteState.getIsGameStartStatus);
  public gameId$ = this.store.select(GameRoulleteState.getGameId);
  public maxBet$ = this.store.select(GameRoulleteState.getMaxBet);
  public gameSync = false;
  public gameNotification: any = 'SYNCHRONIZE';
  public gameStartCountDown: any = null;
  public gameStartInSec: any = 15;
  private red = [3, 9, 12, 18, 12, 21, 27, 30, 36, 5, 14, 23, 32, 1, 7, 16, 19, 25, 34];
  private black = [6, 15, 24, 33, 2, 8, 11, 17, 20, 26, 29, 35, 4, 10, 13, 22, 28, 31];
  private subscriptions = new Subscription();


  constructor(private socketGameService: SocketRouletteGameService,
              private userService: UserService,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private store: Store,
              private ngZone: NgZone) { }

  ngOnInit(): void {
    this.getGameInfo();
    this.subscriptions.add(this.socketGameService.gameStatus.subscribe((res: GameRoundModel) => {
      console.log(res, 'Game');
      if (!this.gameSync) {
        this.ngZone.run(() => this.gameNotification = 'SYNCHRONIZE');
        return;
      }
      if (res && res.roundStatus === 'IDLE') {
        console.log('idle status');
        this.ngZone.run(() => this.gameNotification = 'WINNING_NUMBER');
      } else if (res && res.roundStatus === 'SPIN') {
        setTimeout(() => {
          $mask.text('No More Bets');
        }, 0);
        this.spin(res.winningNumber, res.rules.gameSpinTimeoutInSeconds);
      }
      else if (res && res.roundStatus === 'BETTING') {
        this.ngZone.run(() => this.gameNotification = '');
        this.newGame();
      }
    }));

    this.subscriptions.add(this.socketGameService.syncWithGame.subscribe(res => this.gameSync = res));


    const $reset = $('#reset');
    const $mask = $('.mask');
    //const maskDefault = 'Place Your Bets';
    const maskDefault = '';

    const red = [32, 19, 21, 25, 34, 27, 36, 30, 23, 5, 16, 1, 14, 9, 18, 7, 12, 3];

    $reset.hide();

    $mask.text(maskDefault);


    // setTimeout(() => this.spin(),1000)
  }

  newGame(): void {

    const $inner = $('.inner');
    const  $spin = $('#spin');
    const  $data = $('.data');

    $inner.attr('data-spinto','').removeClass('rest');
    $(this).hide();
    $spin.show();
    $data.removeClass('reveal');
  }

  getGameInfo(): void {
    this.userService.getGameInfo().subscribe(
      res => {
        console.log(res);
        if(!res) {return;}
       /* const currentTime = new Date().getTime();
        const roundExpTime = new Date(res.nextStartTime).getTime();
        this.gameStartInSec = Math.round((roundExpTime - currentTime) / 1000);


        this.gameStartCountDown = setInterval(() => {
          this.gameStartInSec--;
          if (this.gameStartInSec < 1) {
            this.socketGameService.syncWithGame.next(true);
            clearInterval(this.gameStartCountDown);
          }
        },1000);
      */

      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  spin(winningNumber, spinTime): void {
    const $inner = $('.inner');
    const  $reset = $('#reset');
    const  $data = $('.data');
    const  $mask = $('.mask');
    //const  maskDefault = 'Place Your Bets';
    const  maskDefault = '';

    const currentTime = new Date().getTime();
    const roundExpTime = new Date().getTime() + Number(spinTime) * 1000;
    const timer = Math.round(roundExpTime - currentTime);

    const red = [32, 19, 21, 25, 34, 27, 36, 30, 23, 5, 16, 1, 14, 9, 18, 7, 12, 3];

    $reset.hide();

    $mask.text(maskDefault);

    // get a random number between 0 and 36 and apply it to the nth-child selector
    let color = null;
    $inner.attr('data-spinto', winningNumber).find('li:nth-child('+ winningNumber +') input').prop('checked','checked');
    // prevent repeated clicks on the spin button by hiding it
    $(this).hide();
    // disable the reset button until the ball has stopped spinning
    $reset.addClass('disabled').prop('disabled','disabled').show();

    $('.placeholder').remove();




    setTimeout(() => {
      $mask.text(maskDefault);
    }, timer + 500);



    // remove the disabled attribute when the ball has stopped
    setTimeout(() => {
      $reset.removeClass('disabled').prop('disabled','');

      if($.inArray(winningNumber, red) !== -1){ color = 'red';} else { color = 'black';};
      if(winningNumber === 0){color = 'green';};

      $('.result-number').text(winningNumber);
      $('.result-color').text(color);
      $('.result').css({'background-color': ''+color+''});
      $data.addClass('reveal');
      $inner.addClass('rest');
    }, timer);
  };

  ngOnDestroy(): void {
    this.socketGameService.resetSyncWithGame();
    this.subscriptions.unsubscribe();
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}

