import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { VerifyModel } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { VerifyOtp } from 'src/app/store/auth/auth.actions';
import {ErrorLogService} from '../../../core/services/error-log/error-log.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  public digits = new VerifyModel();
  public verifyForm: FormGroup;
  public musicClick = null;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private navController: NavController,
    private errorLogService: ErrorLogService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    this.verifyOtpForm();
    setTimeout(()=>{this.verify();});
  }

  verifyOtpForm(): void {
    this.verifyForm = new FormGroup({
      otp: new FormControl( '', [Validators.required]),
    });
  }

  verify(): void {
    /*const otpNumber = this.digits.mergeDigits();

    if (otpNumber.length !== 6) {
      return;
    }*/

    if (this.musicClick) {
      this.musicClick.play();
    }

    const authState = this.store.snapshot().elitPokerAuth;
    const optData = {
      userId: authState.user.userId,
      deviceId: authState.deviceId,
      logId: authState.user.logId,
      otpNumber: Number('123456')
    };
    this.authService.verifyOtp(optData).subscribe(
      res => {
        this.store.dispatch(new VerifyOtp(res));
        this.router.navigate(['/game/loader']);
        //this.navController.navigateForward(['/game/welcome']);
      },
      async err => {
        const msg = await this.translateService.get(err.error.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {

    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  resendOtp(): void {
    console.log('Resend OTP!');
    const authState = this.store.snapshot().elitPokerAuth;

    const optData = {
      userId: authState.user.userId,
      deviceId: authState.deviceId,
      logId: authState.user.logId
    };

    this.authService.resendOtp(optData).subscribe(
      res => console.log(res),
      async err => {
        const msg = await this.translateService.get(err.error.systemMessage).toPromise();
        this.notificationService.showError(msg);
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  ionViewDidLeave(): void {
    this.verifyForm.reset();
  }
}
