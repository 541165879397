import { Component } from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../core/services/notification/notification.service';
import {fadeInOut} from "../../../animations/fadeInOut.animations";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class NotificationsComponent {
  public loaderStatus = false;
  public status = false;
  public reconnecting = false;
  public isError = false;
  public message = '';
  private subscription = new Subscription();


  constructor( private notificationService: NotificationService ) {

    this.subscription.add(
      this.notificationService.notificationSuccess.subscribe( (res: { message: string}) => {
        this.showSuccess(res);
      })
    );

    this.subscription.add(
      this.notificationService.reconnecting.subscribe( (res) => {
        this.setReconnectingStatus(res);
      })
    );

    this.subscription.add(
      this.notificationService.notificationError.subscribe( (res: { message: string}) => {
        this.showError(res);
      })
    );

    this.subscription.add(
      this.notificationService.loader.subscribe( (res: boolean) => {
        this.showLoader(res);
      })
    );
  }

  showSuccess({message}: { message: string } ) {
    this.message = message;
    this.loaderStatus = false;
    this.isError = false;
    this.status = true;
  }

  showError({message}: { message: string } ) {
    this.message = message;
    this.loaderStatus = false;
    this.isError = true;
    this.status = true;
  }

  setReconnectingStatus(value){
    this.reconnecting = value;
  }

  closeModal() {

    this.message = null;
    this.status = false;

  }

  cancel() {
    this.closeModal();
    this.notificationService.cancelEvent();
  }

  ok() {
    this.closeModal();
    this.notificationService.okEvent();
  }

  close() {
    this.closeModal();
    this.notificationService.closeEvent();
  }

  showLoader(status) {
    this.message = null;
    this.loaderStatus = status;
  }
}
