import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../core/services/user/user.service";
import {Store} from "@ngxs/store";
import {SetFriendList} from "../../../store/friends/friends.action";
import {Platform, ViewWillEnter} from "@ionic/angular";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, ViewWillEnter {
  public percent = 0;
  public addClass = false;
  public preloadItems: any[] = [
    {
      "type": "img",
      "src": "/assets/avatars/avatar1.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar2.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar3.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar4.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar5.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar6.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar7.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar8.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar9.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar10.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar11.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar12.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar13.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar14.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar15.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar16.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar17.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar18.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar19.png"
    },
    {
      "type": "img",
      "src": "/assets/avatars/avatar20.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/SH.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/DC.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/elipse.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/holder.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/jackpot.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-images/crown.png"
    },
    {
      "type": "img",
      "src": "/assets/images/header-bgr.png"
    },
    {
      "type": "img",
      "src": "/assets/images/title-error.png"
    },
    {
      "type": "img",
      "src": "/assets/images/invitation-title.png"
    },
    {
      "type": "img",
      "src": "/assets/images/invitations-title.png"
    },
    {
      "type": "img",
      "src": "/assets/images/invate-players-title.png"
    },
    {
      "type": "img",
      "src": "/assets/images/buy-coins.png"
    },
    {
      "type": "img",
      "src": "/assets/images/all-tables.png"
    },
    {
      "type": "img",
      "src": "/assets/images/buy-coin-bgr.jpg"
    },
    {
      "type": "img",
      "src": "/assets/images/table-green.png"
    },
    {
      "type": "img",
      "src": "/assets/images/table-red.png"
    },
    {
      "type": "img",
      "src": "/assets/images/table-bgr.jpg"
    },
    {
      "type": "img",
      "src": "/assets/images/bgr-start.png"
    },
    {
      "type": "img",
      "src": "/assets/images/big-logo-start.png"
    },
    {
      "type": "img",
      "src": "/assets/images/avatar-ring.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/roulette.png"
    },
    {
      "type": "img",
      "src": "/assets/images/virtual-poker.png"
    },
    {
      "type": "img",
      "src": "/assets/images/coin.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/volume.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/mute.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/bars.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/back.png"
    },
    {
      "type": "img",
      "src": "/assets/images/TexasHoldemPoker.png"
    },
    {
      "type": "img",
      "src": "/assets/images/OmahaHoldemPoker.png"
    },
    {
      "type": "img",
      "src": "/assets/images/PineapplePoker.png"
    },
    {
      "type": "img",
      "src": "/assets/images/Omaha5HoldemPoker.png"
    },
    {
      "type": "img",
      "src": "/assets/images/photo-camera.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/title-profil.png"
    },
    {
      "type": "img",
      "src": "/assets/images/popup-close.png"
    },
    {
      "type": "img",
      "src": "/assets/images/title-statistic.png"
    },
    {
      "type": "img",
      "src": "/assets/images/payments.png"
    },
    {
      "type": "img",
      "src": "/assets/images/title-holdem.png"
    },
    {
      "type": "img",
      "src": "/assets/images/green-players.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/coin.png"
    },
    {
      "type": "img",
      "src": "/assets/images/locked.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/unlocked.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/title-omaha.png"
    },
    {
      "type": "img",
      "src": "/assets/images/title-pineapple.png"
    },
    {
      "type": "img",
      "src": "/assets/images/title-omaha5.png"
    },
    {
      "type": "img",
      "src": "/assets/images/chips.png"
    },
    {
      "type": "img",
      "src": "/assets/images/settings.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/close.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/messages.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/table1.png"
    },
    {
      "type": "img",
      "src": "/assets/images/chair.png"
    },
    {
      "type": "img",
      "src": "/assets/images/sit-here.png"
    },
    {
      "type": "img",
      "src": "/assets/images/newELITE-POKER-LOGO.png"
    },
    {
      "type": "img",
      "src": "/assets/images/jackpot-box.png"
    },
    {
      "type": "img",
      "src": "/assets/images/angle-down.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/angle-down-hover.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/plus-icon.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/plus-icon-hover.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/minus-icon.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/minus-icon-hover.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/edit-icon.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/edit-icon-hover.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/up-down.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/up-down-hover.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/raise.png"
    },
    {
      "type": "img",
      "src": "/assets/images/footer-coin-icon.png"
    },
    {
      "type": "img",
      "src": "/assets/images/buy-in-100.png"
    },
    {
      "type": "img",
      "src": "/assets/images/roulette-img.png"
    },
    {
      "type": "img",
      "src": "/assets/images/wheel-center.png"
    },
    {
      "type": "img",
      "src": "/assets/images/wheel-rotate.png"
    },
    {
      "type": "img",
      "src": "/assets/images/statistic.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/racetrack.png"
    },
    {
      "type": "img",
      "src": "/assets/images/classic.png"
    },
    {
      "type": "img",
      "src": "/assets/images/double.png"
    },
    {
      "type": "img",
      "src": "/assets/images/undo.png"
    },
    {
      "type": "img",
      "src": "/assets/images/clear.png"
    },
    {
      "type": "img",
      "src": "/assets/images/confirm-bet.png"
    },
    {
      "type": "img",
      "src": "/assets/images/bet-slider-logo.png"
    },
    {
      "type": "img",
      "src": "/assets/images/slider-left.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/slider-right.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/1C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/1S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/1H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/1D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/2C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/2S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/2H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/2D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/3C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/3S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/3H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/3D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/4C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/4S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/4H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/4D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/5C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/5S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/5H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/5D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/6C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/6S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/6H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/6D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/7C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/7S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/7H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/7D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/8C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/8S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/8H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/8D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/9C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/9S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/9H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/9D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/10C.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/10S.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/10H.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/10D.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/JC.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/JS.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/JH.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/JD.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/QC.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/QS.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/QH.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/QD.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/KC.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/KS.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/KH.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/KD.png"
    },
    {
      "type": "img",
      "src": "/assets/images/cards/card-default.png"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/1.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/2.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/5.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/10.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/20.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/50.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/100.svg"
    },
    {
      "type": "img",
      "src": "/assets/slider-coins/200.svg"
    },
    {
      "type": "img",
      "src": "/assets/svg/b-dark.svg"
    },
    {
      "type": "img",
      "src": "/assets/svg/s-dark.svg"
    },
    {
      "type": "img",
      "src": "/assets/svg/d-coin.svg"
    },
    {
      "type": "img",
      "src": "/assets/images/add-friend.png"
    },
    {
      "type": "img",
      "src": "/assets/images/friend.png"
    },
    {
      "type": "img",
      "src": "/assets/images/pending.png"
    },
    {
      "type": "img",
      "src": "/assets/images/tooltip.png"
    },
    {
      "type": "img",
      "src": "/assets/images/group-game.png"
    },
    {
      "type": "img",
      "src": "/assets/images/group-game-active.png"
    },
    {
      "type": "img",
      "src": "/assets/images/wheel-around.png"
    },
    {
      "type": "img",
      "src": "/assets/images/time-box.png"
    },
    {
      "type": "img",
      "src": "/assets/images/small-btn-bgr.jpg"
    },
    {
      "type": "img",
      "src": "/assets/images/checkbox.png"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/poker chips drop bunches 09.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/Check_double_tap.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/poker chips drop bunches 05.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/poker chips drop bunches 07.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/Click.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/poker chips drop bunches 01.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/PlayingCards_Pickup_01.wav"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/PlayingCards_DealFlip_06.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/poker chips drop bunches 02.mp3"
    },
    {
      "type": "audio",
      "src": "http://downloads.elite.bets4us.com/Audio/myturn.mp3"
    }
  ];


  constructor(private router: Router,
              private userService: UserService,
              private platform: Platform,
              private so: ScreenOrientation,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private store: Store) { }

  ngOnInit() {
    this.preloadImages();
    this.loadMyFriendsList();
    setTimeout(() => {this.addClass = true;},500);
  }

  ionViewWillEnter() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.lockToLandscape();
      }
    });
  }

  // Lock to landscape
  lockToLandscape(){
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
  }

  loadMyFriendsList(): void {
    this.userService.getMyFriends().subscribe(
      res => {
        console.log(res);
        this.store.dispatch(new SetFriendList(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }

  preloadImage(item){
    return new Promise((resolve, reject) => {
      const img = new Image();
      const audio = new Audio();
      if(item.type === 'img') {
        img.src = item.src;
        img.onload = () => {
          resolve(true);
        };
      }

      if (item.type === 'audio') {
        audio.onload = () => {
          resolve(true);
        };
        resolve(true);
      }
    });
  }

  async preloadImages() {
    console.log(this.preloadItems)
    for (let i = 0; i < this.preloadItems.length;) {
      await this.preloadImage(this.preloadItems[i]);
      i++;
      if(i > 0 ) {
        this.percent = (i / this.preloadItems.length ) * 100;
     }
      if (this.percent === 100 ) {
        // this.router.navigate(['game/choose_game']);
        const avatar = this.store.snapshot().elitPokerHelper.avatarSelected;
        if (avatar) {
          this.router.navigate(['game/choose_game']);
        } else {
          this.router.navigate(['game/select-avatar']);
        }
      }
    }
  }
}
