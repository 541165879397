import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { AddNewMessage, SerTypingStatus } from 'src/app/store/chat/chat.actions';
import { SetGameStatus, SetWaitingList } from 'src/app/store/game/game.actions';
import { environment } from 'src/environments/environment';
import { HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import { NotificationService } from '../notification/notification.service';
import {InfoMessage, SetNumberOfOnlineUsers } from '../../../store/helper/helper.action';
import {UserService} from '../user/user.service';
import {
  SetFriendList,
  SetFriendRequestList,
  SetLastInvitation,
  SetMyInvitations
} from '../../../store/friends/friends.action';
import {HttpClient} from '@angular/common/http';
import {EventService} from '../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public notificationHubStatus = new BehaviorSubject(false);
  private reconnectCheckInterval = null;
  private notificationHub = null;
  private disconnected = false;

  constructor(private http: HttpClient,
              private userService: UserService,
              private store: Store,
              private eventService: EventService,
              private notificationService: NotificationService) {
    this.notificationHubStatus.subscribe((res) => {
      this.reconnect();
    });
  }

  async connect(): Promise<void> {
    if (!this.notificationHub) {
      await this.createBuilder();
      this.connectionToHub();
    } else {
      console.log('Notification hub exist', this.notificationHub);
    }
  }

  createBuilder(): Promise<void> {

    const token = this.store.snapshot().elitPokerAuth.token;

    return new Promise((resolve, reject) => {
      const builder =
        new HubConnectionBuilder()
          .configureLogging(LogLevel.Information)
          .withUrl(`${environment.pokerURL}/gameHub?access_token=${token}`, { transport: 4 });
      try {
        this.notificationHub = builder.build();
        this.notificationHub.serverTimeoutInMilliseconds = 16000;
        this.notificationHub.keepAliveIntervalInMilliseconds = 8000;
        resolve();
      } catch (e) {
        reject();
      }

    });
  }

  connectionToHub(): void {
    this.notificationHub
      .start()
      .then(() => {
        this.initEventListener();
        this.reconnectCheckInterval = setInterval(async () => {
          await this.reconnect();
        }, 5000);
      })
      .catch(async err => {
        console.log('Error while starting connection: ' + err);
        console.log('hub notification status error', this.notificationHub);
        await this.reconnect();

      });
  }

  initEventListener(): void {

    console.log('Connection started', this.notificationHub);
    if (this.notificationHub && this.notificationHub.connectionState === 'Connected') {

    }


    this.notificationHub.on('newChatMessage', (username, message, gameKey, fullName) => {
      this.store.dispatch(new AddNewMessage({name: fullName, message}));
    });

    this.notificationHub.on('waitListUpdate', (data) => {
      this.store.dispatch(new SetWaitingList(data));
    });


    this.notificationHub.on('newGameStatus', (data) => {
      if (this.store.snapshot().elitPokerGame?.game?.gameKey === data.gameKey) {
        this.store.dispatch(new SetGameStatus(data));
      }
    });

    this.notificationHub.on('isTyping', (id, username, status) => {
      this.store.dispatch(new SerTypingStatus(id, username, status));
    });

    this.notificationHub.on('changeNumberOfOnlineUsers', (data) => {
    });

    this.notificationHub.on('showMessage', (data) => {
      this.store.dispatch(new InfoMessage(data));
    });

    this.notificationHub.on('changeNumberOfOnlineUsers', (data) => {
      setTimeout(() => {
        this.eventService.refreshNumberOfUser.next(true);
      },2000);
      this.store.dispatch(new SetNumberOfOnlineUsers(data));
    });

    this.notificationHub.on('updatefriends', () => {
      this.updateFriendsList();
      this.updateFriendsRequestList();
    });

    this.notificationHub.on('updaterequests', () => {
      this.updateFriendsRequestList();
    });

    this.notificationHub.on('updateinvite', () => {
      this.updateInvitationList();
    });



    this.notificationHub.onclose( () => {
      if(!this.disconnected) {
        this.notificationService.setReconnecting(true);
      }
    });
  }

  async reconnect(): Promise<void> {
    if (window.location.pathname !== '/login') {
      if (this.notificationHub && !this.notificationHub.connectionStarted) {
        this.notificationService.setReconnecting(true);

        delete this.notificationHub;
        await this.createBuilder();
        this.notificationHub
          .start()
          .then(() => {
            this.initEventListener();
            console.log('successful reconnected');
            this.notificationService.setReconnecting(false);
            this.eventService.reconnectedSuccessful.next();
          })
          .catch(err => {
            console.log('Error while starting connection: ' + err);
            console.log('hub notification status error', this.notificationHub);
          });
      }
    } else {
      clearInterval(this.reconnectCheckInterval);
      this.notificationHub = null;
      this.disconnected = false;
      this.notificationService.setReconnecting(false);
    }
  }

  disconnect(): void {
    clearInterval(this.reconnectCheckInterval);
    this.disconnected = true;
    console.log('disconnect form hub', this.notificationHub);
    try {

      if (this.notificationHub && this.notificationHub.connectionState === 'Connected') {


        setTimeout(() => {
          if(this.notificationHub) {
            this.notificationHub.stop().then((res) => {
              this.notificationHub = null;
            }).catch((err) => {
              this.notificationHub = null;
              console.log(err);});
          }

        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }

  }


  invokeJoin(gameKey): void {
    if (!this.notificationHub || this.notificationHub && this.notificationHub.connectionState === 'Connected') {
      this.notificationHub.invoke('Subscribe', gameKey);
    } else {
      setTimeout(() => {
        this.invokeJoin(gameKey);
      },1000);
    }
  }

  invokeRemove(gameKey): void {
    if (!this.notificationHub) {return;}
    if (this.notificationHub && this.notificationHub.connectionState === 'Connected') {
      this.notificationHub.invoke('Unsubscribe', gameKey);
    }
  }

  private updateFriendsList() {
    this.userService.getMyFriends().subscribe(
      res => {
        console.log('getMyFriends', res);
        this.store.dispatch(new SetFriendList(res));
      },
      err => console.log(err)
    );
  }
  private updateFriendsRequestList(): void {
    this.userService.getInvitationList().subscribe(
      res => {
        this.store.dispatch(new SetFriendRequestList(res));
      },
      err => console.log(err)
    );
  }

  private updateInvitationList(): void {
    this.userService.getMyInvitations().subscribe(
      res => {
        this.store.dispatch(new SetMyInvitations(res));
        this.store.dispatch(new SetLastInvitation(res[0]));
      },
      err => console.log(err)
    );
  }
}
