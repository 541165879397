
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {PickTable, RemoveTable} from './multiple-game.actions';


export interface MultipleGameStateModel {
  selectedTables: any[];
}

@Injectable()

@State<MultipleGameStateModel>({
  name: 'elitePokerMultipleGame',
  defaults: {
    selectedTables: [],
  }
})

export class MultipleGameState {

  constructor() {}

  @Selector()
  static getAllTables(state: MultipleGameStateModel): any {
    return state.selectedTables || [];
  }

  @Action(PickTable)
  pickTable(ctx: StateContext<MultipleGameStateModel>, {table}: PickTable): void {

    const state = ctx.getState();
    // const selectedTables = state.selectedTables;
    const selectedTables = [table];
    // selectedTables.push(table);

    ctx.patchState({
      selectedTables
    });
  }

  @Action(RemoveTable)
  removeTable(ctx: StateContext<MultipleGameStateModel>, {table}: RemoveTable): void {
    const selectedTables = [];
    ctx.patchState({
      selectedTables
    });
  }

}
