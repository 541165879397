export class SetFriendRequestList {
  static readonly type = '[Friends] SetFriendRequestList';
  constructor(public friendsRequests: any) {}
}

export class SetFriendList {
  static readonly type = '[Friends] SetFriendList';
  constructor(public friends: any) {}
}

export class SetMyInvitations {
  static readonly type = '[Friends] SetMyInvitations';
  constructor(public invitations: any) {}
}

export class SetLastInvitation {
  static readonly type = '[Friends] LastInvitation';
  constructor(public invitation: any) {}
}
