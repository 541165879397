import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  AddRouletteNewMessage, ResetRouletteUnreadMessages, SetRouletteChatStatus,
  SetRouletteNumberOfOnlineUser,
  UnreadRouletteMessagesIncrease
} from './roulette-chat.actions';
import { Injectable } from '@angular/core';
import {ChatMessageModel, UserChatInfoModel} from '../../core/models';
import {UnreadMessagesIncrease} from "../chat/chat.actions";

export interface ChatRouletteStateModel {
  usersColor: UserChatInfoModel[];
  numberOfOnlineUser: number;
  messages: ChatMessageModel[];
  unreadMessages: number;
  isChatOpen: boolean;
}

@Injectable()

@State<ChatRouletteStateModel>({
  name: 'elitRoulettePokerChat',
  defaults: {
    numberOfOnlineUser: null,
    messages: [],
    usersColor: [],
    unreadMessages: 0,
    isChatOpen: false,
  }
})

export class ChatRouletteState {

  constructor() {
  }

  @Selector()
  static getNumberOfOnlineUser(state: ChatRouletteStateModel): any {
    return state.numberOfOnlineUser || null;
  }

  @Selector()
  static getMessages(state: ChatRouletteStateModel): any {
    return state.messages || null;
  }

  @Selector()
  static getUnreadMessages(state: ChatRouletteStateModel): any {
    return state.unreadMessages || 0;
  }

  @Selector()
  static getIsChatOpen(state: ChatRouletteStateModel): any {
    return state.isChatOpen || 0;
  }

  @Action(AddRouletteNewMessage)
  addRouletteMessage(ctx: StateContext<ChatRouletteStateModel>, {message}: AddRouletteNewMessage): void {
    const messages = ctx.getState().messages;

    const usersColor = ctx.getState().usersColor;

    const user = usersColor.find(x => x.name === message.name);

    const randomHex = this.getRandomColor();
    let newMessage;
    if(!user) {
      usersColor.push({ name: message.name, color: randomHex});
      newMessage = {
        name: message.name,
        message: message.message,
        color: randomHex
      };
      messages.push(newMessage);

    } else {
      newMessage = {
        name: message.name,
        message: message.message,
        color: user.color
      };
      messages.push(newMessage);

    }

    console.log(ctx.getState());

    if (messages.length > 20) {
      messages.shift();
    }

    ctx.patchState({
      messages
    });

    ctx.dispatch(new UnreadRouletteMessagesIncrease());
  }

  @Action(SetRouletteNumberOfOnlineUser)
  setRouletteNumberOfOnlineUser(ctx: StateContext<ChatRouletteStateModel>, {numberOfOnlineUser}: SetRouletteNumberOfOnlineUser): void {

    ctx.patchState({
      numberOfOnlineUser
    });

  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  @Action(UnreadRouletteMessagesIncrease)
  setRouletteUnreadMessages(ctx: StateContext<ChatRouletteStateModel>, {}: UnreadRouletteMessagesIncrease): void {
    const unreadMessages = ctx.getState().unreadMessages;
    const numberOfUnreadMessages = unreadMessages + 1;
    const isChatOpen = ctx.getState().isChatOpen;
    if(!isChatOpen) {
      ctx.patchState({
        unreadMessages: numberOfUnreadMessages
      });
    }
  }

  @Action(ResetRouletteUnreadMessages)
  resetRouletteUnreadMessages(ctx: StateContext<ChatRouletteStateModel>, {}: ResetRouletteUnreadMessages): void {
    ctx.patchState({
      unreadMessages: 0
    });
  }

  @Action(SetRouletteChatStatus)
  setRouletteChatStatus(ctx: StateContext<ChatRouletteStateModel>, {isChatOpen}: SetRouletteChatStatus): void {
    ctx.patchState({
      isChatOpen,
      unreadMessages: 0
    });
  }

}
