export class VerifyModel {
  public digit1 = '';
  public digit2 = '';
  public digit3 = '';
  public digit4 = '';
  public digit5 = '';
  public digit6 = '';

  public mergeDigits(): string {
    return String(this.digit1) +
           String(this.digit2) +
           String(this.digit3) +
           String(this.digit4) +
           String(this.digit5) +
           String(this.digit6);
  }
}
