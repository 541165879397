import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';
export const coinAnimation = trigger('coinAnimation', [

    transition('* => *', [

        query(':enter', stagger('1s', [
          animate('.5s ease-in', keyframes([
            style({ 
                opacity:'{{opacityDefault}}', 
                visibility: '{{visibilityDefault}}', 
                transform: '{{transformDefault}}', 
                offset: 0 
            }),

            style({ 
                opacity:'{{opacityIn}}', 
                visibility: '{{visibilityIn}}', 
                transform: '{{transformIn}}', 
                offset: 1 }),
          ]))]), { optional: true }),
     
        query(':leave', stagger('.5s', [
          animate('.7s ease-out', keyframes([
            style({ 
                opacity: '{{opacityIn}}', 
                visibility: '{{visibilityIn}}', 
                transform: '{{transformIn}}', 
                offset: 0 
            }),
            style({ 
                opacity: '{{opacityOut}}', 
                visibility: '{{visibilityOut}}', 
                transform: '{{transformOut}}', 
                offset: 1 
            }),
          ]))]), { optional: true })
      ]),

]);

export const pullCoinAnimation = trigger('pullCoinAnimation', [

  transition('* => *', [

      query(':enter', stagger('1s', [
        animate('.5s ease-in', keyframes([
          style({ 
              opacity:'{{opacityDefault}}', 
              visibility: '{{visibilityDefault}}', 
              transform: '{{transformDefault}}', 
              offset: 0 
          }),

          style({ 
              opacity:'{{opacityIn}}', 
              visibility: '{{visibilityIn}}', 
              transform: '{{transformIn}}', 
              offset: 1 }),
        ]))]), { optional: true }),
   
      query(':leave', stagger('.5s', [
        animate('.7s ease-out', keyframes([
          style({ 
              opacity: '{{opacityIn}}', 
              visibility: '{{visibilityIn}}', 
              transform: '{{transformIn}}', 
              offset: 0 
          }),
          style({ 
              opacity: '{{opacityOut}}', 
              visibility: '{{visibilityOut}}', 
              transform: '{{transformOut}}', 
              offset: 1 
          }),
        ]))]), { optional: true })
    ]),

]);

