
export class AddNewMessage {
  static readonly type = '[chat] AddNewMessage';
  constructor(public message: any) {}
}
export class AddNewLogMessage {
  static readonly type = '[chat] AddNewLogMessage';
  constructor(public logMessage: any) {}
}

export class SetNumberOfOnlineUser {
  static readonly type = '[chat] SetNumberOfOnlineUser';
  constructor(public numberOfOnlineUser: number) {}
}

export class SetChatStatus {
  static readonly type = '[chat] SetChatStatus';
  constructor(public isChatOpen: boolean) {}
}

export class SerTypingStatus {
  static readonly type = '[chat] SerTypingStatus';
  constructor(public id: any,public username: any,public status: any) {}
}

export class ResetChat {
  static readonly type = '[chat] ResetChat';
}

export class ResetChatLog {
  static readonly type = '[chat] ResetChatLog';
}

export class UnreadMessagesIncrease {
  static readonly type = '[chat] UnreadMessagesIncrease';
  constructor() {}
}

export class ResetUnreadMessages {
  static readonly type = '[chat] ResetUnreadMessages';
  constructor() {}
}
