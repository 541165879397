import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {Platform} from '@ionic/angular';
import {MusicService} from './core/services/music/music.service';
import {HelperState} from './store/helper/helper.state';
import {Store} from '@ngxs/store';
import {fadeInOut} from './animations/fadeInOut.animations';
import {FriendsState} from './store/friends/friends.state';
import {SocketService} from './core/services/socket/socket.service';
import {SocketRouletteGameService} from './core/services/socket-roullete-game/socket-roulette-game.service';
import {SetConnecting, SetTimeDiff} from './store/helper/helper.action';
import {NotificationService} from './core/services/notification/notification.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from './core/services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class AppComponent implements OnInit, AfterViewInit {

  public timeDiffInterval = null;
  public fullScreen = false;
  public infoMessage$ = this.store.select(HelperState.getInfoMessage);
  public friendsInvitation$ = this.store.select(FriendsState.getInvitation);
  public isActive$ = this.store.select(HelperState.getIsUserActive);

  constructor(private platform: Platform,
              private musicService: MusicService,
              private store: Store,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private socketRouletteGameService: SocketRouletteGameService,
              private socketService: SocketService,
              private userService: UserService
    ) {

    platform.ready().then(() => {

      this.setTimeDiff();

      if( !this.timeDiffInterval) {
        this.timeDiffInterval = setInterval(() => this.setTimeDiff(), 60000);
      }

      if (platform.is('cordova')){

        //Subscribe on pause i.e. background
        this.platform.pause.subscribe(() => {
        //Hello pause

          // this.musicService.outFocus();
          this.store.dispatch(new SetConnecting(true));
        });

        //Subscribe on resume i.e. foreground
        this.platform.resume.subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['paused'] = 0;
          // this.musicService.inFocus();
        });
      }
    });
  }

  @HostListener('window:focus', ['$event'])
  detectFocus(event) {
    if (window.location.pathname !== '/login') {
      this.socketService.reconnect();
      this.socketRouletteGameService.reconnect();
      // this.musicService.inFocus();
    }
  }

  @HostListener('window:blur', ['$event'])
  detectBlur(event) {
    if (window.location.pathname !== '/login') {
      // this.musicService.outFocus();
      this.store.dispatch(new SetConnecting(true));
      // this.musicService.outFocus();
    }
  }

  ngOnInit() {



    this.setTimeDiff();
    if( !this.timeDiffInterval) {
      this.timeDiffInterval = setInterval(() => this.setTimeDiff(), 60000);
    }
  }

  async ngAfterViewInit() {
    await this.musicService.setupMusicReference();

    const element = document.getElementById("stop");


    // element.addEventListener("touchstart", (e: any) => {
    //   // is not near edge of view, exit
    //   console.log('touch from app component', e)
    //   console.log('touch from app component',e.targetTouches[0].clientX)
    //
    //   if (Math.round(e.targetTouches[0].clientX) < (document.documentElement.clientWidth * 15) /100 ) {
    //     console.log('prvi if')
    //     e.preventDefault();
    //     return;
    //   };
    //
    //   if (Math.round(e.targetTouches[0].clientX) > document.documentElement.clientWidth - (document.documentElement.clientWidth * 15) /100) {
    //     console.log('drugi if')
    //
    //     e.preventDefault();
    //     return;
    //   };
    //
    //
    // });
  }

  setTimeDiff(): void {
    if(!this.store.snapshot().elitPokerAuth.token) {return;}
    const t1 = new Date().getTime();
    this.userService.getTime().subscribe(
      res => {

        const t2 = new Date().getTime();

        const timeDiff = new Date(res.toString()).getTime() - ( t2 - ((t2 - t1) / 2));

        this.store.dispatch(new SetTimeDiff(timeDiff));
      },
      err => console.log(err)
    );
  }

}

