import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myFriends'
})
export class MyFriendsPipe implements PipeTransform {

  transform(value: any): any {
    console.log('pipeeeeeeeeeee', value)
    if(!value) {return [];}



    return value.filter( it => it.status === 1 );
  }
}
