import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-just-number',
  templateUrl: './just-number.component.html',
  styleUrls: ['./just-number.component.scss'],
})
export class JustNumberComponent implements OnInit {

  @Input() number: number;
  public color = '';
  constructor() { }
  private red = [3, 9, 12, 18, 12, 21, 27, 30, 36, 5, 14, 23, 32, 1, 7, 16, 19, 25, 34];
  private black = [6, 15, 24, 33, 2, 8, 11, 17, 20, 26, 29, 35, 4, 10, 13, 22, 28, 31];
  ngOnInit(): void {

    console.log(this.number)
    if (this.red.includes(Number(this.number))) {
      this.color = 'red';
    } else if (this.black.includes(Number(this.number))) {
      this.color = 'white';
    } else {
      this.color = 'green';
    }
  }

}
