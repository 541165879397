import { MusicService } from 'src/app/core/services/music/music.service';
import {Component, Input, OnInit} from '@angular/core';
import {CardEnum} from '../../../core/enum/card-enum';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() card = null;
  @Input() playCard: boolean = null;
  public musicCard = null;
  public cardType = CardEnum;

  constructor(private musicService: MusicService) {}

  ngOnInit(): void {

    if(this.playCard) {
      if (this.musicCard) { this.musicCard.play(); }
      this.musicService.playOneCard();
    }
  }
}
