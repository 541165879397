import { Component, Input, OnInit, EventEmitter, Output, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { MusicService } from 'src/app/core/services/music/music.service';
import { AddNewLogMessage, AddNewMessage, ResetUnreadMessages, SetChatStatus} from 'src/app/store/chat/chat.actions';
import { ChatState } from 'src/app/store/chat/chat.state';
import {GameService} from "../../../core/services/game/game.service";
import {ErrorLogService} from "../../../core/services/error-log/error-log.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() closeChatModal: boolean;
  @Output() closeModal = new EventEmitter();
  @ViewChild('scrollChat') private scrollChat: ElementRef;
  @ViewChild('scrollChatLog') private scrollChatLog: ElementRef;
  public subscription = new Subscription();
  public inputFocus = false;
  public userName = null;
  public gameKey = null;
  public chatType = 'chat';
  public eventAlreadySent = false;
  public msgInput = '';
  public messages$ = this.store.select(ChatState.getMessages);
  public logMessages$ = this.store.select(ChatState.getLogMessages);
  public musicClick = null;

  constructor(
              private chatService: ChatService,
              private store: Store,
              private musicService: MusicService,
              private actions$: Actions,
              private errorLogService: ErrorLogService,
              private translateService: TranslateService,
              private gameService: GameService,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.musicClick = document.getElementById('click-tag');

    console.log('poruke', this.store.snapshot().elitPokerChat);
    this.subscription.add(
      this.actions$
        .pipe(ofActionDispatched(AddNewMessage), debounceTime(1))
        .subscribe(data => {
          console.log('nova poruka');
          this.scrollToBottom();
        }));

    this.subscription.add(
      this.actions$
        .pipe(ofActionDispatched(AddNewLogMessage), debounceTime(1))
        .subscribe(data => {
          console.log('nova poruka');
          this.scrollToBottomLog();
        }));
    this.userName = this.store.snapshot().elitPokerAuth.user.userName;
    this.gameKey = this.route.snapshot.queryParams.gameKey;
    this.store.dispatch(new ResetUnreadMessages())
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  getLogMessages() {
    this.gameService.getLog(this.gameKey).subscribe(
      res => {
        console.log(res);
        this.store.dispatch(new AddNewLogMessage(res));
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  changeChatType(value){
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.chatType = value;
    if(value === 'log') {
      const store = this.store.snapshot();
      if(!store.elitPokerGame?.authUser) {return;}
      this.getLogMessages();
    }
  }

  closeChat(): void {
    // this.musicService.playClick();
    if (this.musicClick) {
      this.musicClick.play();
    }
    this.closeModal.emit(false);
    this.store.dispatch(new SetChatStatus(false));
  }

  submitChat(): void {
    if ( !this.msgInput.length ) { return; }
    console.log('Messages: '+ this.msgInput);
    this.chatService.sendMessage({message: this.msgInput}, this.gameKey).subscribe(
      res => {
        console.log(res);
        this.msgInput = '';
        this.sendTyping(false);
        this.eventAlreadySent = false;
        console.log('user not typing');

      },
      async err => {
      this.msgInput = '';
      this.sendTyping(false);
      this.eventAlreadySent = false;
      const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
      const store = this.store.snapshot();

      const errorData = {
        AppName: 'Elites Poker',
        DeviceId: store.elitPokerAuth?.deviceId,
        UserId: store.elitPokerAuth?.details?.userId,
        PageUrl: window.location.pathname,
        ErrorDescription:  msg,
      };

      this.logOnServer(errorData);
      console.log(err);
    }
    );

  }

  sendTypingEvent(){
    if(this.msgInput.length > 0) {
      console.log('user typing');
      this.eventAlreadySent = true;

      this.sendTyping(true);

    } else if (this.msgInput.length === 0) {
      console.log('user not typing');
      this.eventAlreadySent = false;

      this.sendTyping(false);

    }
  }

  sendTyping(data) {
    this.chatService.sendTypingEvent(data, this.gameKey).subscribe(
      res => {
        console.log(res);
      },
      async err => {
        const msg = await this.translateService.get(err?.error?.systemMessage).toPromise();
        const store = this.store.snapshot();

        const errorData = {
          AppName: 'Elites Poker',
          DeviceId: store.elitPokerAuth?.deviceId,
          UserId: store.elitPokerAuth?.details?.userId,
          PageUrl: window.location.pathname,
          ErrorDescription:  msg,
        };

        this.logOnServer(errorData);
        console.log(err);
      }
    );
  }

  scrollToBottom(): void {
    try {
      this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;
    } catch (err) { }
  }

  scrollToBottomLog(): void {
    try {
      this.scrollChatLog.nativeElement.scrollTop = this.scrollChatLog.nativeElement.scrollHeight;
    } catch (err) { }
  }

  ngOnDestroy() {
    console.log('user not typing');
    this.sendTyping(false);
    this.subscription.unsubscribe()
  }

  logOnServer(error): void {
    this.errorLogService.logError(error).subscribe(
      res => {
        console.log('[ServerLog]', res);
      },
      err => console.log(err)
    );
  }
}
